import React, { Component } from 'react'

import { Input, Button, Row, Col, Select, Form, Radio } from 'antd';
import t from '../../utils/translate'
import Countries from '../../utils/countries'
import Splash from '../app/_splash'
import _ from 'lodash'
import Cookies from '../../utils/cookies'

class CreateOrganization extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }

    this.onFinish = this.onFinish.bind(this)
    this.onFinishFailed = this.onFinishFailed.bind(this)
  }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Create organization',
        page: '/create-organization' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }
  }

  onFinishFailed(error) {
    console.error('reset password form error', error)
    return
  }

  onFinish(values) {

    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/organizations.create',
      data: values
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      let organizations = this.props.app.state.organizations
      organizations.push(response.data.organization)
      this.props.app.setState({organizations: organizations})

      this.props.app.addMessage('success', t('organization_created', "Your organization has successfully been created!"))

      if (window.cmAgent) {
        window.cmAgent.addUserToCompany(response.data.organization.id, {role: 'admin'})
        window.cmAgent.updateCompanyProfile({
          set: {
            name: response.data.organization.name,
            currency: response.data.organization.currency,
            attributes: {
              plan: response.data.organization.plan,
              stripeId: response.data.organization.stripeId,
            }
          }
        })
        window.cmAgent.event({
          label: 'createOrganization',
          props: {
            orgId: response.data.organization.id,
          }
        })
        window.cmAgent.dispatch()
      }

      // refresh token with new claim
      this.props.app.state.firebaseUser.getIdToken(true).then(() => {
        this.props.history.push('/organizations/'+response.data.organization.id)  
      })
    })
  }

  formRef = React.createRef()

  render() {

    const maxIdLength = 15
    let plan = Cookies.getItem('plan')

    if (plan !== 'free' && plan !== 'pro' && plan !== 'premium') {
      plan = 'pro'
    }

    return (
      <Splash h1={t('create_an_organization', "Create a new organization")}>
          <Row>
            <Col span={16} offset={4}>
              <Form
                ref={this.formRef}
                layout="vertical"
                name="create_org_form"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                initialValues={{plan: plan}}
              >
                <Form.Item 
                  name="name"
                  label={t('organization_name', "Organization name")}
                  rules={[{ required: true, message: t('field_required', "This field is required!") }]}
                >
                  <Input size="large" placeholder="i.e: Acme Inc" onChange={(e) => {
                    const name = e.target.value
                    if (name) {
                      let newId = _.truncate(_.camelCase(name).toLowerCase(), {length: maxIdLength, omission: ''})
                      if (newId !== '') {
                        this.formRef.current.setFieldsValue({'id': newId})     
                      }                 
                    }
                  }} />
                </Form.Item>

                <Form.Item 
                  name="id"
                  label={t('organization_id', "Organization ID")} 
                  rules={[{ required: true, type: 'string', max: maxIdLength, pattern: /^[a-z0-9]+$/, message: t('only_x_alphanumeric_characters', "Only {x} alphanumeric characters allowed!", {x: maxIdLength}) }]}
                  shouldUpdate
                >
                  <Input size="large" placeholder="i.e: acmeinc" />
                </Form.Item>

                {/*<Form.Item 
                  name="currency"
                  label={t('currency', "Currency")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder={t('select_a_currency', "Select a currency")}
                  >
                    {Currencies.map((c) => <Select.Option key={c.code} value={c.code}>{c.code+' - '+c.currency}</Select.Option>)}
                  </Select>
                </Form.Item>*/}

                <Form.Item 
                  name="country"
                  label={t('country', "Country")}
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    placeholder={t('select_a_country', "Select a country")}
                  >
                    {Countries.map((c) => <Select.Option key={c.code} value={c.code}>{c.code+' - '+c.name}</Select.Option>)}
                  </Select>
                </Form.Item>

                <Form.Item 
                  name="plan"
                  label={t('plan', "Plan")}
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    <Radio value="free">Free</Radio>
                    <Radio value="pro">Pro</Radio>
                    <Radio value="premium">Premium</Radio>
                  </Radio.Group>
                </Form.Item>

                <Button type="primary" loading={this.state.loading} size="large" htmlType="submit" style={{width: '100%'}}>{t('confirm', "Confirm")}</Button>
              </Form>
            </Col>
          </Row>
        </Splash>
    );
  }
}

export default CreateOrganization