import React, { Component } from 'react'

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Input, Button, Row, Col, Modal, Form } from 'antd';
import t from '../../utils/translate'
import Firebase from 'firebase/app'
import 'firebase/auth'
import SignUpForm from './_signUpForm'

class LoginForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showSignUp: false,
    }

    this.signInWithGoogle = this.signInWithGoogle.bind(this)
    this.onFinish = this.onFinish.bind(this)
    this.onFinishFailed = this.onFinishFailed.bind(this)
  }

  signInWithGoogle() {
    const provider = new Firebase.auth.GoogleAuthProvider()

    // the change observer will trigger as soon as the user is logged in
    Firebase.auth().signInWithPopup(provider).catch((error) => {
      console.error(error)
    })
  }

  onFinishFailed(error) {
    console.error('reset password form error', error)
    return
  }

  onFinish(values) {
    if (this.state.loading === true) {
      return
    } else {
      this.setState({loading: true})
    }

    Firebase.auth().signInWithEmailAndPassword(values.email, values.password).catch((error) => {
      // var errorCode = error.code;
      // var errorMessage = error.message;
      console.error('sign in form error', error)

      if (error.code === "auth/user-not-found") {
        error.message = t('user_not_found', "User not found.")
      }

      if (error.code === "auth/wrong-password") {
        error.message = t('wrong_password', "The password is invalid.")
      }

      this.props.addMessage('error', error.message)
      this.setState({loading: false })
    })
  }

  render() {
    if (this.state.showSignUp === true) {
      return <SignUpForm addMessage={this.props.addMessage} signIn={this.props.signIn} />
    }

    return (
      <div>
        <Button type="primary" ghost size="large" onClick={this.signInWithGoogle}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="google-svg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
          {t('sign_in_with_google', "Sign in with Google")}
        </Button>

        <div className="text-center margin-v-l">{t('or_sign_in_with_credentials', "Or sign in with credentials")}:</div>

        <Row>
          <Col span={16} offset={4}>
            <Form
              layout="vertical"
              name="login"
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Form.Item
                label={t('email_address', "Email address")}
                name="email"
                rules={[{ required: true, type: 'email' }]}
              >
                <Input size="large" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('email_address', "Email address")} />
              </Form.Item>

              <Form.Item
                label={t('password', "Password")}
                name="password"
                rules={[{ required: true }]}
              >
                <Input type="password" size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('password', "Password")} />
              </Form.Item>

              <Button type="primary" loading={this.state.loading} size="large" htmlType="submit" block>{t('sign_in', "Sign in")}</Button>

              <ForgotPassword addMessage={this.props.addMessage} />
            </Form>

            {t('dont_have_account', "Don't have an account?")} <span className="link" onClick={() => this.setState({showSignUp: true})}>{t('sign_up_for_free', "Sign up for free")}</span>              
          </Col>
        </Row>
      </div>
    );
  }
}

class ForgotPassword extends Component {

  constructor(props) {
    super(props)

    this.state = {
      show: false,
      loading: false
    }

    this.onFinish = this.onFinish.bind(this)
    this.onFinishFailed = this.onFinishFailed.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }

  toggleModal() {
    this.setState({show: !this.state.show})
  }

  onFinishFailed(error) {
    console.error('reset password form error', error)
    return
  }

  onFinish(values) {

    if (this.state.loading === true) {
      return
    } else {
      this.setState({loading: true})
    }

    Firebase.auth().sendPasswordResetEmail(values.email).then(() => {
      this.setState({loading: false, show: false})
      this.props.addMessage('success', t('reset_password_sent', "An email containing instructions to reset your password has been sent to {email}!", {email: values.email}))
    })
    .catch((error) => {
      // var errorCode = error.code;
      // var errorMessage = error.message;
      console.error('reset password error', error)

      if (error.code === "auth/user-not-found") {
        error.message = t('user_not_found', "User not found.")
      }

      this.props.addMessage('error', error.message)
      this.setState({loading: false})
    })
  }

  render() {
    return <React.Fragment>
      <div className="link margin-v-m" onClick={this.toggleModal}>{t('forgot_password', "Forgot password?")}</div>    
      <ForgotPasswordModal
        visible={this.state.show}
        onSuccess={this.onFinish}
        onCancel={this.toggleModal}
        loading={this.state.loading}
      />
    </React.Fragment>
  }
}

const ForgotPasswordModal = ({ visible, onSuccess, onCancel, loading }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title={t('forgot_password', "Forgot password?")}
      okText={t('send_me_a_new_password', "Send me a new password")}
      okButtonProps={{loading: loading}}
      cancelButtonProps={{loading: loading}}
      cancelText={t('cancel', "Cancel")}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSuccess(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item label={t('email_address', "Email address")} name="email" rules={[{required: true, type: 'email'}]}>
          <Input size="large" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={t('email', "Email")} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LoginForm