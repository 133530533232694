import React, { Component } from 'react'
import { 
  Button,
  Dropdown,
  Menu,
  Modal,
} from 'antd'
import t from '../../utils/translate'
import { CopyOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import WidgetForm from './_widgetForm'
import EmailForm from './_emailForm'
import SmsForm from './_smsForm'

class TemplateButton extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      visible: this.props.visible || false,
      fromTemplate: null,
    }

    this.toggleModal = this.toggleModal.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.deleteTemplate = this.deleteTemplate.bind(this)
    this.cloneFrom = this.cloneFrom.bind(this)
  }

  toggleModal() {
    this.setState({visible: !this.state.visible, fromTemplate: null})
  }

  cloneFrom(fromTemplate) {
    // console.log('from', from);
    this.setState({
      visible: true,
      fromTemplate: fromTemplate,
    })
  }

  deleteTemplate() {
    Modal.confirm({
      title: t('delete_template', "Delete template"),
      icon: <ExclamationCircleOutlined />,
      content: t('confirm_delete_template', "Do you really want to delete this template?"),
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      okType: 'danger',
      onOk: () => {

        return new Promise((resolve, reject) => {

          this.props.app.ajaxRequest({
            method: 'post',
            url: '/templates.delete',
            data: {
              projectId: this.props.projectLayout.project.id,
              templateId: this.props.template.id
            }
          }, (error, data) => {

            if (error) {
              this.props.app.addMessage('error', error)
              return reject()
            }

            this.props.app.addMessage('success', 'Your template has been successfully deleted!')

            this.props.projectLayout.fetchNotifications()
            return resolve()
          })

        }).catch((e) => {
          this.props.app.addMessage('error', e)
          console.error(e)
        });
      },
      onCancel() {},
    })
  }

  submitForm(values, onSuccess) {

    if (this.state.loading === true) {
      return
    }

    // parse json

    try { 
      const parsed = JSON.parse(values.testData)
      values.testData = parsed
    }
    catch(e) {
      values.testData = {}
    }

    values.projectId = this.props.projectLayout.project.id

    if (this.props.template) {
      values.templateId = this.props.template.id
    }

    values.notificationId = this.props.notification.id
    values.language = this.props.language
    values.channel = this.props.channel
    
    if (values.cc && values.cc.length) {
      values.cc = values.cc.join(',')
    }
    if (values.bcc && values.bcc.length) {
      values.bcc = values.bcc.join(',')
    }

    // console.log('values', values);

    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/templates.upsert',
      data: values
    }, (error, data) => {

      this.setState({loading: false})

      if (error) {
        // if (error === 'key.exists') {
        //   error = 'This notification key already exists!'
        // }
        this.props.app.addMessage('error', error)
        return
      }

      onSuccess()

      if (this.props.template) {
        this.props.app.addMessage('success', 'Your template has been successfully updated!')
      } else {
        this.props.app.addMessage('success', 'Your template has been successfully created!')        
      }

      this.props.projectLayout.fetchNotifications()
    })
  }
  
  render() {

    const clonables = this.props.notification.templates.filter(x => x.channel === this.props.channel && x.language !== this.props.language)
    // console.log('clonables', clonables);

    const hasDropdown = this.props.template || clonables.length > 0

    const mainBtn = <Button type={this.props.template ? 'default' : 'primary'} size={this.props.btnSize || 'default'} onClick={() => this.setState({visible: true})}>{this.props.template ? t('edit', "Edit") : t('create_template', "Create template")}</Button>

    let btn = mainBtn

    if (hasDropdown) {
      btn = <Dropdown.Button buttonsRender={([leftButton, rightButton]) => [mainBtn, rightButton]} type={this.props.template ? 'default' : 'primary'} size={this.props.btnSize || 'default'} onClick={() => this.setState({visible: true, fromTemplate: null})} overlay={<Menu>
        {this.props.template && <Menu.Item key="delete" onClick={this.deleteTemplate} icon={<DeleteOutlined />}>{t('delete', "Delete")}</Menu.Item>}
        {clonables.map(x => <Menu.Item key={'clone-'+x.language} onClick={this.cloneFrom.bind(null, x)} icon={<CopyOutlined />}>{t('clone_from_template', "Clone from template")} {x.language.toUpperCase()}</Menu.Item>)}
      </Menu>}></Dropdown.Button>
    }

    if (this.state.visible) {
      return <React.Fragment>
        {btn}
        {this.props.channel === 'widget' && <WidgetForm
          loading={this.state.loading}
          submitForm={this.submitForm}
          visible={this.state.visible}
          fromTemplate={this.state.fromTemplate}
          setVisible={(value) => this.setState({visible: value, fromTemplate: null})}
          macros={this.props.projectLayout.macros}
          notification={this.props.notification}
          template={this.props.template}
          language={this.props.language}
          channelTitle={this.props.channelTitle}
          organizationId={this.props.projectLayout.project.organizationId}
          addMessage={this.props.app.addMessage}
        />}
        {this.props.channel === 'email' && <EmailForm
          loading={this.state.loading}
          submitForm={this.submitForm}
          visible={this.state.visible}
          fromTemplate={this.state.fromTemplate}
          setVisible={(value) => this.setState({visible: value, fromTemplate: null})}
          macros={this.props.projectLayout.macros}
          notification={this.props.notification}
          template={this.props.template}
          language={this.props.language}
          channelTitle={this.props.channelTitle}
        />}
        {this.props.channel === 'sms' && <SmsForm
          loading={this.state.loading}
          submitForm={this.submitForm}
          visible={this.state.visible}
          fromTemplate={this.state.fromTemplate}
          setVisible={(value) => this.setState({visible: value, fromTemplate: null})}
          macros={this.props.projectLayout.macros}
          notification={this.props.notification}
          template={this.props.template}
          language={this.props.language}
          channelTitle={this.props.channelTitle}
        />}
      </React.Fragment>
    }

    return btn
  }
}

export default TemplateButton
