import React, { Component } from 'react'

import t from '../../utils/translate'
import { Spin, Button } from 'antd';
import NotificationItem from '../notifications/_item'
import { ArrowRightOutlined } from '@ant-design/icons';

class SetupTemplates extends Component {

  render() {

    const notification = this.props.projectLayout.notifications[0]

    if (!notification) {
      return <div className="padding-a-l">
        <Spin />
      </div>
    }

    // console.log('notification', notification);
    // console.log('this.props.projectLayout.project.languages', this.props.projectLayout.project.languages.map(lang => { return {key: lang, value: lang}}));

    return <div className="padding-a-m">
      <p className="padding-b-m">As you can see below, our first notification holds the templates for each channel and each language. Create a template to move forward.</p>

      <NotificationItem {...this.props} notification={notification} />

      {notification.templates.length > 0 && <div className="text-right margin-t-l">
        <Button type="primary" size="large" onClick={() => this.props.setStep('templates')}>{t('next', "Next")} <ArrowRightOutlined /></Button>
      </div>}
    </div>
  }
}

export default SetupTemplates