
export default {
  'en': {
  },
  'fr': {
    'sign_in': 'Se connecter',
    'sign_in_with_google': 'Se connecter avec Google',
    'or_sign_in_with_credentials': 'Ou se connecter avec mot de passe',
    'email_address': 'Adresse email',
    'password': 'Mot de passe',
    'forgot_password': 'Mot de passe oublié?',
    'send_me_a_new_password': 'Envoyez-moi un nouveau mot de passe',
    'or_cancel': 'ou annuler',
    'field_required': 'Ce champ est requis!',
    'password_format_invalid': 'Le mot de passe doit contenir au moins 6 caractères.',
    'dont_have_account': "Vous n'avez pas d'accès?",
    'sign_up_for_free': 'Créez un compte gratuitement',
    'user_not_found': 'Utilisateur inconnu.',
    'or_sign_up_with_credentials': 'Ou créer un compte avec mot de passe',
    'confirm': 'Confirmer',
    'cancel': 'Annuler',
    'already_have_an_account': 'Vous avez déjà un compte?',
    'email_already_in_use': 'Cette adresse email est déjà utilisée par un autre compte.',
    'wrong_password': "Le mot de passe est invalide.",
    'create_account_terms_checkbox': 'En créant un compte j\'accepte les <a href="https://notifuse.com/terms" target="blank">CGU</a> & <a href="https://notifuse.com/privacy" target="blank">Politique de confidentialité</a>.', 
    'checkbox_terms_required': 'Vous devez accepter les CGU pour pouvoir créer votre compte.',
    'loading': 'Chargement...',
    'reset_password_sent': 'Un email contenant les instructions pour changer votre mot de passe a été envoyé à {email}!',
    'verify_your_email_address': 'Vérifiez votre adresse email',
    'reset_your_password': 'Réinitialisez votre mot de passe',
    'new_password': 'Nouveau mot de passe',
    'password_reset_success': 'Votre mot de passe a été mis à jour!',
    'email_verified': 'Votre adresse email a bien été vérifiée, merci!',
    'email_recovered': 'Votre ancienne adresse email a bien été récupérée!',
    'first_name': 'Prénom',
    'last_name': 'Nom',
    'preferred_language': 'Langue préférée',
    'time_zone': 'Fuseau horaire',
    'complete_your_profile': 'Complétez votre profil',
    'user_profile_updated': 'Votre profile a été mis à jour!',
    'select_an_organization': 'Sélectionnez une organisation',
    'or': 'ou',
    'create_an_organization': 'Créer une nouvelle organisation',
    'organization_created': 'Votre organisation a été créée',
    'select_a_currency': 'Sélectionnez une devise',
    'currency': 'Devise',
    'organization_name': "Nom de l'organisation",
    'welcome': 'Bienvenue !',
    'check_your_mailbox': "Veuillez suivre le lien envoyé sur votre email pour vérifier votre compte.",
    'country': 'Pays',
    'select_a_country': 'Sélectionnez un pays',
    'valid_url_required': 'Une URL valide est requise!',
    'project_name': 'Nom du projet',
    'privacy_policy_url': 'URL de votre Politique de Confidentialité',
    'website_url': 'URL de votre site',
    'project_created': 'Votre nouveau projet a été créé!',
  } 
}