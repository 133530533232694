import React, { Component } from 'react'

import t from '../../utils/translate'
import { RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Link } from 'react-router-dom'
import Splash from '../app/_splash'

class OrganizationsList extends Component {

  componentDidMount() {
    // redirect to onboarding if no organizations
    if (this.props.app.state.organizations.length === 0) {
      return this.props.history.push('/create-organization')
    }

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'List organizations',
        page: '/organizations' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }
  }

  render() {
    // console.log('props', this.props)

    return (
      <Splash h1={t('select_an_organization', "Select an organization")}>          
        <div className="list">
          {(this.props.app.state.organizations.map((org, i) => {
            return (
              <Link className="list-item" to={'/organizations/'+org.id} key={org.id}>
                <div className="list-item-title">{org.name}</div>
                <div className="list-item-icon"><RightOutlined /></div>
              </Link>
            );
          }))}
        </div>

        <div className="text-center padding-v-m">{t('or', "or")}</div>

        <div className="text-center">
          <Button type="primary" ghost onClick={() => this.props.history.push('/create-organization')}>{t('create_an_organization', "Create a new organization")}</Button>
        </div>
      </Splash>
    );
  }
}

export default OrganizationsList