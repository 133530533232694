import React, { Component } from 'react'

import t from '../../utils/translate'
import LoginForm from './_loginForm'
import Splash from './_splash'

class Login extends Component {

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Login',
        page: '/login' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }
  }

  render() {
    return <Splash h1={t('sign_in', "Sign in")}>
      <LoginForm addMessage={this.props.addMessage} signIn={this.props.signIn} />
    </Splash>
  }
}

export default Login