import React from 'react'
import { 
  // Row,
  // Col,
  Button,
  Modal,
} from 'antd'
// import _ from 'lodash'
import t from '../../utils/translate'
import MacroForm from '../macros/_form'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const MacroItem = ({app, projectLayout, macro}) => {

  // console.log('MacroItem projectLayout', projectLayout);
  // const [loading, setLoading] = useState(false);

  // console.log('form', form);

  const deleteConfirm = () => {
    Modal.confirm({
      title: 'Delete this macros page?',
      content: 'Do you really want to delete this macros page?',
      icon: <ExclamationCircleOutlined />,
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      okType: 'danger',
      onOk: () => {
        return new Promise((resolve, reject) => {

          app.ajaxRequest({
            method: 'post',
            url: '/macros.delete',
            data: {
              projectId: projectLayout.project.id,
              id: macro.id
            }
          }, (error, data) => {

            if (error) {
              app.addMessage('error', error)
              return reject(error)
            }

            app.addMessage('success', 'Your macros page has been successfully deleted!')

            projectLayout.fetchNotifications()
            return resolve()
          })
        })
      },
    })
  }

  return <div className="block-notif">
    <div className="block-notif-title">
      <span className="actions">
        <Button.Group>
          <Button type="ghost" size="small" onClick={deleteConfirm}><DeleteOutlined /></Button>
          <MacroForm app={app} btnSize="small" projectLayout={projectLayout} inDrawer={true} macro={macro} />
        </Button.Group>
      </span>
      {macro.name}
    </div>

  </div>
}

export default MacroItem
