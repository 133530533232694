import React, { Component } from 'react'
 
import t from '../../utils/translate'
import _ from 'lodash'
import { Link, matchPath } from 'react-router-dom'
import { HomeOutlined } from '@ant-design/icons';

class Breadcrumb extends Component {

  render() {
    // console.log('props', this.props)
      
    let paths = []

    const path = this.props.location.pathname
    const matchProject = matchPath(path, {path: '/organizations/:organizationId/projects/:projectId'})
    const currentProject = _.find(this.props.organizationLayout.projects, (p) => _.get(matchProject, 'params.projectId', null) === p.id)
    const org = this.props.organizationLayout.organization
    
    if (path.includes('/organizations/')) {
      paths.push({route: '/', title: <HomeOutlined />})
      paths.push({route: '/organizations/:organizationId', title: t('organization', "Organization")+': '+org.name})
    }
  
    if (path.includes('/create-project')) {
      paths.push({route: '/organizations/:organizationId/create-project', title: t('create_a_new_project', "Create a new project")})
    }

    
    if (currentProject) {
      if (path.includes('/projects/')) {
        paths.push({route: '/organizations/:organizationId/projects/:projectId', title: t('project', "Project")+': '+currentProject.name})
      }
    
      if (path.includes('/setup')) {
        paths.push({route: '/organizations/:organizationId/projects/:projectId/setup', title: t('setup', "Setup")})
      }
    
      if (path.includes('/settings')) {
        paths.push({route: '/organizations/:organizationId/projects/:projectId/settings', title: t('settings', "Settings")})
      }

      if (path.includes('/templates')) {
        paths.push({route: '/organizations/:organizationId/projects/:projectId/templates', title: t('templates', "Templates")})
      }

      if (path.includes('/macros')) {
        paths.push({route: '/organizations/:organizationId/projects/:projectId/macros', title: t('macros', "Macros")})
      }

      if (path.includes('/playground')) {
        paths.push({route: '/organizations/:organizationId/projects/:projectId/playground', title: 'Playground'})
      }
    
      if (path.includes('/users')) {
        paths.push({route: '/organizations/:organizationId/projects/:projectId/users', title: t('users', "Users")})

        if (path.endsWith('/users') === false) {
          paths.push({route: '/organizations/:organizationId/projects/:projectId/users/:userId', title: t('user_profile', "User profile")})
        }
      }
    }

    const href = (route) => {
      if (this.props.match.params.organizationId) {
        route = route.replace(':organizationId', this.props.organizationLayout.organization.id)  
      }

      if (currentProject) {
        route = route.replace(':projectId', currentProject.id)  
      }

      if (this.props.match.params.userId) {
        route = route.replace(':userId', this.props.match.params.userId)  
      }
      
      return route
    }

    return <div className="breadcrumb">{paths.map((p, i) => (i < paths.length-1) ? <span key={i}><Link to={href(p.route)}>{p.title}</Link><span className="breadcrumb-spacer">/</span></span> : <span key={i}>{p.title}</span>)}</div>
  }
}

export default Breadcrumb