import React, { Component } from 'react'

import t from '../../utils/translate'
// import { ReloadOutlined } from '@ant-design/icons';
import { 
  Alert,
  // Row, 
  // Col, 
} from 'antd'
// import _ from 'lodash'
import MacroItem from './_item'
import MacroForm from './_form'

class ProjectMacros extends Component {

  componentDidMount() {

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Macros pages (project: '+this.props.projectLayout.project.id+')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  render() {

    return <div>
      <h1 className="margin-b-l">
      <div className="actions"><MacroForm {...this.props} inDrawer={true} /></div>
        {t('macros_pages', "Macros pages")}
      </h1>

      <div className="margin-h-m">
        <Alert
          description={t('macros_page_info', "A macros page is the place where you can declare useful templating macros that can be used in your notifications templates (i.e: your HTML email layout).")}
          type="info"
          showIcon
          className="margin-b-l"
        />
        
        {this.props.projectLayout.macros.map(macro => <MacroItem 
          key={macro.id} 
          app={this.props.app}
          projectLayout={this.props.projectLayout}
          macro={macro} />
        )}
      </div>
    </div>
  }
}

export default ProjectMacros