import React, { Component } from 'react'

import { ArrowRightOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';

import { Button, Popconfirm } from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'

class ProjectsList extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
    }

    this.resetDemo = this.resetDemo.bind(this)
    this.deleteDemo = this.deleteDemo.bind(this)
  }
  
  componentDidMount() {
    if (this.props.organizationLayout.organization.kind !== 'mediaPartner' && this.props.organizationLayout.projects.length === 0) {
      return this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/create-project')
    }

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Projects (org: '+this.props.organizationLayout.organization.id+')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.organizationLayout.organization.id,
        }
      })
      window.cmAgent.dispatch()
    }
  }

  resetDemo(projectId) {
    if (this.state.loading === true) {
      return
    }

    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/projects.resetDemo',
      data: {
        organizationId: this.props.organizationLayout.organization.id,
        projectId: projectId,
      }
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      // remove the previous project and add the new one
      let projects = _.filter(this.props.organizationLayout.projects, (p) => p.id !== projectId)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('reset_demo_success', 'The demo has been reset with fresh data!'))

      this.setState({loading: false})
      this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+response.data.project.id)
    })
  }


  deleteDemo(projectId) {
    if (this.state.loading === true) {
      return
    }

    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/projects.deleteDemo',
      data: {
        organizationId: this.props.organizationLayout.organization.id,
        projectId: projectId,
      }
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p) => p.id !== projectId)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('delete_demo_success', 'The demo has been deleted!'))

      this.setState({loading: false})
    })
  }

  render() {

    return (
      <div className="layout-content">

        <h1 className="margin-b-l">
          {t('projects', "Projects")}
          <span className="actions">
            <Button type="ghost" onClick={() => this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/create-project')}>{t('create_a_new_project', "Create a new project")}</Button>
          </span>
        </h1>

        <div className="margin-a-s">
        {this.props.organizationLayout.projects.filter(x => !x.deletedAt).map(p => {
          return <div key={p.id} style={{float: 'left', width: '33%'}}>
            <div className="paper margin-a-m">
              <div className="padding-a-m"><b>{p.name}</b></div>
                <div className="padding-a-m">
                  <div><small>ID: {p.id}</small></div>
                  <small><a href={p.websiteUrl} rel="noreferrer noopener" target="blank">{p.websiteUrl}</a></small>
                </div>

                <div className="padding-a-m">
                  {p.demoKind && <Popconfirm placement="topRight" title={t('reset_demo_confirm', "Do you really want to reset the demo with fresh data?")} onConfirm={this.resetDemo.bind(null, p.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Button type="default" className="margin-r-s" icon={<ReloadOutlined />} loading={this.state.loading}>{t('reset_demo', "Reset demo")}</Button>
                  </Popconfirm>}
                  {p.demoKind && <Popconfirm placement="topRight" title={t('delete_demo_confirm', "Do you really want to delete this demo?")} onConfirm={this.deleteDemo.bind(null, p.id)} okText={t('confirm', "Confirm")} cancelText={t('cancel', "Cancel")}>
                    <Button type="default" className="margin-r-s" icon={<DeleteOutlined />} loading={this.state.loading} />
                  </Popconfirm>}
                  {p.status === "setup" && <Button type="primary" block onClick={() => this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+p.id+'/setup')}>{t('setup', "Setup")} <ArrowRightOutlined /></Button>}
                  {p.status === "active" && <Button type="primary" block onClick={() => this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+p.id)}>{t('view', "View")} <ArrowRightOutlined /></Button>}
                </div>
              
            </div>
          </div>
        })}
        </div>
    </div>
    );
  }
}

export default ProjectsList