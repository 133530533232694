import React, { Component } from 'react'

import t from '../../utils/translate'
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

class CancelInvitationButton extends Component {

  constructor(props) {
    super(props)

    this.state = {
      ajaxLoading: false
    }

    this.showConfirm = this.showConfirm.bind(this)
    this.submit = this.submit.bind(this)
  }

  showConfirm() {
    Modal.confirm({
      title: t('cancel_invitation', "Cancel Invitation"),
      content: t('cancel_invitation_content', "Do you really want to cancel the invitation sent to {email}?", {email: this.props.invitation.email}),
      onOk: this.submit,
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      onCancel() {},
    })
  }

  submit() {

    this.setState({ajaxLoading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/organizations.cancelInvitation',
      data: {
        organizationId: this.props.organization.id,
        invitationId: this.props.invitation.id
      }
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addNotification('error', 'Invitation error', errorMessage)
        this.setState({ajaxLoading: false})
        return
      }
      
      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'cancelInvitation',
          props: {
            orgId: this.props.organization.id,
          }
        })
        window.cmAgent.dispatch()
      }

      this.setState({ajaxLoading: false})      
    })
  }

  render() {
    return (
      <Button
        loading={this.state.ajaxLoading}
        size={this.props.size}
        type={this.props.type || 'default'}
        style={this.props.style || {}}
        onClick={this.showConfirm}>
          <DeleteOutlined />
      </Button>
    );
  }
}

export default CancelInvitationButton