import React, { Component } from 'react'

import t from '../../utils/translate'
import _ from 'lodash'
import { Input, Button, Row, Col, Select, Form, Divider } from 'antd';
import Industries from '../../utils/industries'
import Languages from '../../utils/languages'
import Moment from 'moment-timezone'
import ChannelsSetup from '../channels/_setup'
// import { ArrowRightOutlined } from '@ant-design/icons';

const SettingsForm = ({loading, project, organization, onFinish, onFinishFailed}) => {
  const [form] = Form.useForm();

  const initialValues = Object.assign({}, project)

  return <Form
    form={form}
    layout="horizontal"
    name="project_settings_form"
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    labelCol={{sm: { span: 8 }}}
    wrapperCol={{sm: { span: 10 }}}
    initialValues={initialValues}
  >
    <Form.Item 
      name="name"
      label={t('project_name', "Project name")}
      rules={[{ required: true, message: t('field_required', "This field is required!") }]}
    >
      <Input />
    </Form.Item>

    <Form.Item 
      name="languages"
      label={t('notification_languages', "Notification languages")} 
      rules={[{ required: true, type: 'array', min: 1}]}
    >
      <Select 
        mode="multiple"
        placeholder={t('select_a_value', "Select a value")}
        allowClear={false}
        optionFilterProp="label"
        onChange={(values) => {
          // remove default language if not avilable anymore
          if (values.indexOf(form.getFieldValue('defaultNotificationLanguage')) === -1) {
            form.setFieldsValue({'defaultNotificationLanguage': values[0]})
          }
        }}
        options={Languages.map((language) => { return {label: language.alpha2+' - '+language.name, value: language.alpha2}})}
      />
    </Form.Item>

    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.languages.length !== currentValues.languages.length}>
    {({ getFieldValue }) => <Form.Item 
      name="defaultNotificationLanguage"
      label={t('default_notification_language', "Default notification language")} 
      rules={[{ required: true, type: 'string'}]}
    >
      <Select 
        placeholder={t('select_a_value', "Select a value")}
        allowClear={false}
        optionFilterProp="label"
        options={Languages.filter(x => getFieldValue('languages').indexOf(x.alpha2) !== -1).map((language) => { return {label: language.alpha2+' - '+language.name, value: language.alpha2}})}
      />
    </Form.Item>}
    </Form.Item>

    <Form.Item 
      name="defaultNotificationTimezone"
      label={t('default_notification_timezone', "Default notification timezone")} 
      rules={[{ required: true, type: 'string'}]}
    >
      <Select 
        placeholder={t('select_a_value', "Select a value")}
        allowClear={false}
        showSearch={true}
        filterOption={(searchText, option) => {
          return searchText !== '' && option.label.toLowerCase().includes(searchText.toLowerCase())
        }}
        options={Moment.tz.names().map((timezone) => { return {label: timezone, value: timezone}})}
      />
    </Form.Item>

    <Divider plain>{t('gdpr_compliance', "GDPR compliance")}</Divider>

    <Form.Item 
      name="websiteUrl"
      label={t('website_url', "Website URL")} 
      rules={[{ required: true, type: 'url'}]}
    >
      <Input />
    </Form.Item>

    <Form.Item 
      name="privacyPolicyUrl"
      label={t('privacy_policy_url', "URL of your Privacy Policy")} 
      rules={[{ required: true, type: 'url'}]}
    >
      <Input />
    </Form.Item>

    <Form.Item 
      name="dataProtectionOfficerId"
      label={t('data_protection_officer', "Data Protection Officer")} 
      rules={[{ required: true, type: 'string'}]}
    >
      <Select placeholder={t('select_a_value', "Select a value")}>
        {_.toArray(organization.admins).map((admin) => <Select.Option key={admin.id} value={admin.id}>{admin.firstName+' '+admin.lastName}</Select.Option>)}
      </Select>
    </Form.Item>

    <Form.Item 
      name="industry"
      label={t('industry', "Industry")} 
      rules={[{ required: true, type: 'string'}]}
    >
      <Select>
        {Industries.map((i) => <Select.Option key={i.id}>{t(i.id, i.name)}</Select.Option>)}
      </Select>
    </Form.Item>
  
    <Form.Item wrapperCol={{sm: {span: 10, offset: 8}}} className="text-right">
      <Button loading={loading} type="primary" htmlType="submit">{t('confirm', "Confirm")}</Button>
    </Form.Item>
  </Form>
}

class Settings extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
    }

    this.onFinishSettings = this.onFinishSettings.bind(this)
  }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Settings (project: '+this.props.projectLayout.project.id+')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  onFinishSettings(values) {

    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    values.id = this.props.projectLayout.project.id
    values.organizationId = this.props.organizationLayout.organization.id

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/projects.setSettings',
      data: values
    }, (errorMessage, response) => {

      this.setState({loading: false})

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        return
      }

      let projects = this.props.organizationLayout.projects.filter(x => x.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('project_settings_updated', "Your project settings has successfully been updated!"))
      
      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'setProjectSettings',
          props: {
            orgId: this.props.organizationLayout.organization.id,
            projectId: response.data.project.id,
          }
        })
        window.cmAgent.dispatch()
      }
    })
  }

  render() {

    const project = this.props.projectLayout.project
    // console.log('project', project);

    return (
      <div className="layout-content">

        <h1>
          <div className="actions">Project ID: {project.id}</div>
          {t('project_settings', "Project settings")}
        </h1>

        <div className="subtitle padding-a-l">{t('channels', "Channels")}</div>

        <div className="padding-a-m dashed-bottom-2">
          <ChannelsSetup app={this.props.app} organizationLayout={this.props.organizationLayout} projectLayout={this.props.projectLayout} />
        </div>

        <div className="subtitle padding-a-l">{t('keys', "Keys")}</div>

        <div className="padding-v-m padding-h-l dashed-bottom-2">
          <Form
            layout="horizontal"
            name="project_key_form"
            labelCol={{sm: { span: 6 }}}
            wrapperCol={{sm: { span: 16 }}}
            initialValues={{
              apiKey: this.props.projectLayout.project.apiKeys.find(x => !x.deletedAt).key,
              secretKey: this.props.projectLayout.project.secretKeys.find(x => !x.deletedAt).key,
            }}
          >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item 
                name="apiKey"
                label={t('api_key', "API key")}
                rules={[{ required: false}]}
                help={t('api_key_info', "The API key is used to send messages to your users from server-side.")}
              >
                <Input readOnly />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                name="secretKey"
                label={t('secret_key', "Secret key")}
                rules={[{ required: false}]}
                help={t('secret_key_info', "The Secret key is used to sign your user IDs on the server-side with HMAC-256. The signature is then used by the frontside of your app to authenticate each user when you init the widget.")}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          </Form>
        </div>

        <div className="subtitle padding-a-l">{t('settings', "Settings")}</div>

        <SettingsForm loading={this.state.loading} project={this.props.projectLayout.project} organization={this.props.organizationLayout.organization} onFinish={this.onFinishSettings} />
      </div>
    );
  }
}

export default Settings