import React, { Component } from 'react'

import t from '../../utils/translate'

import { 
  Drawer,
  Button,
  Input,
  Select,
  Spin,
  Form,
} from 'antd';
import Moment from 'moment-timezone'

const timezones = Moment.tz.names()

class UserSettings extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
      settingsVisible: false
    }

    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.onFinish = this.onFinish.bind(this)
  }

  toggleDrawer() {
    this.setState({settingsVisible: !this.state.settingsVisible})
  }

  onFinish(values) {

    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/admins.setProfile',
      data: values
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }
      
      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'updateAdminSettings'
        })
        window.cmAgent.dispatch()
      }

      this.setState({loading: true})
      this.props.app.setState({admin: response.data.admin})
      this.props.toggleSettings()
      this.props.app.addMessage('success', t('your_profile_has_been_updated', "Your profile has been updated!"))
    })
  }

  formRef = React.createRef()

  render() {

    return <Drawer
      title={t('my_settings', "My settings")}
      visible={true}
      onClose={this.props.toggleSettings}
      width={600}
      footer={
        <div style={{textAlign: 'right'}}>
          <Button loading={this.state.loading} onClick={() => this.props.toggleSettings()} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
          <Button loading={this.state.loading} onClick={() => {
            this.formRef.current
              .validateFields()
              .then((values) => {
                this.onFinish(values, () => {
                  this.props.toggleSettings()
                })
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }} type="primary">{t('confirm', "Confirm")}</Button>
        </div>
      }
    >
      <Spin tip="Loading..." spinning={this.state.loading}>
        <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish} initialValues={{
          firstName: this.props.app.state.admin.firstName,
          lastName: this.props.app.state.admin.lastName,
          timezone: this.props.app.state.admin.timezone,
          language: this.props.app.state.admin.language,
          picture: this.props.app.state.firebaseUser.photoURL,
        }}>
          <Form.Item name="firstName" label={t('first_name', "First name")} rules={[{ required: true, type: 'string'}]}>
            <Input />
          </Form.Item>

          <Form.Item name="lastName" label={t('last_name', "Last name")} rules={[{ required: true, type: 'string'}]}>
            <Input />
          </Form.Item>

          <Form.Item name="timezone" label={t('time_zone', "Time zone")} rules={[{required: true, type: 'enum', enum: timezones, message: 'This time zone is not valid.'}]}>
            <Select 
                placeholder={t('select_a_value', "Select a value")}
                allowClear={true}
                showSearch={true}
                filterOption={(searchText, option) => {
                  return searchText !== '' && option.key.toLowerCase().includes(searchText.toLowerCase())
                }}>
                  {timezones.map((timezone) => {
                    return <Select.Option key={timezone} value={timezone}>{timezone}</Select.Option>
                  })}
              </Select>
          </Form.Item>

          <Form.Item name="language" label={t('language', "Language")} rules={[{required: true, type: 'enum', enum: ['en', 'fr'], message: 'This language is not valid.'}]}>
            <Select 
              placeholder={t('select_a_value', "Select a value")}
              allowClear={false}>
                <Select.Option key="en" value="en">English</Select.Option>
                <Select.Option key="fr" value="fr">Français</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="picture" label={t('photo_url', "Photo URL")} rules={[{ required: false, type: 'url'}]}>
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  }
}

export default UserSettings