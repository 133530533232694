import React from 'react'
import N from 'numeral'
// import t from './translate'
// import Moment from 'moment-timezone'
// import Numeral from 'numeral'
// import _ from 'lodash'

const currencyFormatter = (currencyCode, amount, originalAmount, originalCurrency, className, classNameOriginal, light) => {
  currencyCode = currencyCode.toUpperCase()
  let str

  amount = N(amount/100).format(light ? '0,0' : '0,0[.]00')

  switch(currencyCode) {
    case 'ARS': str = '$'+amount; break
    case 'AUD': str = '$'+amount; break
    case 'BRL': str = 'R$'+amount; break
    case 'CAD': str = '$'+amount; break
    case 'COP': str = '$'+amount; break
    case 'CZK': str = 'Kč'+amount; break
    case 'DKK': str = 'kr'+amount; break
    case 'EUR': str = amount+'€'; break
    case 'HKD': str = '$'+amount; break
    case 'IDR': str = 'Rp'+amount; break
    case 'ILS': str = '₪'+amount; break
    case 'JPY': str = '¥'+amount; break
    case 'KRW': str = '₩'+amount; break
    case 'MYR': str = 'RM'+amount; break
    case 'MXN': str = '$'+amount; break
    case 'NZD': str = '$'+amount; break
    case 'PKR': str = '₨'+amount; break
    case 'PHP': str = '₱'+amount; break
    case 'SGD': str = '$'+amount; break
    case 'ZAR': str = 'R'+amount; break
    case 'TWD': str = 'NT$'+amount; break
    case 'GBP': str = '£'+amount; break
    case 'USD': str = '$'+amount; break
    default: str = currencyCode+amount
  }

  if (originalCurrency && originalCurrency !== currencyCode && originalAmount > 0) {
    return <span><span className={className || ''}>{str}</span> <span className={classNameOriginal || 'size-12'}>({currencyFormatter(originalCurrency, originalAmount)})</span></span>
  } else {
    return <span className={className || ''}>{str}</span>
  }
}

const exported = {
  currency: currencyFormatter,
  duration: (totalSecs, precision) => {

    if (precision === undefined) {
      precision = 2
    }

    if (!totalSecs) {
      return '0s'
    }
    
    totalSecs = Math.round(totalSecs)

    let result, mins, secs, hours, days

    // in days
    if (totalSecs >= 86400) {
      days = Math.round(totalSecs/86400)
      hours = Math.round((totalSecs-(days*86400))/3600)
      mins = Math.round((totalSecs-(days*86400)-(hours*3600))/60)
      secs = totalSecs-(days*86400)-(hours*3600)-(mins*60)

      result = days+'d'
      if (hours > 0 && precision >= 2) result += ' '+hours+'h'
      if (mins > 0 && precision >= 3) result += ' '+mins+'m'
      if (secs > 0 && precision >= 4) result += ' '+secs+'s'
      return result

    } else if (totalSecs >= 3600) { 

      // in hours
      hours = Math.round(totalSecs/3600)
      mins = Math.round((totalSecs-(hours*3600))/60)
      secs = totalSecs-(hours*3600)-(mins*60)

      result = hours+'h'
      if (mins > 0 && precision >= 2) result += ' '+mins+'m'
      if (secs > 0 && precision >= 3) result += ' '+secs+'s'
      return result

    } else if (totalSecs >= 60) {
      // in mins
      mins = Math.round(totalSecs/60)
      secs = totalSecs%60
      result = mins+'m'
      if (secs > 0) result += ' '+secs+'s'
      return result

    } else {
      return totalSecs+'s'
    }
  },
}

export default exported