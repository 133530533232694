import React, { Component } from 'react'

import t from '../../utils/translate'
import _ from 'lodash'
import Sidebar from './_sidebar'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Avatar, Spin } from 'antd';
import Breadcrumbs from './_breadcrumbs'
import Config from '../../config'
import {
  Route,
  Switch,
} from 'react-router-dom'

import AdminsList from '../admins/list'
import ProjectsList from '../projects/list'
import ProjectsCreate from '../projects/create'
import ProjectsLayout from '../projects/layout'
import UserSettings from './_userSettings'
import Billing from './billing'
import Firebase from 'firebase/app'
import 'firebase/firestore'

class OrganizationsLayout extends Component {
  _isMounted = false;

  constructor(props) {
    super(props)
    
    this.state = {
      // organization: _.find(props.app.state.organizations, (org) => org.id === props.match.params.organizationId),
      loadingProjects: true,
      menuVisible: true,
      settingsVisible: false,
      projects: [],
      hasRunningTask: false,
    }

    this.fetchProjects = this.fetchProjects.bind(this)
    this.setProjects = this.setProjects.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleSettings = this.toggleSettings.bind(this)
    this.setRunningTask = this.setRunningTask.bind(this)
    this.unsubscribe = undefined
  }

  componentWillUnmount() {
    this._isMounted = false;

    window.Notifuse.destroy()
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchOrganization();  
    this.fetchProjects();

    window.Notifuse.init({ 
        // host: 'https://api.notifusedev.com',
        projectId: Config().notifuseProjectId,
        userId: this.props.app.state.admin.id,
        userHmac: this.props.app.state.admin.notifuseHmac,
        onClickMessage: (message) => {
        
            console.log('got click on notifucation', message);
            
             switch (message.notificationId) {
                default:
                  console.log('notification not implemented', message.notificationId);
             }
        },
        bellSelector: '#notifuse-bell',
        styles: {
          container: {
            color: 'initial',
            marginTop: '30px',
            marginRight: '24px'
          }
        },
        classNames: {}
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.organizationId !== this.props.match.params.organizationId) {
      this.fetchOrganization()
      this.fetchProjects()
      
      if (window.cmAgent) {
        window.cmAgent.setCompanyId(this.props.match.params.organizationId)
      }
    }
  }

  fetchOrganization() {

    const Firestore = Firebase.firestore()
    

    this.unsubscribe = Firestore.collection("organizations")
                                .doc(this.props.match.params.organizationId)
                                .onSnapshot((doc) => {

      // this.setState({loading: false})

      const org = doc.data()
      
      // console.log('got org', org);

      const i = this.props.app.state.organizations.findIndex(o => o.id === this.props.match.params.organizationId)
      this.props.app.state.organizations[i] = org
      this.props.app.setState({organizations: this.props.app.state.organizations})

      if (window.cmAgent) {
        window.cmAgent.setCompanyId(this.props.match.params.organizationId)
      }

      // if (this._isMounted) {
      //   this.setState({organization: org})
      // }
    })
  }

  fetchProjects() {

    this.setState({loadingProjects: true})

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/projects.list',
      params: {
        organizationId: this.props.match.params.organizationId
      }
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      if (this._isMounted) {
        this.setState({
          projects: response.data.projects,
          loadingProjects: false
        })
      }
    })
  }

  setRunningTask(value) {
    this.setState({hasRunningTask: value})
  }

  setProjects(projects) {
    this.setState({projects: projects})
  }

  toggleMenu(value) {
    if (value === true || value === false) {
      return this.setState({menuVisible: value})
    }

    this.setState({menuVisible: !this.state.menuVisible})
  }

  toggleSettings() {
    this.setState({settingsVisible: !this.state.settingsVisible})
  }

  render() {
    // if (this.state.loading) {
    //   return null
    // }
    const org = _.find(this.props.app.state.organizations, (org) => org.id === this.props.match.params.organizationId)
    
    const organizationLayout = {
      organization: org,
      projects: _.filter(this.state.projects, (pg) => pg.organizationId === org.id),
      toggleMenu: this.toggleMenu,
      menuVisible: this.state.menuVisible,
      setProjects: this.setProjects,
      fetchProjects: this.fetchProjects,
      hasRunningTask: this.state.hasRunningTask,
      setRunningTask: this.setRunningTask
    }

    return (
      <div className="layout">
        <div className="layout-header">
          <div className={cn('layout-header-logo', {'folded': !this.state.menuVisible})}>

            <svg width="32px" height="32px" viewBox="0 0 480 481" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-319.000000, -1021.000000)">
                        <g transform="translate(334.000000, 991.000000)">
                            <path d="M395,204.187181 L395,456 C395,478.09139 377.09139,496 355,496 L40,496 C17.90861,496 0,478.09139 0,456 L0,141 C0,118.90861 17.90861,101 40,101 L291.25841,101" stroke="#FFFFFF" strokeWidth="30"></path>
                            <polygon fill="#FFFFFF" fillRule="nonzero" points="161.650391 383 161.650391 267.927734 231.798828 383 267.833984 383 267.833984 208.109375 231.918945 208.109375 231.918945 323.421875 161.650391 208.109375 125.615234 208.109375 125.615234 383"></polygon>
                            <circle fill="#E91E63" cx="395" cy="100" r="70"></circle>
                        </g>
                    </g>
                </g>
            </svg>
          </div>

          <div className="layout-header-breadcrumb"><Breadcrumbs {...this.props} organizationLayout={organizationLayout} /></div>

          <div className="layout-header-user-container">
            {this.state.settingsVisible && <UserSettings {...this.props} toggleSettings={this.toggleSettings} />}
            <Dropdown overlay={<Menu>
              <Menu.Item onClick={this.toggleSettings}><div className="margin-a-s"><FontAwesomeIcon icon="cog" className="margin-r-s" /> {t('my_settings', "My settings")}</div></Menu.Item>
              <Menu.Item><div className="margin-a-s" onClick={() => { this.props.history.push('/sign-out')}}><FontAwesomeIcon icon="power-off" className="margin-r-s" /> {t('sign_out', "Sign out")}</div></Menu.Item>
            </Menu>}>
              <div className="layout-header-user">
                <div className="layout-header-user-picture">
                  {this.props.app.state.firebaseUser.photoURL ?
                    <Avatar src={this.props.app.state.firebaseUser.photoURL} />
                    :
                    <span style={{fontSize: '32px', verticalAlign: 'middle'}}><FontAwesomeIcon icon="user-circle" /></span>
                  }
                </div>
                <div className="layout-header-user-name">
                  <div className="layout-header-user-name-top">
                    {this.props.app.state.admin.firstName+' '+this.props.app.state.admin.lastName}<br/>
                    <span className="layout-header-user-name-sub"><FontAwesomeIcon icon="clock" className="margin-r-s" />{this.props.app.state.admin.timezone}</span>
                  </div>
                </div>
                <div className="layout-header-user-button"><DownOutlined className="margin-l-s" /></div>
              </div>
            </Dropdown>
          </div>
          
          <div className="layout-header-item layout-header-bell" id="notifuse-bell">
             <FontAwesomeIcon icon="bell" />
          </div>
          
          <a className="layout-header-item" href="https://docs.notifuse.com?utm_source=notifuse.com&utm_medium=console" target="_blank" rel="noopener noreferrer">Docs</a>        </div>

        <div className="layout-aside">
          <Sidebar {...this.props} organizationLayout={organizationLayout} />

          <div className="layout-main">
            {this.state.loadingProjects ? <div className="text-center margin-a-l">
                <Spin size="large" />
              </div>
            :
              <Switch>
                <Route exact path="/organizations/:organizationId" render={(routerProps) => <ProjectsList app={this.props.app} {...routerProps} organizationLayout={organizationLayout} />} />
                <Route path="/organizations/:organizationId/admins" render={(routerProps) => <AdminsList app={this.props.app} {...routerProps} organizationLayout={organizationLayout} />} />
                <Route path="/organizations/:organizationId/create-project" render={(routerProps) => <ProjectsCreate app={this.props.app} {...routerProps} organizationLayout={organizationLayout} />} />
                <Route path="/organizations/:organizationId/projects/:projectId" render={(routerProps) => <ProjectsLayout app={this.props.app} {...routerProps} organizationLayout={organizationLayout} />} />
                <Route path="/organizations/:organizationId/billing" render={(routerProps) => <Billing app={this.props.app} {...routerProps} organizationLayout={organizationLayout} />} />
                <Route render={() => <div>Route not found :(</div>}/>
              </Switch>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationsLayout