import React, { Component } from 'react'
import { Alert } from 'antd';
import t from '../../utils/translate'
import NotificationForm from '../notifications/_form'

class SetupNotification extends Component {

  render() {

    return (
      <div>
        <div className="padding-v-m">
          
          <Alert
            description={t('templates_info', "A notification defines which channels it can be sent to, and holds the templates for each channel/translation.")}
            type="info"
            showIcon
            className="margin-a-l"
          />

          <NotificationForm {...this.props} inDrawer={false} onComplete={() => this.props.setStep('notification')} />
        </div>
      </div>
    );
  }
}

export default SetupNotification