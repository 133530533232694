import React, { Component } from 'react'
import { Button, Alert, InputNumber, Form, Input, Divider, Switch, Drawer, Row, Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import _ from 'lodash'
import t from '../../utils/translate'
import AceInput from './_aceInput'

const NotificationForm = ({inDrawer, loading, project, notifications, notification, drawerVisible, setDrawerVisible, onFinish, onFinishFailed}) => {
  const [form] = Form.useForm();

  const initialValues = notification ? Object.assign({}, notification) : {
    name: t('welcome_email', "Welcome email"),
    nameTranslations: {},
    id: 'welcome',
    withWidget: true,
    withEmail: true,
    testData: JSON.stringify({}, null, 2),
    visibleInPreferences: true,
    allowMuteEmail: true,
    allowMuteSMS: true,
    increment: false,
    incrementTimeout: 480,
  }

  if (notification) {
    initialValues.testData = JSON.stringify(notification.testData ? notification.testData : {}, null, 2)
  }

  // console.log('initialValues', initialValues);

  const formContent = <Form
    form={form}
    layout="horizontal"
    name="create_notif_form"
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    labelCol={{sm: { span: 8 }}}
    wrapperCol={{sm: { span: 14 }}}
    initialValues={initialValues}
  >
    <Form.Item 
      name="name"
      label={t('notification_name', "Notification name")+' '+project.defaultNotificationLanguage.toUpperCase()}
      rules={[{ required: true, message: t('field_required', "This field is required!") }]}
    >
      <Input placeholder="i.e: Welcome email" onChange={(e) => {
        const name = e.target.value
        if (name) {
          let newId = _.truncate(_.camelCase(name).toLowerCase(), {omission: ''})
          if (newId !== '') {
            form.setFieldsValue({'id': newId})     
          }                 
        }
      }} />
    </Form.Item>

    {project.languages.filter(lang => lang !== project.defaultNotificationLanguage).map(lang => <Form.Item 
      key={lang}
      name={['nameTranslations',lang]}
      label={t('notification_name', "Notification name")+' '+lang.toUpperCase()}
      rules={[{ required: false, type: 'string' }]}
    >
      <Input />
    </Form.Item>)}

    <Form.Item 
      name="id"
      label={t('notification_id', "Notification ID")} 
      rules={[
        { required: true, type: 'string', pattern: /^[a-z0-9-]+$/, message: t('only_alphanumeric_characters', "Only alphanumeric characters allowed!") },
        { validator:(item, value) => {
          if (notification) {
            return Promise.resolve()
          }

          if (_.find(notifications, x => x.id === value)) {
            return Promise.reject(t('this_notification_id_already_exists', "This notification ID already exists."))
          }
          return Promise.resolve()
        }},
      ]}
      shouldUpdate
    >
      <Input disabled={notification ? true : false} placeholder="i.e: welcome" />
    </Form.Item>

    <Divider plain>{t('channels', "Channels")}</Divider>

    <Row gutter={24}>
      <Col span={12}>
        <Form.Item 
          labelCol={{span: 16}}
          name="withWidget"
          label={t('in_app_widget', "In-app widget")} 
          rules={[{ required: false, type: 'boolean' }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item 
          labelCol={{span: 16}}
          name="withSMS"
          label={t('sms', "SMS")} 
          rules={[{ required: false, type: 'boolean' }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item 
          labelCol={{span: 12}}
          name="withEmail"
          label={t('email', "Email")} 
          rules={[{ required: false, type: 'boolean' }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item 
          labelCol={{span: 12}}
          name="withWebPush"
          label="Web Push"
          help="Coming soon..."
          rules={[{ required: false, type: 'boolean' }]}
          valuePropName="checked"
        >
          <Switch disabled />
        </Form.Item>
      </Col>
    </Row>

    {/*<Form.Item 
      name="withSlack"
      label="Slack"
      help="Coming soon..."
      rules={[{ required: false, type: 'boolean' }]}
      valuePropName="checked"
    >
      <Switch disabled />
    </Form.Item>*/}

    <Divider plain>{t('user_preferences', "User preferences")}</Divider>

    <Alert className="margin-a-l" type="info" message={t('visible_in_preferences_info', "We recommend you to hide special notifications (i.e: reset passord...) from the user preferences")} />
    
    <Form.Item 
      name="visibleInPreferences"
      label={t('visible_in_preferences', "Visible in preferences")}
      rules={[{ required: false, type: 'boolean' }]}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>

    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.visibleInPreferences !== curValues.visibleInPreferences}>
    {({ getFieldValue }) => {
      if (getFieldValue('visibleInPreferences') === true) {
        return <Row gutter={12}>
          <Col span={12}>
            <Form.Item 
              labelCol={{span: 16}}
              name="allowMuteWidget"
              label={t('allow_mute_widget', "Allow mute widget?")}
              rules={[{ required: false, type: 'boolean' }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item 
              labelCol={{span: 16}}
              name="allowMuteSMS"
              label={t('allow_mute_email', "Allow mute sms?")}
              rules={[{ required: false, type: 'boolean' }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item 
              labelCol={{span: 12}}
              name="allowMuteEmail"
              label={t('allow_mute_email', "Allow mute email?")}
              rules={[{ required: false, type: 'boolean' }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item 
              labelCol={{span: 12}}
              name="allowMuteWebPush"
              label={t('allow_mute_web_push', "Allow mute web push?")}
              rules={[{ required: false, type: 'boolean' }]}
              valuePropName="checked"
              help={t('coming_soon', "Coming soon...")}
            >
              <Switch disabled />
            </Form.Item>
          </Col>
        </Row>
      }}}
    </Form.Item>

    <Divider plain>{t('incrementation', "Incrementation")}</Divider>

    <Form.Item 
      name="increment"
      label={t('allow_incrementation', "Allow incrementation")}
      help="Increments a counter on recurring notification instead of spamming your users."
      rules={[{ required: false, type: 'boolean' }]}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>

    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.increment !== curValues.increment}>
    {({ getFieldValue }) => {
      if (getFieldValue('increment') === true) {
        return <Form.Item 
          name="incrementTimeout"
          label={t('new_increment_every', "New increment every")}
          rules={[{ required: true, type: 'integer', min:1 }]}
        >
          <InputNumber formatter={value => `${value} mins`} parser={value => value.replace(' mins', '')} style={{width: "150px"}} />
        </Form.Item>
      }
      return
    }}
    </Form.Item>

    {notification && <div>
      <Divider plain>{t('test_data', "Test data")}</Divider>

      <p>{t('test_data_info', "The \"test data\" is a JSON object that is used to customize your templates when you want to test them.")}</p>
      <Form.Item 
        name="testData"
        labelCol={{sm: { span: 0 }}}
        wrapperCol={{sm: { span: 24 }}}
        validateFirst={true}
        rules={[
          { validator:(xxx, value) => {
            // check if data is valid json
            try { 
              if (JSON.parse(value)) {}
              return Promise.resolve()
            }
            catch(e) {
              return Promise.reject(t('test_data_is_not_valid_json', "Your test variables is not a valid JSON object!")) 
            }
          }},
          { required: false, type: 'object', transform: (value) => {
            try { 
              const parsed = JSON.parse(value)
              return parsed
            }
            catch(e) {
              return value
            }
          } },
        ]}
      >
        <AceInput id="testData" width="100%" height="150px" mode="json" />
      </Form.Item>
    </div>} 

    {!inDrawer && <Form.Item wrapperCol={{sm: {span: 12, offset: 10}}} className="text-right margin-t-m">
      <Button loading={loading} type="primary" htmlType="submit">{t('create', "Create")} <ArrowRightOutlined /></Button>
    </Form.Item>} 
  </Form>

  if (!inDrawer) {
    return formContent
  }

  return <Drawer
    title={(notification) ? t('edit_notification', "Edit notification") : t('create_a_notification', "Create a notification")}
    visible={drawerVisible}
    closable={true}
    width={720}
    onClose={() => { setDrawerVisible(false) }}
    bodyStyle={{ paddingBottom: 80 }}
    footer={
      <div style={{textAlign: 'right'}}>
        <Button loading={loading} onClick={() => setDrawerVisible(false)} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
        <Button loading={loading} onClick={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }} type="primary">{t('confirm', "Confirm")}</Button>
      </div>
    }
  >
  {formContent}
  </Drawer>
}


class NotificationDrawer extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      drawerVisible: false
    }

    this.onFinish = this.onFinish.bind(this)
    this.onFinishFailed = this.onFinishFailed.bind(this)
    this.setDrawerVisible = this.setDrawerVisible.bind(this)
  }

  setDrawerVisible() {
    this.setState({drawerVisible: !this.state.drawerVisible})
  }

  onFinishFailed(error) {
    console.error('form error', error)
    return
  }

  onFinish(values) {

    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    values.projectId = this.props.projectLayout.project.id

    if (values.testData) {
      try { 
        const parsed = JSON.parse(values.testData)
        values.testData = parsed
      }
      catch(e) {
        values.testData = {}
      }
    }

    // update
    if (this.props.notification) {
      values.id = this.props.notification.id
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: this.props.notification ? '/notifications.update' : '/notifications.create',
      data: values
    }, (errorMessage, response) => {

      if (errorMessage) {
        if (errorMessage === 'id/exists') errorMessage = t('this_notification_id_already_exists', "This notification ID already exists.")

        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      this.props.app.addMessage('success', t('notification_created', "The notification has successfully been created!"))

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'createNotification',
          // props: {
            // orgId: response.data.organization.id,
            // projectId: 
          // }
        })
        window.cmAgent.dispatch()
      }

      if (this.props.inDrawer) this.setDrawerVisible(false)
      this.setState({loading: false})

      if (this.props.onComplete) this.props.onComplete()

      // refresh notifications
      this.props.projectLayout.fetchNotifications()
    })
  }

  render() {

    // console.log('qsd', this.state);

    // only init the form when needed, so it doesn't cache the fields if the notification testData is updated by templates
    return <span>
      {(!this.props.inDrawer || this.state.drawerVisible) && <NotificationForm
        project={this.props.projectLayout.project} 
        inDrawer={this.props.inDrawer} 
        notification={this.props.notification}
        notifications={this.props.projectLayout.notifications}
        drawerVisible={this.state.drawerVisible}
        setDrawerVisible={this.setDrawerVisible}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        loading={this.state.loading}
      />}
      {this.props.inDrawer && <Button loading={this.state.loading} type={this.props.notification ? 'default': 'primary'} size={this.props.btnSize || 'normal'} onClick={() => this.setDrawerVisible(true)}>{this.props.notification ? t('Edit', "Edit") : t('create_a_notification', "Create a notification")}</Button>}
    </span>
  }
}

export default NotificationDrawer
