import React, { Component } from 'react'
 
import _ from 'lodash'
import { Alert } from 'antd'
import {
  Route,
  Switch,
  Link,
} from 'react-router-dom'
import Dashboard from './dashboard'
import { Spin, Button } from 'antd'
import Setup from './setup'
import Templates from '../notifications/list'
import Macros from '../macros/list'
import Users from '../users/list'
import Playground from './playground'
import Settings from './settings'
// import ShowUser from '../users/_show'
import QS from 'qs'
import t from '../../utils/translate'

class ProjectLayout extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
      notifications: [],
      macros: [],
    }

    this.viewUser = this.viewUser.bind(this)
    this.fetchNotifications = this.fetchNotifications.bind(this)
  }

  componentDidMount() {
    const project = (this.props.match.params.projectId) ? _.find(this.props.organizationLayout.projects, (p) => p.id === this.props.match.params.projectId) : null;

    if (project && project.status === "setup" && this.props.location.pathname.indexOf('/setup') === -1) {
      return this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+project.id+'/setup')
    }

    // this.watchRunningTasks()
    this.fetchNotifications()
  }

  componentWillUnmount() {
    // if (this.unsubscribe) {
    //   this.unsubscribe()
    // }
  }

  componentDidUpdate(prevProps){
    // check if project has been swiched
    if (prevProps.match.params.projectId !== this.props.match.params.projectId) {
      // this.watchRunningTasks()
      this.fetchNotifications()
    }
  }


  fetchNotifications() {
    if (this.state.loading === true) {
      return
    }

    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/notifications.list',
      params: {
        projectId: this.props.match.params.projectId,
      }
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      // console.log('fetch notifs', response.data);
      this.setState({
        loading: false, 
        notifications: response.data.notifications,
        macros: response.data.macros,
      })
    })
  }


  viewUser(userId) {
    let params = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})
    params['showUser'] = userId
    this.props.history.push(window.location.pathname+'?'+QS.stringify(params))
  }

  render() {
    const project = (this.props.match.params.projectId) ? _.find(this.props.organizationLayout.projects, (p) => p.id === this.props.match.params.projectId) : null;

    if (!project) {
      return <Alert type="error" message="Project not found :(" className="margin-a-l" />
    }

    // const params = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})
    const projectLayout = {
      project: project,
      notifications: this.state.notifications,
      macros: this.state.macros,
      templates: this.state.templates,
      fetchNotifications: this.fetchNotifications,
      viewUser: this.viewUser,
      // subscribeToRunningTasks: this.subscribeToRunningTasks,
      // unsubscribeFromRunningTasks: this.unsubscribeFromRunningTasks,
    }

    // console.log('project', project);

    let withEmail = false
    let withSMS = false

    this.state.notifications.forEach(x => {
      if (x.withEmail) withEmail = true
      if (x.withSMS) withSMS = true
    })

    return <React.Fragment>
      {/*params.showUser && params.showUser !== '' && <ShowUser 
        app={this.props.app}
        userId={params.showUser}
        projectLayout={projectLayout}
        history={this.props.history}
        location={this.props.location}
      />*/}

      {project.status === 'active' && withEmail && (!project.emailProvider || project.emailProvider === '') && <div className="warning-box">
        {t('email_provider_not_connect', "You have to connect an email provider to be able to send email notifications.")} <Link to={'/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+project.id+'/settings'}><Button size="small" ghost type="primary" className="margin-l-m">{t('connect', "Connect")}</Button></Link>
      </div>}
      {project.status === 'active' && withSMS && (!project.smsProvider || project.smsProvider === '') && <div className="warning-box">
        {t('sms_provider_not_connect', "You have to connect an SMS provider to be able to send SMS notifications.")} <Link to={'/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+project.id+'/settings'}><Button size="small" ghost type="primary" className="margin-l-m">{t('connect', "Connect")}</Button></Link>
      </div>}


      <Spin spinning={this.state.loading} size="large">
        <Switch>
          <Route exact path="/organizations/:organizationId/projects/:projectId" render={(routerProps) => <Dashboard app={this.props.app} {...routerProps} organizationLayout={this.props.organizationLayout} projectLayout={projectLayout} />} />
          <Route path="/organizations/:organizationId/projects/:projectId/setup" render={(routerProps) => <Setup app={this.props.app} {...routerProps} organizationLayout={this.props.organizationLayout} projectLayout={projectLayout} />} />
          <Route path="/organizations/:organizationId/projects/:projectId/templates" render={(routerProps) => <Templates app={this.props.app} {...routerProps} organizationLayout={this.props.organizationLayout} projectLayout={projectLayout} />} />
          <Route path="/organizations/:organizationId/projects/:projectId/macros" render={(routerProps) => <Macros app={this.props.app} {...routerProps} organizationLayout={this.props.organizationLayout} projectLayout={projectLayout} />} />
          <Route path="/organizations/:organizationId/projects/:projectId/users" render={(routerProps) => <Users app={this.props.app} {...routerProps} organizationLayout={this.props.organizationLayout} projectLayout={projectLayout} />} />
          <Route path="/organizations/:organizationId/projects/:projectId/settings" render={(routerProps) => <Settings app={this.props.app} {...routerProps} organizationLayout={this.props.organizationLayout} projectLayout={projectLayout} />} />
          <Route path="/organizations/:organizationId/projects/:projectId/playground" render={(routerProps) => <Playground app={this.props.app} {...routerProps} organizationLayout={this.props.organizationLayout} projectLayout={projectLayout} />} />
          {/*<Route path="/organizations/:organizationId/projects/:projectId/tasks" render={(routerProps) => <TasksList app={this.props.app} {...routerProps} organizationLayout={this.props.organizationLayout} projectLayout={projectLayout} />} />
          <Route path="/organizations/:organizationId/projects/:projectId/logs" render={(routerProps) => <LogsList app={this.props.app} {...routerProps} organizationLayout={this.props.organizationLayout} projectLayout={projectLayout} />} />
        */}
          <Route render={() => <div>Route not found :(</div>}/>
        </Switch>
      </Spin>
    </React.Fragment>
  }
}

export default ProjectLayout