import React, { Component } from 'react'
 
import t from '../../utils/translate'
import { Steps, Spin } from 'antd'
import SetupNotification from './_setupNotification'
import SetupTemplates from './_setupTemplates'
import SetupIntegrations from './_setupIntegrations'
import _ from 'lodash'

class ProjectSetup extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
    }

    this.setStep = this.setStep.bind(this)
  }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Setup (project: '+this.props.projectLayout.project.id+')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  setStep(step) {
    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/projects.setSetupStep',
      data: {projectId: this.props.projectLayout.project.id, step: step}
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      let projects = _.filter(this.props.organizationLayout.projects, (p) => p.id !== response.data.project.id)
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.setState({loading: false})
        
      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'setSetupStep',
          props: {
            orgId: this.props.organizationLayout.organization.id,
            projectId: this.props.projectLayout.project.id,
            setupStep: step
          }
        })
        window.cmAgent.dispatch()
      }

      if (response.data.project.status === 'active') {
        this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+response.data.project.id)
      }
    })
  }

  render() {

    let step = 0

    if (this.props.projectLayout.project.setupStep === "notification") {
      step = 1
    }

    if (this.props.projectLayout.project.setupStep === "templates") {
      step = 2
    }

    if (this.props.projectLayout.project.setupStep === "integrations") {
      step = 3
    }

    return <div>
      <h1 className="margin-b-l">{t('setup', "Setup")}</h1>

      <div className="margin-a-l">
        <Steps size="small" current={step} className="padding-b-m">
          <Steps.Step title={t('create_notification', "Create notification")} />
          <Steps.Step title={t('create_templates', "Create templates")} />
          <Steps.Step title={t('app_integration', "App integration")} />
        </Steps>
      </div>

      <Spin size="large" spinning={this.state.loading}>
        {(this.props.projectLayout.project.setupStep === "start") && <SetupNotification {...this.props} setStep={this.setStep} />}
        {(this.props.projectLayout.project.setupStep === "notification") && <SetupTemplates {...this.props} setStep={this.setStep} />}
        {(this.props.projectLayout.project.setupStep === "templates") && <SetupIntegrations {...this.props} setStep={this.setStep} />}
      </Spin>
    </div>
  }
}

export default ProjectSetup