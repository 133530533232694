import React, { Component } from 'react'
import { 
  Button, 
} from 'antd'
// https://github.com/ajaxorg/ace-builds/tree/master/src-noconflict
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-nunjucks"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-chrome"
import "ace-builds/src-noconflict/ext-language_tools"
import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons'

class AceInput extends Component {

  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }
  }

  render() {
    return <div className={this.state.expanded ? 'Iframe expanded': ''}>
      <Button type="primary" ghost className="Iframe-actions" onClick={() => this.setState({expanded: !this.state.expanded})}>{this.state.expanded ? <ShrinkOutlined /> : <ExpandAltOutlined />}</Button>
      <AceEditor
          defaultValue={this.props.value}
          mode={this.props.mode}
          theme="chrome"
          onChange={this.props.onChange}
          debounceChangePeriod={300}
          name={this.props.id}
          editorProps={{ $blockScrolling: true }}
          fontSize="14px"
          height={this.state.expanded ? '100%' : this.props.height}
          width={this.state.expanded ? '100%' : this.props.width}
          className="ace-input"
          wrapEnabled={true}
          setOptions={{
            // enableBasicAutocompletion: true,
            // enableLiveAutocompletion: true,
            // enableSnippets: true
          }}
        />
      </div>
  }
}

export default AceInput