import React, { useState } from 'react'
import { 
  Button,
  Form,
  Tooltip,
  Select,
  Row,
  Col,
  Drawer,
} from 'antd'
import t from '../../utils/translate'
import { BellFilled } from '@ant-design/icons'
import Nunjucks from 'nunjucks'
import AceInput from './_aceInput'
import ImageInput from './_imageInput'

const WidgetForm = ({loading, organizationId, addMessage, macros, channelTitle, language, template, notification, visible, fromTemplate, setVisible, submitForm}) => {
  const [form] = Form.useForm();
  const [contentDecorated, setContentDecorated] = useState('');

  // console.log('form', form);

  const initialValues = template ? Object.assign({}, template) : {
    iconURL: 'https://notifuse.com/images/item-photo.png',
    content: 'Welcome <b>{{ user.firstName }}</b>! 😀',
    testData: JSON.stringify(notification.testData, null, 2),
  }

  if (template) {
    initialValues.testData = JSON.stringify(notification.testData, null, 2)
  }

  // clone from template
  if (fromTemplate) {
    initialValues.iconURL = fromTemplate.iconURL
    initialValues.content = fromTemplate.content
    initialValues.testData = JSON.stringify(notification.testData, null, 2)
  }

  const decorateContent = (content, data, macroId) => {
    if (!content) {
      return
    }

    let parsedData = {}
    try { 
      parsedData = JSON.parse(data)
    }
    catch(e) {}

    let macroContent = ''

    if (macroId) {
      macros.forEach(x => {
        if (x.id === macroId) {
          macroContent = x.content
        }
      })
    }

    // console.log('content', content);
    // console.log('parsedData', parsedData);

    Nunjucks.renderString(macroContent+' '+content, parsedData, (error, result) => {
      if(error) {
        setContentDecorated(t('templating_syntax_invalid', "Templating syntax is not valid..."))
        return
      }
      // trim result to remove linebreaks introduced by macros sets
      setContentDecorated(result.trim())
    })
  }

  const title = ': '+notification.name+' - '+channelTitle+' - '+language

  return <Drawer
    title={(template) ? t('edit_template', "Edit template")+title : t('create_a_template', "Create a template")+title}
    visible={visible}
    closable={true}
    maskClosable={false}
    width={1272}
    onClose={() => { setVisible(false) }}
    bodyStyle={{ paddingBottom: 80 }}
    footer={
      <div style={{textAlign: 'right'}}>
        <Button loading={loading} onClick={() => setVisible(false)} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
        <Button loading={loading} onClick={() => {
          form
            .validateFields()
            .then((values) => {
              submitForm(values, () => {
                setVisible(false)
              })
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }} type="primary">{t('confirm', "Confirm")}</Button>
      </div>
    }
  >
    <Form form={form} layout="vertical" name="widget_form" initialValues={initialValues}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={t('icon_URL', "Icon URL (52x52px)")}
            name="iconURL"
            rules={[{ required: false, type: 'url' }]}
          >
            <ImageInput organizationId={organizationId} addMessage={addMessage} />
          </Form.Item>

          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', right: '0', width: '150px', zIndex: 1, top: '-3px'}}>
              <Tooltip title={t('macros_page', "Macros page")}>
                <div>
                <Form.Item noStyle name="macroId">
                  <Select style={{width: '100%'}} dropdownMatchSelectWidth={false} allowClear={true} size="small" options={macros.map(x => { return {label: x.name, value: x.id}})} />
                </Form.Item>
                </div>
              </Tooltip>
            </div>
             
            <Form.Item 
              label={t('template_html_content', "Template HTML content")}
              name="content"
              rules={[{ required: true, type: 'string' }]}
            >
              <AceInput id="widgetContent" width="600px" height="300px" mode="nunjucks" />
            </Form.Item>
          </div>

          <Form.Item 
            label={t('notification_test_data', "Notification test data")}
            name="testData"
            validateFirst={true}
            rules={[
              { validator:(xxx, value) => {
                // check if data is valid json
                try { 
                  if (JSON.parse(value)) {}
                  return Promise.resolve()
                }
                catch(e) {
                  return Promise.reject(t('test_data_is_not_valid_json', "Your test variables is not a valid JSON object!")) 
                }
              }},
              { required: false, type: 'object', transform: (value) => {
                try { 
                  const parsed = JSON.parse(value)
                  return parsed
                }
                catch(e) {
                  return value
                }
              } },
            ]}
          >
            <AceInput id="testData" width="600px" height="150px" mode="json" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <p>{t('preview', "Preview")}</p>


          <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.content !== curValues.content || prevValues.iconURL !== curValues.iconURL || prevValues.testData !== curValues.testData || prevValues.macroId !== curValues.macroId}>
          {({ getFieldValue }) => {
            decorateContent(getFieldValue('content'), getFieldValue('testData'), getFieldValue('macroId'))

          return <div style={{backgroundColor: '#F6F9FC', height: '400px'}}>
            <div className="bar">
              <div className="right">
                <div className="bell">
                  <span className="badge">
                    <sup className="badge-count">1</sup>
                    <BellFilled />
                  </span>
                </div>
              </div>
            </div>
            <div className="widget-container">
              <div className="widget">
                <div className="widget-header">
                  <span className="widget-header-right link">Mark all as read</span>
                  <span className="widget-header-title">Notifications</span>
                </div>

                <div className="item">
                  {getFieldValue('iconURL') && <div className="cover-container">
                    <div className="cover" style={{backgroundImage: 'url("'+getFieldValue('iconURL')+'")'}}></div>
                  </div>}
                  <div span={20} className="item-content">
                    <div className="unread"></div>
                    <div className="desc" dangerouslySetInnerHTML={{__html: contentDecorated}} />
                    <div className="time">4h</div>
                  </div>
                </div>

                <div className="widget-footer">
                  <span className="widget-footer-right link">Settings</span>
                </div>
              </div>
            </div>
          </div>
          }}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </Drawer>
}

export default WidgetForm
