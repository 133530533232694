import React, { useState } from 'react'
import { 
  Modal,
  Form,
  Input,
  Button,
} from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'

const TheButton = ({btnType, btnSize, btnContent, app, organizationLayout, projectLayout}) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const onClicked = () => {
    setModalVisible(true)
  }
  
  return <React.Fragment>
    <Button type={btnType} size={btnSize || 'small'} ghost onClick={onClicked}>{projectLayout.project.postmarkSettings ? 'Change API key' : 'Connect'}</Button>

    <Modal
      visible={modalVisible}
      title={t('connect_postmark', "Connect Postmark")}
      okText={t('confirm', "Confirm")}
      cancelText={t('cancel', "Cancel")}
      onCancel={() => { setModalVisible(false) }}
      onOk={() => {
        return new Promise((resolve, reject) => {
          form.validateFields().then(values => {

            app.ajaxRequest({
              method: 'post',
              url: '/channels.connect',
              data: {
                projectId: projectLayout.project.id,
                channel: 'postmark',
                postmark: values
              }
            }, (errorMessage, response) => {

              if (errorMessage) {
                app.addMessage('error', errorMessage)
                return reject()
              }

              app.addMessage('success', t('channel_connected', "Your channel has successfully been connected!"))

              if (window.cmAgent) {
                window.cmAgent.event({
                  label: 'connectChannel',
                  props: {
                    orgId: projectLayout.project.organizationId,
                    projectId: projectLayout.project.id,
                    channel: 'postmark'
                  }
                })
                window.cmAgent.dispatch()
              }

              let projects = _.filter(organizationLayout.projects, (p) => p.id !== response.data.project.id)
              projects.push(response.data.project)
              organizationLayout.setProjects(projects)

              form.resetFields();
              setModalVisible(false)
              return resolve()
              // organizationLayout.fetchProjects()
            })
          })
          .catch(info => { console.log('Validate Failed:', info); });
        })
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="connect_postmark"
      >
        <Form.Item 
          name="apiKey"
          label="API key" 
          rules={[{ required: true, type: 'string' }]}
        >
          <Input size="large" placeholder="i.e: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" />
        </Form.Item>
      </Form>
    </Modal>
  </React.Fragment>
}

export default TheButton