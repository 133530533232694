import React, { Component } from 'react'

import t from '../../utils/translate'
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

class UsersRemoveButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ajaxLoading: false
    };

    this.showConfirm = this.showConfirm.bind(this);
    this.submit = this.submit.bind(this);
  }

  showConfirm() {
    Modal.confirm({
      title: t('remove_admin', "Remove admin"),
      content: t('remove_admin_content', "Do you really want to remove this admin from your organization?"),
      onOk: this.submit,
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      onCancel() {},
    });
  }

  submit() {

    this.setState({ajaxLoading: true});

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/organizations.removeAdmin',
      data: {
        organizationId: this.props.organization.id,
        userId: this.props.user.id
      }
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addNotification('error', 'Invitation error', errorMessage);
        this.setState({ajaxLoading: false});
        return;
      }

      this.setState({ajaxLoading: false});
      
      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'removeAdmin',
          props: {
            orgId: this.props.organization.id,
          }
        })
        window.cmAgent.dispatch()
      }
    });
  }

  render() {
    return (
      <Button
        loading={this.state.ajaxLoading}
        size={this.props.size}
        type={this.props.type || 'default'}
        style={this.props.style || {}}
        onClick={this.showConfirm}>
          <DeleteOutlined />
      </Button>
    );
  }
}

export default UsersRemoveButton