import React, { Component } from 'react'

import { ArrowRightOutlined } from '@ant-design/icons';
import { Input, Button, Select, Form, Divider } from 'antd';
import t from '../../utils/translate'
import _ from 'lodash'
import Industries from '../../utils/industries'
import Languages from '../../utils/languages'
import Moment from 'moment-timezone'

class CreateProject extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.onFinish = this.onFinish.bind(this)
    this.onFinishFailed = this.onFinishFailed.bind(this)
  }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Create project (org: '+this.props.organizationLayout.organization.id+')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.organizationLayout.organization.id,
        }
      })
      window.cmAgent.dispatch()
    }
  }

  onFinishFailed(error) {
    console.error('reset password form error', error)
    return
  }

  onFinish(values) {

    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    values.organizationId = this.props.organizationLayout.organization.id

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/projects.create',
      data: values
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      let projects = this.props.organizationLayout.projects
      projects.push(response.data.project)
      this.props.organizationLayout.setProjects(projects)

      this.props.app.addMessage('success', t('project_created', "Your new project has successfully been created!"))

      
      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'createProject',
          props: {
            orgId: this.props.organizationLayout.organization.id,
            projectId: response.data.project.id,
          }
        })
        window.cmAgent.dispatch()
      }

      this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+response.data.project.id+'/setup')
    })
  }

  formRef = React.createRef()

  render() {

    // console.log(this.props);

    const maxIdLength = 15

    return (
      <div className="layout-content">
        <h1 className="margin-b-l">{t('create_a_new_project', "Create a new project")}</h1>

        <Form
          ref={this.formRef}
          layout="horizontal"
          labelCol={{sm:8}}
          wrapperCol={{sm: 12}}
          name="create_org_form"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          size="large"
          initialValues={{
            websiteUrl: 'https://',
            privacyPolicyUrl: 'https://',
            dataProtectionOfficerId: _.toArray(this.props.organizationLayout.organization.admins)[0].id,
            languages: [this.props.app.state.admin.language],
            defaultNotificationLanguage: this.props.app.state.admin.language,
            defaultNotificationTimezone: this.props.app.state.admin.timezone,
          }}
        >
          <Divider plain>{t('general_settings', "General settings")}</Divider>

          <Form.Item 
            name="name"
            label={t('project_name', "Project name")}
            rules={[{ required: true, type: 'string' }]}
          >
            <Input placeholder="i.e: My app / website" onChange={(e) => {
              const name = e.target.value
              if (name) {
                let newId = _.truncate(_.camelCase(name).toLowerCase(), {length: maxIdLength, omission: ''})
                if (newId !== '') {
                  this.formRef.current.setFieldsValue({'id': newId})     
                }                 
              }
            }} />
          </Form.Item>

          <Form.Item 
            name="id"
            label={t('project_id', "Project ID")} 
            rules={[{ required: true, type: 'string', max: maxIdLength, pattern: /^[a-z0-9]+$/, message: t('only_x_alphanumeric_characters', "Only {x} alphanumeric characters allowed!", {x: maxIdLength}) }]}
            shouldUpdate
          >
            <Input addonBefore={this.props.organizationLayout.organization.id+'_'} placeholder="i.e: myapp" />
          </Form.Item>

          <Form.Item 
            name="languages"
            label={t('notification_languages', "Notification languages")} 
            rules={[{ required: true, type: 'array', min: 1}]}
          >
            <Select 
              mode="multiple"
              placeholder={t('select_a_value', "Select a value")}
              allowClear={false}
              optionFilterProp="label"
              onChange={(values) => {
                // remove default language if not avilable anymore
                if (values.indexOf(this.formRef.current.getFieldValue('defaultNotificationLanguage')) === -1) {
                  this.formRef.current.setFieldsValue({'defaultNotificationLanguage': values[0]})
                }
              }}
              options={Languages.map((language) => { return {label: language.alpha2+' - '+language.name, value: language.alpha2}})}
            />
          </Form.Item>

          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.languages.length !== currentValues.languages.length}>
          {({ getFieldValue }) => <Form.Item 
            name="defaultNotificationLanguage"
            label={t('default_notification_language', "Default notification language")} 
            rules={[{ required: true, type: 'string'}]}
          >
            <Select 
              placeholder={t('select_a_value', "Select a value")}
              allowClear={false}
              optionFilterProp="label"
              options={Languages.filter(x => getFieldValue('languages').indexOf(x.alpha2) !== -1).map((language) => { return {label: language.alpha2+' - '+language.name, value: language.alpha2}})}
            />
          </Form.Item>}
          </Form.Item>

          <Form.Item 
            name="defaultNotificationTimezone"
            label={t('default_notification_timezone', "Default notification timezone")} 
            rules={[{ required: true, type: 'string'}]}
          >
            <Select 
              placeholder={t('select_a_value', "Select a value")}
              allowClear={false}
              showSearch={true}
              filterOption={(searchText, option) => {
                return searchText !== '' && option.label.toLowerCase().includes(searchText.toLowerCase())
              }}
              options={Moment.tz.names().map((timezone) => { return {label: timezone, value: timezone}})}
            />
          </Form.Item>

          <Divider plain>{t('gdpr_compliance', "GDPR compliance")}</Divider>

          <Form.Item 
            name="websiteUrl"
            label={t('website_url', "Website URL")} 
            rules={[{ required: true, type: 'url'}]}
          >
            <Input />
          </Form.Item>

          <Form.Item 
            name="privacyPolicyUrl"
            label={t('privacy_policy_url', "URL of your Privacy Policy")} 
            rules={[{ required: true, type: 'url'}]}
          >
            <Input />
          </Form.Item>

          <Form.Item 
            name="dataProtectionOfficerId"
            label={t('data_protection_officer', "Data Protection Officer")} 
            rules={[{ required: true, type: 'string'}]}
          >
            <Select placeholder={t('select_a_value', "Select a value")}>
              {_.toArray(this.props.organizationLayout.organization.admins).map((admin) => <Select.Option key={admin.id} value={admin.id}>{admin.firstName+' '+admin.lastName}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item 
            name="industry"
            label={t('industry', "Industry")} 
            rules={[{ required: true, type: 'string'}]}
          >
            <Select>
              {Industries.map((i) => <Select.Option key={i.id}>{t(i.id, i.name)}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{sm: {span: 12, offset: 8}}} className="text-right">
            <Button type="primary" loading={this.state.loading} htmlType="submit">{t('create', "Create")} <ArrowRightOutlined /></Button>
          </Form.Item>
        
        </Form>
      </div>
    );
  }
}

export default CreateProject