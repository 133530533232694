import React, { Component } from 'react'
 
import t from '../../utils/translate'
import { Spin, Alert, Button } from 'antd'
import LoginForm from './_loginForm'
import Config from '../../config'
import Axios from 'axios'
import QS from 'qs'
import Splash from './_splash'

class AdminInvitation extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      loading: true,
      invitation: undefined,
      isTokenValid: undefined,
      ajaxLoading: false
    }

    this.consumeInvitation = this.consumeInvitation.bind(this)
  }

  componentDidMount() {
    
    // console.log('props', this.props)
    const params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    Axios({
      method: 'get',
      url: Config().api_endpoint+'/organizations.checkInvitation',
      headers: {Authorization: 'Bearer '+params.token},
    }).then(response => {
      this.setState({
        loading: false,
        isTokenValid: (response.data.invitation.consumedAt) ? false : true,
        invitation: response.data.invitation
      })
    })
    .catch(error => {

      let message = error.message

      if (error.response && error.response.status === 400) {
        switch(error.response.data.message) {
          default: 
            message = error.response.data.message
          break
        }
      }

      this.props.app.addMessage('error', 'Error: '+message)
      this.setState({
        loading: false,
        isTokenValid: false
      })
    })

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Invitation',
        page: '/invitation' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }
  }

  consumeInvitation() {
    if (this.state.ajaxLoading === true) {
      return
    }

    this.setState({ajaxLoading: true})

    const params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/organizations.consumeInvitation',
      data: {
        token: params.token,
      }
    }, (errorMessage, response) => {

      if (errorMessage) {
        if (errorMessage === 'user.alreadyExists') {
          this.props.app.addMessage('error', 'You are already member of this organization.')
        }
        else {
          this.props.app.addMessage('error', 'Error: '+errorMessage)
        }

        this.setState({ajaxLoading: false})
        return
      }

      // console.log('dash', response.data)

      this.props.app.addMessage('success', 'Your invitation has been accepted!')

      if (window.cmAgent) {
        window.cmAgent.addUserToCompany(this.state.invitation.organizationId, {role: 'admin'})

        window.cmAgent.event({
          label: 'consumeInvitation',
          props: {
            userId: this.props.app.state.admin.id,
            email: this.props.app.state.admin.primaryEmail,
          }
        })
        window.cmAgent.dispatch()
      }

      // refresh token with new claim
      this.props.app.state.firebaseUser.getIdToken(true).then(() => {
        this.props.history.push('/organizations/'+this.state.invitation.organizationId)
      })
    })
  }

  render() {
    // console.log('props', this.props)

    return (
      <Splash h1={t('welcome', "Welcome")}>
        <Spin spinning={this.state.ajaxLoading}>
          {this.state.loading &&
            <div className="margin-a-l">
              <Spin spinning={true} />
            </div>
          }

          {this.state.isTokenValid === false &&
            <Alert type="error" message="Your invitation token has already been consumed or is not valid!" />
          }
          
          {this.state.isTokenValid === true && <div>
            <Alert type="info" className="margin-b-m" message={t('x_invited_you_to_join_its_organization', "{x} invited you to join it's organization!", {x: this.state.invitation.organizationName})} />
 
              {(this.props.app.state.admin) ?
                <div>
                  <Button size="large" type="primary" onClick={this.consumeInvitation}>{t('accept_invitation_and_join_x', "Accept invitation and join {organization}", {organization: this.state.invitation.organizationName})}</Button>

                  <div className="margin-t-m">
                    {t('or', "or")} <span className="link" onClick={this.props.app.signOut}>{t('sign_out', "sign out")}</span>
                  </div>
                </div>
              :
                <LoginForm addMessage={this.props.app.addMessage} signIn={this.props.signIn} />
              }
          </div>}
        </Spin>
      </Splash>
    )
  }
}

// AdminInvitation.contextTypes = {
//   router: React.PropTypes.object.isRequired
// }

export default AdminInvitation