import React, { Component } from 'react'

// import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Tooltip, Input, Select } from 'antd';
import t from '../../utils/translate'
import Moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QS from 'qs'
import _ from 'lodash'
// import UpsertUserButton from './_upsertUserButton'

const defaultParams = {
  limit: 15,
  skip: 0,
  sortKey: 'createdAt',
  sortOrder: 'desc',
  searchKey: 'lastName'
}

class UsersList extends Component {
  _isMounted = false;

  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      users: [],
      usersCount: 0,
    }

    this.fetchUsers = this.fetchUsers.bind(this)
    this.handleTableChange = this.handleTableChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
    this.onSearch = this.onSearch.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    this.props.organizationLayout.toggleMenu()
    this.fetchUsers()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Users (project: '+this.props.projectLayout.project.id+')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  componentDidUpdate(prevProps){
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {

      const params = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})
      const prevParams = QS.parse(prevProps.location.search, {ignoreQueryPrefix: true})

      // dont refresh on toggle user
      if (params.showUser !== prevParams.showUser) {
        return
      }

      this.fetchUsers()
    }
  }
  handleTableChange(pagination, filters, sorter) {
    // console.log('filters', filters);
    // console.log('pagination', pagination);
    // console.log('sorter', sorter);
    const params = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})

    const newParams = _.assign({
        limit: params.limit || defaultParams.limit,
        skip: params.skip || defaultParams.skip,
        sortKey: params.sortKey || defaultParams.sortKey,
        sortOrder: params.sortOrder || defaultParams.sortOrder,
        searchKey: params.searchKey || defaultParams.searchKey,
        searchValue: params.searchValue || defaultParams.searchValue,
    }, {
      limit: pagination.pageSize,
      skip: (pagination.current*pagination.pageSize)-pagination.pageSize,
      sortKey: sorter.columnKey,
      sortOrder: sorter.order,
    })

    this.props.history.push(window.location.pathname+'?'+QS.stringify(newParams, { indices: false }));
  }

  onSearch(value) {
    this.updateParams([{k: 'searchValue', v: value}])
  }

  updateParams(updatedParams) {
    let params = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})
    updatedParams.forEach(up => {
      params[up.k] = up.v
    })
    this.props.history.push(window.location.pathname+'?'+QS.stringify(params))
  }

  fetchUsers() {

    this.setState({loading: true})

    const params = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})

    let query = {
      projectId: this.props.projectLayout.project.id,
      limit: params.limit || defaultParams.limit,
      skip: params.skip || defaultParams.skip,
      sortKey: params.sortKey || defaultParams.sortKey,
      sortOrder: params.sortOrder || defaultParams.sortOrder,
      searchKey: params.searchKey || defaultParams.searchKey,
      searchValue: params.searchValue || '',
    }

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/users.list',
      params: query
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      // console.log('res', response.data);

      if (this._isMounted) {
        this.setState({
          users: response.data.users,
          usersCount: response.data.count,
          loading: false
        })
      }
    })
  }

  render() {

    const params = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})
    const skip = params.skip || defaultParams.skip
    const limit = params.limit || defaultParams.limit

    const page = (skip === 0) ? 1 : (skip/limit)+1

    const SearchKey = <Select defaultValue={defaultParams.searchKey} onChange={(value) => this.updateParams([{k: 'searchKey', v: value}])} style={{ width: 130 }} dropdownMatchSelectWidth={false}>
      <Select.Option value="id">ID</Select.Option>
      <Select.Option value="firstName">{t('first_name', "First name")}</Select.Option>
      <Select.Option value="lastName">{t('last_name', "Last name")}</Select.Option>
      <Select.Option value="email">Email</Select.Option>
      <Select.Option value="telephone">{t('telephone', "Telephone")}</Select.Option>
      {/*<Select.Option value="twitterUsername">{t('twitter_username', "Twitter username")}</Select.Option>
      <Select.Option value="facebookUsername">{t('facebook_username', "Facebook username")}</Select.Option>
      <Select.Option value="websiteURL">{t('website_url', "Website URL")}</Select.Option>
      <Select.Option value="linkedInURL">{t('linkedin_url', "LinkedIn URL")}</Select.Option>*/}
    </Select>

    return <div>
      <h1>
        <div className="actions">
          <Input.Search style={{width: '400px'}} allowClear placeholder={t('search', "Search")} defaultValue={params.searchValue} onSearch={this.onSearch} addonBefore={SearchKey}  />
        </div>
        {t('user', "Users")}
      </h1>

      <Table
        dataSource={this.state.users}
        rowKey="id"
        className="edge-table"
        onChange={this.handleTableChange}
        loading={this.state.loading}
        pagination={{
          pageSize: Number(params.limit || defaultParams.limit),
          total: this.state.usersCount,
          current: page
        }}
        columns={[
          {
            key: 'id',
            title: 'ID',
            render: user => {
              if (user.id.length > 15) {
                return <Tooltip title={user.id}><span className="size-12">{_.truncate(user.id, {length: 16, omission: '...'})}</span></Tooltip>
              }
              return <span className="size-12">{user.id}</span>
            }
          },
          {
            key: 'name',
            title: t('name', "Name"),
            render: user => <div>
              {user.photoURL ? <img className="user-picture" src={user.photoURL} alt="" /> : <span className="user-picture-icon"><FontAwesomeIcon icon="user-circle" /></span>}
              <b className="padding-l-s">{(user.firstName || '')+' '+(user.lastName || '')}</b>
            </div>
          },
          {
            key: 'email',
            title: 'Email',
            render: user => <div>
              {user.email && <div className="padding-b-xs"><a href={'mailto:'+user.email}>{user.email}</a></div>}
            </div>
          },
          {
            key: 'telephone',
            title: t('telephone', "Telephone"),
            render: user => <div>
              {user.telephone && <div className="padding-b-xs"><a href={'mailto:'+user.telephone}>{user.telephone}</a></div>}
            </div>
          },
          {
            key: 'language',
            title: t('language', "Language"),
            render: user => user.language
          },
          {
            key: 'timezone',
            title: t('timezone', "Time zone"),
            render: user => user.timezone
          },
          // {
          //   key: 'gender',
          //   title: '',
          //   width: '6px',
          //   render: user => <div>
          //     {user.gender && <FontAwesomeIcon icon={user.gender} style={{color: user.gender === 'male' ? 'rgba(178,235,242 ,1)' : 'rgba(248,187,208 ,1)'}} />}
          //   </div>
          // },
          {
            key: 'createdAt',
            title: t('created_at', "Created at"),
            render: user => <span className="size-12">{Moment(user.createdAt).fromNow()}</span>
          }, 
          // {
          //   key: 'actions',
          //   title: <span className="pull-right"><Button type="ghost" size="small" onClick={this.fetchUsers} disabled={this.state.loading}><FontAwesomeIcon icon="sync-alt" spin={this.state.loading} /></Button></span>,
          //   className: 'text-right',
          //   render: user => <Button.Group>
          //     <Button size="small" type="ghost" onClick={this.props.projectLayout.viewUser.bind(null, user.id)}><FontAwesomeIcon icon="search" style={{color: 'rgba(0,0,0, 0.5)'}} /></Button>
          //   </Button.Group>
          // },
        ]}
      />
    </div>
  }
}

export default UsersList