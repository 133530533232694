import React, { Component } from 'react'

import t from '../../utils/translate'
import _ from 'lodash'
import { KeyOutlined } from '@ant-design/icons';
import { Table, Button, Tag, Avatar, Modal, Input } from 'antd'
import InviteButton from './_inviteButton'
import TransferOwnershipButton from './_transferOwnershipButton'
import RemoveAdminButton from './_removeButton'
import CancelInvitationButton from './_cancelInvitationButton'
import CreateServiceAccountButton from './_createServiceAccountButton'
import Firebase from 'firebase/app'
import 'firebase/firestore'

class AdminsList extends Component {
  _isMounted = false;

  constructor(props) {
    super(props)
    
    this.state = {
      loading: true,
      invitations: [],
    }

    this.fetchInvitations = this.fetchInvitations.bind(this)
    this.getServiceAccountToken = this.getServiceAccountToken.bind(this)
    this.unsubscribe = undefined
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchInvitations()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'List admins',
        page: this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  fetchInvitations() {

    this.setState({loading: true})

    // const params = QS.parse(this.props.location.search, {ignoreQueryPrefix: true})

    const Firestore = Firebase.firestore()
    

    this.unsubscribe = Firestore.collection("invitations")
                                .where("organizationId", "==", this.props.organizationLayout.organization.id)
                                .onSnapshot((querySnapshot) => {
        const invitations = []

        querySnapshot.forEach((doc) => {
          invitations.push(doc.data())
        })

        if (this._isMounted) {
          this.setState({
            invitations: invitations,
            loading: false,
          })
        }
    })
  }

  getServiceAccountToken(serviceAccountId) {

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/organizations.getServiceAccountToken',
      params: {
        organizationId: this.props.organizationLayout.organization.id,
        serviceAccountId: serviceAccountId
      }
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        // this.setState({loading: false})
        return
      }

      // console.log('res', response.data);

      Modal.info({
        title: 'Service Account Token',
        centered: true,
        content: (
          <div><Input.TextArea value={response.data.token} autoSize /></div>
        ),
        width: 600,
        onOk() {},
      });
    })

  }

  render() {
    let adminList = []
    let imAdmin = false

    _.forEach(this.props.organizationLayout.organization.admins, (admin) => {
      adminList.push(admin)

      if (admin.isOwner === true && admin.id === this.props.app.state.admin.id) {
        imAdmin = true
      }
    })

    this.state.invitations.forEach((inv) => {
      if (!inv.consumedAt) {
        adminList.push(inv)
      }
    })

    // console.log('props', this.props)
    return (
      <div className="layout-content">
        <h1>
          <span className="pull-right">
            <CreateServiceAccountButton
              type="ghost"
              onComplete={(updatedOrganization) => {
                this.props.app.addMessage('success', t('service_account_created', 'The service account has been created.'))
              }}
              organization={this.props.organizationLayout.organization}
              app={this.props.app} />

            <InviteButton
              type="primary"
              onComplete={(updatedOrganization) => {
                this.props.app.addMessage('success', t('admin_invitation_sent', 'An invitation has been sent to your colleague.'))
              }}
              organization={this.props.organizationLayout.organization}
              app={this.props.app} />

          </span>
          {t('admins', "Admins")} 
        </h1>

        <div className="block">
          <Table 
            bordered={false}
            rowKey="id"
            dataSource={adminList}
            pagination={false}
            columns={[{
              title: t('name', "Name"),
              key: 'name',
              render: (record) => {
                if (record.isServiceAccount) {
                  return <div>
                    <Avatar className="margin-r-m" style={{ color: '#ffffff', backgroundColor: '#3F51B5' }}>API</Avatar>
                    {record.firstName}
                    <Tag color="cyan" className="no-pointer margin-l-s">Service Account</Tag>
                </div>
                }

                let role = <Tag color="blue" className="no-pointer margin-l-s">{t('admin', "Admin")}</Tag>
                if (record.isOwner) {
                  role = <Tag color="purple" className="no-pointer margin-l-s">{t('owner', "Owner")}</Tag>
                }
                if (record.isSupport) {
                  role = <Tag color="green" className="no-pointer margin-l-s">Support</Tag>
                }

                return <div>
                  {(record.firstName) ?
                    <div>
                      <img src={record.picture} alt="" className="margin-r-m" style={{height: '32px', verticalAlign: 'middle', borderRadius: '50%'}} />
                      {record.firstName+' '+record.lastName}
                      {role}
                    </div>
                    : 
                    <span>{record.email} <Tag color="magenta">{t('invitation_sent', "invitation sent")}</Tag></span>
                  }
                </div>
              }
            }, {
              title: '',
              key: 'actions',
              render: (record) => <div className="text-right">
                {(imAdmin && record.id !== this.props.app.state.admin.id) &&
                  <Button.Group>
                    {record.isServiceAccount === true && <Button type="default" size="small" onClick={this.getServiceAccountToken.bind(null, record.id)} icon={<KeyOutlined />} />}

                    {record.isOwner === false && record.isSupport === false && record.isServiceAccount === false  && <TransferOwnershipButton
                      admin={record}
                      type="default"
                      size="small"
                      onComplete={(updatedOrganization) => {
                        this.props.app.addMessage('success', t('ownership_transfered', 'The ownership of this organization has been transfered.'))
                      }}
                      organization={this.props.organizationLayout.organization}
                      app={this.props.app} />}

                    {(!record.firstName) ?
                      <CancelInvitationButton
                        invitation={record}
                        type="default"
                        size="small"
                        onComplete={(updatedOrganization) => {
                          this.props.app.addMessage('success', t('invitation_cancelled', 'The invitation has been cancelled.'))
                        }}
                        organization={this.props.organizationLayout.organization}
                        app={this.props.app} />
                    :
                      (!record.isSupport ? <RemoveAdminButton
                        user={record}
                        type="default"
                        size="small"
                        onComplete={(updatedOrganization) => {
                          this.props.app.addMessage('success', t('admin_removed', 'The admin has been removed of your organization.'))
                        }}
                        organization={this.props.organizationLayout.organization}
                        app={this.props.app} />
                      : null)
                    }
                  </Button.Group>}
              </div>
            }
          ]}
          />
        </div>
      </div>
    );
  }
}

export default AdminsList