import React from 'react'
import { 
  // Row,
  // Col,
  Table,
  Button,
  Modal,
} from 'antd'
import _ from 'lodash'
import t from '../../utils/translate'
import TemplateButton from '../notifications/_templateButton'
import NotificationForm from '../notifications/_form'
import TestForm from '../notifications/_testForm'
import { 
  BellOutlined,
  MailOutlined,
  MobileOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';

const NotificationItem = ({app, projectLayout, notification}) => {

  // console.log('NotificationItem projectLayout', projectLayout);
  // const [loading, setLoading] = useState(false);

  // console.log('form', form);

  const deleteConfirm = () => {
    Modal.confirm({
      title: 'Delete this notification?',
      content: 'Do you really want to delete this notification?',
      icon: <ExclamationCircleOutlined />,
      okText: t('confirm', "Confirm"),
      cancelText: t('cancel', "Cancel"),
      okType: 'danger',
      onOk: () => {
        return new Promise((resolve, reject) => {

          app.ajaxRequest({
            method: 'post',
            url: '/notifications.delete',
            data: {
              projectId: projectLayout.project.id,
              id: notification.id
            }
          }, (error, data) => {

            if (error) {
              app.addMessage('error', error)
              return reject(error)
            }

            app.addMessage('success', 'Your notification has been successfully deleted!')

            projectLayout.fetchNotifications()
            return resolve()
          })
        })
      },
    })
  }

  // const generateTable = (channel, title) => {
  //   return <div className="padding-a-m">
  //     <div className="block-notif-subtitle">
  //       {channel === 'widget' && <BellOutlined className="padding-r-s" />}
  //       {channel === 'email' && <MailOutlined className="padding-r-s" />}
  //       {channel === 'sms' && <MobileOutlined className="padding-r-s" />}
  //       {title}
  //     </div>

  //     <Table rowKey="key" size="small" pagination={false} dataSource={projectLayout.project.languages.map(lang => { return {key: lang, value: lang}})} showHeader={false} columns={[
  //       {
  //         title: 'lang',
  //         key: 'lang',
  //         render: lang => lang.value.toUpperCase()
  //       },
  //       {
  //         title: 'actions',
  //         key: 'actions',
  //         // align: 'right',
  //         render: lang => {
  //           const tpl = _.find(notification.templates, x => x.channel === channel && x.language === lang.value)

  //           return <TemplateButton app={app} projectLayout={projectLayout} template={tpl} notification={notification} channel={channel} channelTitle={title} language={lang.value} btnSize="small" />
  //         }
  //       },
  //     ]} />
  //   </div>
  // }

  // const blocks = []

  // let channels = 0
  // if (notification.withWidget) channels++
  // if (notification.withEmail) channels++
  // if (notification.withSMS) channels++

  // const titleSpan = channels > 0 ? (24-(3*channels)) : 24

  // if (notification.withWidget) blocks.push(generateTable('widget', 'Widget'))
  // if (notification.withEmail) blocks.push(generateTable('email', 'Email'))
  // if (notification.withSMS) blocks.push(generateTable('sms', 'SMS'))

  // const span = Math.floor(24/blocks.length)

  const columns = [{
    title: '',
    key: 'lang',
    render: lang => lang.value.toUpperCase()
  }]

  if (notification.withWidget) {
    columns.push({
      title: <span><BellOutlined className="padding-r-s" /> Widget</span>,
      key: 'widget',
      // align: 'right',
      width: 200,
      render: lang => {
        const tpl = _.find(notification.templates, x => x.channel === 'widget' && x.language === lang.value)

        return <TemplateButton app={app} projectLayout={projectLayout} template={tpl} notification={notification} channel="widget" channelTitle="Widget" language={lang.value} btnSize="small" />
      }
    })
  }

  if (notification.withEmail) {
    columns.push({
      title: <span><MailOutlined className="padding-r-s" /> Email</span>,
      key: 'email',
      // align: 'right',
      width: 200,
      render: lang => {
        const tpl = _.find(notification.templates, x => x.channel === 'email' && x.language === lang.value)

        return <TemplateButton app={app} projectLayout={projectLayout} template={tpl} notification={notification} channel="email" channelTitle="Email" language={lang.value} btnSize="small" />
      }
    })
  }

  if (notification.withSMS) {
    columns.push({
      title: <span><MobileOutlined className="padding-r-s" /> SMS</span>,
      key: 'sms',
      // align: 'right',
      width: 200,
      render: lang => {
        const tpl = _.find(notification.templates, x => x.channel === 'sms' && x.language === lang.value)

        return <TemplateButton app={app} projectLayout={projectLayout} template={tpl} notification={notification} channel="sms" channelTitle="SMS" language={lang.value} btnSize="small" />
      }
    })
  }

  return <div className="block-notif">
    <div className="block-notif-title">
      <span className="actions">
        <span className="color-grey size-12 padding-r-m">ID: {notification.id}</span>
        <Button.Group>
          <Button type="ghost" size="small" onClick={deleteConfirm}><DeleteOutlined /></Button>
          <NotificationForm app={app} btnSize="small" projectLayout={projectLayout} inDrawer={true} notification={notification} />
          <TestForm app={app} projectLayout={projectLayout} notification={notification} btnSize="small" />
        </Button.Group>
      </span>
      {notification.name}
    </div>

    <div className="padding-a-m" style={{backgroundColor: '#fafafc'}}>
      <Table
        className="no-header-bg"
        rowKey="key"
        size="small"
        pagination={false}
        dataSource={projectLayout.project.languages.map(lang => { return {key: lang, value: lang}})}
        showHeader={true}
        columns={columns}
      />
    </div>

    {/*<Row gutter={24}>
    {blocks.map((x, i) => {
      return <Col key={i} span={span}>{x}</Col>
    })}
    </Row>*/}
  </div>
}

export default NotificationItem
