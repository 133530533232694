const Config = () => {

  if (window.location.hostname.indexOf('dev.com') !== -1) {
    return {
      env: 'development',
      cm_agent_host: 'https://localapi.captainmetrics.com',
      api_endpoint: 'https://api.notifusedev.com',
      firebase_config: {
        apiKey: "AIzaSyCptQs3WEl9ptEdpHquOiEMUxrQe-klGzw",
        authDomain: "notifuse2-dev.firebaseapp.com",
        databaseURL: "https://notifuse2-dev.firebaseio.com",
        projectId: "notifuse2-dev",
        storageBucket: "notifuse2-dev.appspot.com",
        messagingSenderId: "722501605338",
        appId: "1:722501605338:web:e15088863eb7b1b93b92e3"
      },
      google_api_key: 'AIzaSyCptQs3WEl9ptEdpHquOiEMUxrQe-klGzw',
      google_client_id: '722501605338-79i72ntpa60nund07m9r23ds1v4q3e5g.apps.googleusercontent.com',
      sentry_dsn: 'https://e4a462bcaec24611a33483cfd0c61c6f@sentry.io/1380697',
      notifuseProjectId: 'demo_demo'
    }
  }
  else {
    return {
      env: 'production',
      cm_agent_host: 'https://api.captainmetrics.com',
      api_endpoint: 'https://api.notifuse.com',
      firebase_config: {
        apiKey: "AIzaSyDZy8E25gGHWzmLYih5e-qC4OQrYiqx5kw",
        authDomain: "notifuse-prod.firebaseapp.com",
        databaseURL: "https://notifuse-prod.firebaseio.com",
        projectId: "notifuse-prod",
        storageBucket: "notifuse-prod.appspot.com",
        messagingSenderId: "1023169453768",
        appId: "1:1023169453768:web:3752c3cd81f0ebc3f14ffd",
        measurementId: "G-VRFHMR2611"
      },
      google_api_key: 'AIzaSyBFOqMDu3UJFyxGW8KlSkwK6PrTPLn4yxY',
      google_client_id: '1023169453768-n3hg4igjbsik7v7lb4kh9j32dlg5rt3n.apps.googleusercontent.com',
      sentry_dsn: 'https://e4a462bcaec24611a33483cfd0c61c6f@sentry.io/1380697',
      notifuseProjectId: 'notifuse_notifuse'
    }
  }
}

export default Config