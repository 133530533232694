import React, { Component } from 'react'

import t from '../../utils/translate'
import Splash from '../app/_splash'

class CheckYourMailbox extends Component {

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Check your mailbox',
        page: '/check-your-mailbox' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }
  }

  render() {
    return <Splash h1={t('welcome', "Welcome!")}>
      <p>{t('check_your_mailbox_info', "Thank you for signing up, please follow the link sent to your mailbox to verify your account.")}</p>
    </Splash>
  }
}

export default CheckYourMailbox