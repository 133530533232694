import React, { Component } from 'react'

import t from '../../utils/translate'
import { Button, Row, Col } from 'antd';
// import _ from 'lodash'
import ChannelsSetup from '../channels/_setup'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

class SetupIntegrations extends Component {

  render() {

    return <div className="padding-a-m">

      <ChannelsSetup app={this.props.app} organizationLayout={this.props.organizationLayout} projectLayout={this.props.projectLayout} />

      <Row className="margin-t-l">
        <Col span={12} className="text-left">
          <Button size="large" onClick={() => this.props.setStep('notification')}><ArrowLeftOutlined /> {t('previous', "Previous")}</Button>
        </Col>
        <Col span={12} className="text-right">
          <Button type="primary" size="large" onClick={() => this.props.setStep('integrations')}>{t('next', "Next")} <ArrowRightOutlined /></Button>
        </Col>
      </Row>
    </div>
  }
}

export default SetupIntegrations