import React, { Component } from 'react'

import t from '../../utils/translate'
import { Button, 
  Spin, 
  Input, 
  Select, 
  Modal, 
  Form,
} from 'antd';

class AdminsInviteButton extends Component {

  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      loading: false
    }

    // this.checkChannelId = this.checkChannelId.bind(this)
    this.onFinish = this.onFinish.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
  }


  toggleModal() {
    this.setState({visible: !this.state.visible})
  }

  onFinish(values) {
    this.setState({loading: true})

    let data = {
      organizationId: this.props.organization.id,
      email: values.email,
      language: values.language
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/organizations.inviteAdmin',
      data: data
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addNotification('error', 'Invitation error', errorMessage)
        this.setState({loading: false})
        return
      }

      this.props.app.addMessage('success', t('colleague_invited', "Your colleague has been invited!"))

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'inviteAdmin',
          props: {
            orgId: this.props.organization.id,
          }
        })
        window.cmAgent.dispatch()
      }

      this.setState({loading: false, visible: false})
      
      // if (window.ga) {
      //   window.ga('send', 'event', {
      //     eventCategory: 'organizations',
      //     eventAction: 'inviteAdmin'
      //   })
      // }
    })
  }

  formRef = React.createRef()

  render() {

    return (
      <span>
        <Button size={this.props.size} type={this.props.type || 'default'} style={this.props.style || {}} onClick={this.toggleModal}>{t('invite_a_colleague', "Invite a colleague")}</Button>

        {this.state.visible && 
          <Modal
            title={t('invite_a_colleague', "Invite a colleague")}
            wrapClassName="vertical-center-modal"
            visible={true}
            okText={t('confirm', "Confirm")}
            cancelText={t('cancel', "Cancel")}
            onCancel={this.toggleModal}
            okButtonProps={{loading: this.state.loading}}
            cancelButtonProps={{loading: this.state.loading}}
            onOk={() => {
              this.formRef.current.validateFields()
                .then(values => {
                  this.formRef.current.resetFields()
                  this.onFinish(values)
                })
                .catch(info => {
                  console.log('Validate Failed:', info)
                })
            }}>

            <Spin tip="Loading..." spinning={this.state.loading}>
              <Form 
                ref={this.formRef} 
                layout="vertical">

                <Form.Item
                  name="email"
                  label={t('email_address', "Email address")}
                  rules={[{ required: true, type: 'email' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="language"
                  label={t('preferred_language', "Preferred language")}
                  rules={[{ required: true, type: 'string' }]}
                >
                  <Select>
                      <Select.Option value="en">English</Select.Option>
                      <Select.Option value="fr">Français</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Spin>
          </Modal>
        }
      </span>
    )
  }
}

export default AdminsInviteButton