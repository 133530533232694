import React, { Component } from 'react'
import { Button, Form, Input, Drawer } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import _ from 'lodash'
import t from '../../utils/translate'
import AceInput from '../notifications/_aceInput'
import Nunjucks from 'nunjucks'

const MacroForm = ({inDrawer, loading, project, macros, macro, drawerVisible, setDrawerVisible, onFinish, onFinishFailed}) => {
  const [form] = Form.useForm();

  const initialValues = macro ? Object.assign({}, macro) : {
    id: 'emails-layout',
    name: t('emails_layout', "Emails layout"),
    content: '',
  }

  // console.log('initialValues', initialValues);

  const formContent = <Form
    form={form}
    layout="horizontal"
    name="create_notif_form"
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    labelCol={{sm: { span: 8 }}}
    wrapperCol={{sm: { span: 14 }}}
    initialValues={initialValues}
  >
    <Form.Item 
      name="name"
      label={t('macro_name', "Macros page name")}
      rules={[{ required: true, message: t('field_required', "This field is required!") }]}
    >
      <Input placeholder="i.e: Emails layout" onChange={(e) => {
        const name = e.target.value
        if (name) {
          let newId = _.truncate(_.camelCase(name).toLowerCase(), {omission: ''})
          if (newId !== '') {
            form.setFieldsValue({'id': newId})     
          }                 
        }
      }} />
    </Form.Item>

    <Form.Item 
      name="id"
      label={t('macro_id', "Macros page ID")} 
      rules={[
        { required: true, type: 'string', pattern: /^[a-z0-9-]+$/, message: t('only_alphanumeric_characters', "Only alphanumeric characters allowed!") },
        { validator:(item, value) => {
          if (macro) {
            return Promise.resolve()
          }

          if (_.find(macros, x => x.id === value)) {
            return Promise.reject(t('this_macros_page_id_already_exists', "This macros page ID already exists."))
          }
          return Promise.resolve()
        }},
      ]}
      shouldUpdate
    >
      <Input disabled={macro ? true : false} placeholder="i.e: emails-layout" />
    </Form.Item>

    <Form.Item 
      name="content"
      labelCol={{sm: { span: 0 }}}
      wrapperCol={{sm: { span: 24 }}}
      validateFirst={true}
      rules={[
        { validator:(xxx, value) => {
          // TODO: check if nunjucks can compile
          try { 
            Nunjucks.renderString(value)
            return Promise.resolve()
          }
          catch(e) {
            return Promise.reject(t('templating_syntax_is_not_valid', "The templating syntax is not valid!")) 
          }
        }},
      ]}
    >
      <AceInput id="content" width="100%" height="550px" mode="nunjucks" />
    </Form.Item>

    {!inDrawer && <Form.Item wrapperCol={{sm: {span: 12, offset: 10}}} className="text-right margin-t-m">
      <Button loading={loading} type="primary" htmlType="submit">{t('create', "Create")} <ArrowRightOutlined /></Button>
    </Form.Item>} 
  </Form>

  if (!inDrawer) {
    return formContent
  }

  return <Drawer
    title={(macro) ? t('edit_macro', "Edit macro") : t('create_a_macros_page', "Create a macros page")}
    visible={drawerVisible}
    closable={true}
    width={960}
    onClose={() => { setDrawerVisible(false) }}
    bodyStyle={{ paddingBottom: 80 }}
    footer={
      <div style={{textAlign: 'right'}}>
        <Button loading={loading} onClick={() => setDrawerVisible(false)} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
        <Button loading={loading} onClick={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }} type="primary">{t('confirm', "Confirm")}</Button>
      </div>
    }
  >
  {formContent}
  </Drawer>
}


class MacroDrawer extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      drawerVisible: false
    }

    this.onFinish = this.onFinish.bind(this)
    this.onFinishFailed = this.onFinishFailed.bind(this)
    this.setDrawerVisible = this.setDrawerVisible.bind(this)
  }

  setDrawerVisible() {
    this.setState({drawerVisible: !this.state.drawerVisible})
  }

  onFinishFailed(error) {
    console.error('form error', error)
    return
  }

  onFinish(values) {

    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    values.projectId = this.props.projectLayout.project.id

    if (values.testData) {
      try { 
        const parsed = JSON.parse(values.testData)
        values.testData = parsed
      }
      catch(e) {
        values.testData = {}
      }
    }

    // update
    if (this.props.macro) {
      values.id = this.props.macro.id
    }

    this.props.app.ajaxRequest({
      method: 'post',
      url: this.props.macro ? '/macros.update' : '/macros.create',
      data: values
    }, (errorMessage, response) => {

      if (errorMessage) {
        if (errorMessage === 'id/exists') errorMessage = t('this_macro_id_already_exists', "This macro ID already exists.")

        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      this.props.app.addMessage('success', t('macro_created', "The macros page has successfully been created!"))

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'createMacro',
          // props: {
            // orgId: response.data.organization.id,
            // projectId: 
          // }
        })
        window.cmAgent.dispatch()
      }

      if (this.props.inDrawer) this.setDrawerVisible(false)
      this.setState({loading: false})

      if (this.props.onComplete) this.props.onComplete()

      // refresh macros
      this.props.projectLayout.fetchNotifications()
    })
  }

  render() {

    // console.log('qsd', this.state);

    // only init the form when needed, so it doesn't cache the fields if the macro testData is updated by templates
    return <span>
      {(!this.props.inDrawer || this.state.drawerVisible) && <MacroForm
        project={this.props.projectLayout.project} 
        inDrawer={this.props.inDrawer} 
        macro={this.props.macro}
        macros={this.props.projectLayout.macros}
        drawerVisible={this.state.drawerVisible}
        setDrawerVisible={this.setDrawerVisible}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        loading={this.state.loading}
      />}
      {this.props.inDrawer && <Button loading={this.state.loading} type={this.props.macro ? 'default': 'primary'} size={this.props.btnSize || 'normal'} onClick={() => this.setDrawerVisible(true)}>{this.props.macro ? t('Edit', "Edit") : t('create_a_macros_page', "Create a macros page")}</Button>}
    </span>
  }
}

export default MacroDrawer
