import React, { Component } from 'react'

import {
  Route,
  Switch,
  // Redirect,
  withRouter
} from 'react-router-dom'

import { Spin, notification, message, ConfigProvider, Button } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import Config from '../../config'
import Axios from 'axios'
import Moment from 'moment-timezone'
import Numeral from 'numeral'
import QS from 'qs'
// import findOnboardingRoute from '../../utils/findOnboardingRoute'
import * as Firebase from "firebase/app";
import 'firebase/auth'
import Cookies from '../../utils/cookies'
import t from '../../utils/translate'
import _ from 'lodash'

import translations from '../../translations'

import Login from './login'
import SignUp from './signUp'
import SignOut from './signOut'
import Invitation from './invitation'
import FirebaseAuthAction from './firebaseAuthAction'
import CheckYourMailbox from '../admins/checkYourMailbox'

// import Onboarding from '../onboarding/index'
import OrganizationsList from '../organizations/list'
import CreateOrganization from '../organizations/create'
import OrganizationLayout from '../organizations/layout'

// import ShopifyRedirectUri from '../integrations/_shopifyRedirectUri'
// import MailchimpRedirectUri from '../integrations/_mailchimpRedirectUri'
// import LazadaRedirectUri from '../integrations/_lazadaRedirectUri'

// import logo from '../../../public/images/logo.png'

require('numeral/locales/fr')

Firebase.initializeApp(Config().firebase_config)

// GoogleMapsLoader.KEY = Config().gmap_key

const navigatorLanguage = ((navigator.language || navigator.userLanguage).indexOf('fr') !== -1) ? 'fr' : 'en'

class App extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      loading: true,
      firebaseUser: undefined,
      admin: undefined,
      adminSettingsVisible: false,

      organizations: [],
    }

    this.signOut = this.signOut.bind(this)
    this.addNotification = this.addNotification.bind(this)
    this.addMessage = this.addMessage.bind(this)
    this.setAppState = this.setAppState.bind(this)
    this.toggleAdminSettings = this.toggleAdminSettings.bind(this)
    this.ajaxRequest = this.ajaxRequest.bind(this)
    this.stopImpersonating = this.stopImpersonating.bind(this)
    this.signIn = this.signIn.bind(this)
  }

  componentDidMount() {

    const params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (params.plan) {
      Cookies.setItem('plan', params.plan, 60*60*24, '/', null, true)
    }

    if (window.CM) {
      // window.cmAgent = new window.CM.Agent({
      //   projectId: 'captainmetrics_captainmetrics',
      //   host: Config().cm_agent_host,
      //   userConsent: Config().env === 'development' ? false : true, // disable hits in dev
      //   // logLevel: 'debug',
      //   // crossDomains: ['test.captainmetrics.com'],
      //   // onLog: this.log.bind(this)
      // })
    }

    // console.log('location', this.props.location);

    Firebase.auth().onAuthStateChanged((firebaseUser) => {
      
      // console.log('firebase user state changed', firebaseUser);

      const adminToken = Cookies.getItem('impersonateUser')

      if (adminToken) {
        Firebase.auth().signInWithCustomToken(adminToken).then((firebaseUser) => {
          this.signIn(firebaseUser)
        })
        .catch((error) => {
          this.addNotification('error', 'Authentication error', error.message+ ' - code: '+error.code)
          this.setState({loading: false})
        })
      } // Check if we are already signed-in Firebase with the correct user.
      else if (firebaseUser) {
        // only signin users with a name (name is set in sign up form asynchronously)
        this.signIn(firebaseUser)
      } else {
        // User is signed out.
        this.setState({loading: false})
      }
    })
  }

  signIn(FirebaseUser, redirectTo, forceRefresh) {

    // console.log('sign in user', FirebaseUser);
    // https://firebase.google.com/docs/reference/js/firebase.User#getIdToken
    FirebaseUser.getIdToken(forceRefresh).then((token) => {

      // console.log('token', token);
      
      // log the server-side
      let data = {
        token: token,
        timezone: Moment.tz.guess(),
        language: navigatorLanguage
      }

      Axios({
        method: 'post',
        url: Config().api_endpoint+'/admins.login',
        data: data,
        // withCredentials: true
      })
      .then(response => {
        this.setState({
          firebaseUser: FirebaseUser,
          // firebaseToken: firebaseToken,
          admin: response.data.admin,
          organizations: response.data.organizations || [],
          loading: false
        })
        
        if (window.cmAgent) {
          window.cmAgent.setUserId(response.data.admin.id)
          window.cmAgent.event({
            label: 'login',
            props: {
              userId: response.data.admin.id,
              email: response.data.admin.primaryEmail,
            }
          })
          window.cmAgent.dispatch()
        }
        
        // ONBOARDING REDIRECTIONS

        if (this.props.location.pathname.indexOf('auth/action') === -1 && response.data.admin.emails[response.data.admin.primaryEmail] !== true) {
          return this.props.history.push('/check-your-mailbox')
        }

        if (redirectTo) {
          return this.props.history.push(redirectTo)
        }
        
      })
      .catch(error => {
        console.error(error)
        let message = error.message

        if (error.response && error.response.status <= 500) {
          message = error.response.data.message
        }

        this.addNotification('error', 'Login error', message)
      })

    })
    .catch((error) => {
      console.log(error)
    })
  }

  signOut() {
    Firebase.auth().signOut().then(() => {
      // console.log('out')
    }).catch(error => {
      console.error(error)
    })

    this.setState({
      firebaseUser: null,
      admin: null,
      organizations: []
    })

    this.props.history.push('/')
  }

  addNotification(type, message, description, duration = 4.5) {
    notification[type]({
      message: message,
      description: description,
      duration: duration
    })
  }

  addMessage(type, msg, duration = 4.5) {
    message[type](msg, duration)
  }

  setAppState(state) {
    this.setState(state)
  }

  toggleAdminSettings() {
    this.setState({adminSettingsVisible: !this.state.adminSettingsVisible})
  }

  ajaxRequest(options, callback) {

    this.state.firebaseUser.getIdToken().then((token) => {

      options.url = Config().api_endpoint+options.url
      options.headers = {Authorization: 'Bearer '+token}
      options.withCredentials = true

      Axios(options).then(response => {
        callback(null, response)
      })
      .catch(error => {

        console.log(error)

        let message = error.message

        if (error.response && error.response.status === 400) {
          switch(error.response.data.message) {
            default: 
              message = error.response.data.message
          }
        }

        callback(message)
      })
    })
    .catch((error) => {
      this.addNotification('error', 'Authentication error', error.message)
    })
  }

  stopImpersonating() {
    const cookieHost = (window.location.host.includes('localhost')) ? 'localhost' : '.captainmetrics.com'

    Cookies.removeItem('impersonateUser', '/', cookieHost)
    document.location.reload()
  }
  
  render() {

    const AppProps = {
      ajaxRequest: this.ajaxRequest,
      addNotification: this.addNotification,
      addMessage: this.addMessage,
      state: this.state, 
      setState: this.setAppState,
      signOut: this.signOut,
      toggleAdminSettings: this.toggleAdminSettings
    }

    // console.log('app state', this.state);

    let locale = _.get(this.state, 'admin.language', navigatorLanguage)

    // locale = 'en'
    Moment.locale(locale)
    Numeral.locale(locale)
    window.app = translations[locale]

    return <ConfigProvider locale={enUS}>
      <AdminSwitch stopImpersonating={this.stopImpersonating}>
        {(this.state.loading === true) ?
          <div className="splash">
            <img src={process.env.PUBLIC_URL+'/images/logo-white.png'} alt="Notifuse" className="splash-logo" />

            <div className="splash-content text-center">
              <h1 className="padding-b-l">{t('loading', 'Loading...')}</h1>

              <Spin size="large" />
            </div>
          </div>
        :
          ((!this.state.admin) ?
            <Switch>
              <Route path="/sign-up" render={(routerProps) => <SignUp addMessage={this.addMessage} signIn={this.signIn} />} />
              <Route path="/_auth" render={(routerProps) => <FirebaseAuthAction location={this.props.location} history={this.props.history} addMessage={this.addMessage} signIn={this.signIn} />} />
              <Route path="/check-your-mailbox" render={(routerProps) => <CheckYourMailbox app={AppProps} {...routerProps} />} />
              <Route path="/invitation" render={(routerProps) => <Invitation app={AppProps} location={this.props.location} history={this.props.history} signIn={this.signIn} />} />
              <Route render={() => <Login addMessage={this.addMessage} signIn={this.signIn} />}/>
            </Switch>
            :
            <Switch>
              <Route exact path="/" render={(routerProps) => <OrganizationsList app={AppProps} {...routerProps} />} />
              <Route path="/sign-up" render={(routerProps) => <SignUp app={AppProps} history={this.props.history} addMessage={this.addMessage} signIn={this.signIn} />} />
              <Route path="/sign-out" render={(routerProps) => <SignOut app={AppProps} {...routerProps} />} />
              <Route path="/check-your-mailbox" render={(routerProps) => <CheckYourMailbox app={AppProps} {...routerProps} />} />
              <Route path="/create-organization" render={(routerProps) => <CreateOrganization app={AppProps} {...routerProps} />} />
              <Route path="/invitation" render={(routerProps) => <Invitation app={AppProps} location={this.props.location} history={this.props.history} signIn={this.signIn} />} />
              <Route path="/_auth" render={(routerProps) => <FirebaseAuthAction location={this.props.location} history={this.props.history} addMessage={this.addMessage} signIn={this.signIn} />} />
              <Route path="/organizations/:organizationId" render={(routerProps) => <OrganizationLayout app={AppProps} {...routerProps} />} />
              <Route render={() => <div>Route not found :(</div>}/>
            </Switch>
          )
        }
      </AdminSwitch>
    </ConfigProvider>
  }
}

const AdminSwitch = (props) => {
  return <div>{props.children}
    {Cookies.getItem('impersonateUser') && <Button type="danger" className="admin-switch" onClick={props.stopImpersonating}>Stop admin session</Button>}
  </div>
}

const AppWithRouter = withRouter(App)

export default AppWithRouter