import React, { Component } from 'react'

import t from '../../utils/translate'
// import { ReloadOutlined } from '@ant-design/icons';
import { 
  Row, 
  Col, 
  Tooltip as AntdTooltip,
  Table, 
} from 'antd'
import Moment from 'moment-timezone'
import Formatters from '../../utils/formatters'
import N from 'numeral'
import { Chart, Interval, Tooltip, Legend } from 'bizcharts';
import _ from 'lodash'
import { 
  BellOutlined,
  MailOutlined,
  MobileOutlined,
  UserOutlined,
  EyeFilled,
  // EyeInvisibleFilled,
} from '@ant-design/icons';

class ProjectDashboard extends Component {
  _isMounted = false;

  constructor(props) {
    super(props)
    
    this.state = {
      loading: true,
      loadingMessages: true,
      messages: [],
      // visibleKpi: null,
      // dateRangeVs: 'previous_period',
      dateRangeLabel: 'last_30_days',
      dateRangeValue: [Moment().tz(props.app.state.admin.timezone).subtract(31, 'day').startOf('day'), Moment().tz(this.props.app.state.admin.timezone).subtract(1, 'day').endOf('day')],
      // refreshAt: Moment().unix()
    }

    // this.setDateRange = this.setDateRange.bind(this)
    // this.setDateRangeVs = this.setDateRangeVs.bind(this)
    // this.refresh = this.refresh.bind(this)
    this.fetchDashboard = this.fetchDashboard.bind(this)
    this.fetchMessages = this.fetchMessages.bind(this)
    // this.toggleKpi = this.toggleKpi.bind(this)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchDashboard()
    this.fetchMessages()

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Dashboard (project: '+this.props.projectLayout.project.id+')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  shouldComponentUpdate(nextProps) {
    // optimize menu collapse speed
    if (nextProps.organizationLayout.menuVisible !== this.props.organizationLayout.menuVisible) {
      return false
    }

    return true
  }

  componentDidUpdate(prevProps, prevState) {
    // check url params have changed
    if (prevProps.location.search !== this.props.location.search) {
      this.fetchDashboard()
      this.fetchMessages()
    // } else if (prevState.refreshAt !== this.state.refreshAt) {
    //   this.fetchDashboard(true)
    } else if (prevProps.projectLayout.project.id !== this.props.projectLayout.project.id) {
      this.fetchDashboard()
      this.fetchMessages()
    }
  }

  fetchDashboard() {

    let query = {
      projectId: this.props.projectLayout.project.id,
      timezone: this.props.app.state.admin.timezone,
      // dateFrom: this.state.dateRangeValue[0].format('YYYY-MM-DD HH:mm:ss'),
      // dateTo: this.state.dateRangeValue[1].format('YYYY-MM-DD HH:mm:ss'),
      // compareTo: this.state.dateRangeVs,
    }
    
    this.setState({loading: true})

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/projects.dashboard',
      params: query
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      // console.log('dashboard report', response.data);

      if (this._isMounted) {
        this.setState({
          dashboard: response.data,
          loading: false
        })
      }
    })
  }

  fetchMessages() {

    this.setState({loadingMessages: true})

    this.props.app.ajaxRequest({
      method: 'get',
      url: '/messages.list',
      params: {
        projectId: this.props.projectLayout.project.id,
        skip: 0,
        limit: 10,
      }
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loadingMessages: false})
        return
      }

      // console.log('dashboard report', response.data);

      if (this._isMounted) {
        this.setState({
          messages: response.data.messages,
          loadingMessages: false
        })
      }
    })
  }

  render() {

    // console.log('languagesTopSessions', languagesTopSessions);
    // console.log('state', this.state);
    const data = []

    // const currentDate = Moment().subtract(30, 'days')

    const stats = _.get(this.state, 'dashboard.stats', [])

    // stats.sort((a, b) => {

    // })

    stats.forEach(stat => {
      data.push({
        date: stat.date,
        channel: 'widget',
        count: stat.widget,
      })
      data.push({
        date: stat.date,
        channel: 'email',
        count: stat.email,
      })
      data.push({
        date: stat.date,
        channel: 'sms',
        count: stat.sms,
      })
    })

    // for(var i=0; i<30;i++){

    //   const date = currentDate.add(1, 'days').format('YYYY-MM-DD')

    //   data.push({
    //     date: date,
    //     channel: 'widget',
    //     count: i+_.random(1000, i+1500),
    //   })
    //   data.push({
    //     date: date,
    //     channel: 'email',
    //     count: i+_.random(800, i+1200),
    //   })
    //   data.push({
    //     date: date,
    //     channel: 'sms',
    //     count: Math.floor((i+_.random(1574, i+1574))/10),
    //   })
    // }

    // console.log('data', data);

    const usersCount = _.get(this.state, 'dashboard.counters.users', 0)
    const usersThisMonth = _.get(this.state, 'dashboard.counters.usersThisMonth', 0)
    const notificationsThisMonth = _.get(this.state, 'dashboard.counters.notificationsThisMonth', 0)
    const viewableThisMonth = _.get(this.state, 'dashboard.counters.viewableThisMonth', 0)
    const seenThisMonth = _.get(this.state, 'dashboard.counters.seenThisMonth', 0)
    const timeToReadThisMonth = _.get(this.state, 'dashboard.counters.timeToReadThisMonth', 0)

    const averageNotificationsPerUserThisMonth = (usersThisMonth > 0 && notificationsThisMonth > 0) ? (notificationsThisMonth/usersThisMonth) : 0
    const averageTimeToOpen = (timeToReadThisMonth > 0 && seenThisMonth > 0) ? timeToReadThisMonth/seenThisMonth : 0
    const openRate = (viewableThisMonth > 0 && seenThisMonth > 0) ? ((seenThisMonth*100)/viewableThisMonth) : 0

    return <div>
      <Row gutter={24} style={{borderBottom: '2px solid #cfd8dc'}}>
        <Col span={4}>
          <div className="kpi" style={{borderRight: '1px dashed #cfd8dc'}}>
            <div className="subtitle"><AntdTooltip title={t('total_users', "Total users")}>{t('total_users', "Total users")}</AntdTooltip></div>
            <div className="value">{N(usersCount).format('0,0')}</div>
          </div>
        </Col>
        <Col span={4}>
          <div className="kpi" style={{borderRight: '1px dashed #cfd8dc'}}>
            <div className="subtitle"><AntdTooltip title={t('users_notified_this_month', "Users notified /mo")}>{t('users_notified_this_month', "Users notified /mo")}</AntdTooltip></div>
            <div className="value">{N((usersThisMonth*100)/usersCount).format('0,0[.]0')}%</div>
          </div>
        </Col>
        <Col span={4}>
          <div className="kpi" style={{borderRight: '1px dashed #cfd8dc'}}>
            <div className="subtitle"><AntdTooltip title={t('sent_this_month', "Sent /mo")}>{t('sent_this_month', "Sent /mo")}</AntdTooltip></div>
            <div className="value">{N(notificationsThisMonth).format('0,0')}</div>
          </div>
        </Col>
        <Col span={4}>
          <div className="kpi" style={{borderRight: '1px dashed #cfd8dc'}}>
            <div className="subtitle"><AntdTooltip title={t('notifications_per_user_per_month', "Notifications / user /mo")}>{t('notifications_per_user_per_month', "Notifications / user /mo")}</AntdTooltip></div>
            <div className="value">{N(averageNotificationsPerUserThisMonth).format('0[.]0')}</div>
          </div>
        </Col>
        <Col span={4}>
          <div className="kpi" style={{borderRight: '1px dashed #cfd8dc'}}>
            <div className="subtitle"><AntdTooltip title={t('open_rate', "Open rate")}>{t('open_rate', "Open rate")}</AntdTooltip></div>
            <div className="value">{N(openRate).format('0[.]0')}%</div>
          </div>
        </Col>
        <Col span={4}>
          <div className="kpi">
            <div className="subtitle"><AntdTooltip title={t('average_time_to_open', "Avg. time to open")}>{t('average_time_to_open', "Avg. time to open")}</AntdTooltip></div>
            <div className="value">{Formatters.duration(averageTimeToOpen)}</div>
          </div>
        </Col>
      </Row>

      <div style={{borderBottom: '2px solid #cfd8dc'}}>
        <div className="subtitle padding-a-l">Last 30 days</div>

        <div className="margin-b-l">
          <Chart height={300} padding="auto" appendPadding={[20, 20, 20, 20]} data={data} autoFit animate={false} scale={{
            date: {
              type: 'timeCat',
              formatter: val => Moment(val).format('ll'),
              tickInterval: 3,
            }
          }}>
            <Interval
              adjust="stack"
              color={['channel', (xVal) => {
                if (xVal === 'widget') return '#4DD0E1'
                if (xVal === 'email') return '#FFD54F'
                return '#9575CD';
              }]}
              position="date*count"
            />
            <Tooltip shared />
            <Legend layout="horizontal" visible={true} position="bottom" title={false} />
          </Chart>

          {/*<StackedColumnChart 
            
            data={data}
            
            forceFit={true}

            xField="date"
            yField="count"
            yAxis={{ title: false }}
            xAxis={{ title: false, type: 'timeCat' }}
            stackField="channel"
            legend={{position: 'bottom'}}
            color={['#9575CD', '#4FC3F7', '#AED581', '#F48FB1']} />*/}
        </div>
      </div>


      <div className="subtitle padding-a-l">{t('recent_messages', "Recent messages")}</div>

      <Table
        pagination={false}
        dataSource={this.state.messages}
        loading={this.state.loadingMessages}
        className="margin-a-m margin-b-l"
        size="small"
        rowKey="id"
        columns={[
          {
            title: t('notification', "Notification"),
            key: 'notification',
            render: record => record.notificationId+' - '+record.userLanguage
          },
          {
            title: t('channels', "Channels"),
            key: 'channels',
            render: record => <div>
              {record.withWidget && <BellOutlined className="padding-r-s" />}
              {record.withEmail && <span>
                {record.emailError && <AntdTooltip title={record.emailError}><MailOutlined className="padding-r-s" style={{color: 'red'}} /></AntdTooltip>}
                {!record.emailError && <MailOutlined className="padding-r-s" />}
              </span>}
              {record.withSMS && <span>
                {record.smsError && <AntdTooltip title={record.smsError}><MobileOutlined className="padding-r-s" style={{color: 'red'}} /></AntdTooltip>}
                {!record.smsError && <MobileOutlined className="padding-r-s" />}
              </span>}
            </div>
          },
          {
            title: t('contact', "Contact"),
            key: 'user',
            render: record => <div>
              <UserOutlined /> {record.userId}
              {record.withEmail && <span> ― <a href={'mailto:'+record.userEmail}>{record.userEmail}</a></span>}
              {record.withSMS && <span> ― <a href={'tel:'+record.userTelephone}>{record.userTelephone}</a></span>}
            </div>
          },
          {
            title: t('viewed', "Viewed"),
            key: 'read',
            render: record => <small>
              {record.readAt && <span>
                <EyeFilled className="padding-r-s" /> 
                {record.readFrom === 'widget' && <BellOutlined className="padding-r-s" />}
                {record.readFrom === 'email' && <MailOutlined className="padding-r-s" />}
                {Moment(record.readAt).fromNow()}
              </span>}
            </small>
          },
          {
            title: t('sent_at', "Sent at"),
            key: 'at',
            render: record => <small>
              {Moment(record.createdAt).fromNow()}
            </small>
          },
          // {
          //   key: 'actions',
          //   render: p => <div className="text-right">
          //     {p.status === "active" && <Button type="primary" onClick={() => this.props.history.push('/organizations/'+this.props.organizationLayout.organization.id+'/projects/'+p.id)}>{t('view', "View")} <ArrowRightOutlined /></Button>}
          //   </div>
          // }
        ]}
      />
    </div>
  }
}

export default ProjectDashboard