import React, { Component } from 'react'
import { 
  Button, 
  Radio,
  Input,
  Slider,
  Row,
  Modal,
  Col,
  Upload,
} from 'antd'
import Cropper from 'react-easy-crop'
import { UploadOutlined } from '@ant-design/icons'
import * as Firebase from 'firebase/app'
import 'firebase/storage'

class ImageInput extends Component {

  constructor(props) {
    super(props)

    this.state = {
      imgSrc: null,
      imgId: null,
      imgType: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      loading: false,
      mode: 'resize'
    }

    this.beforeUpload = this.beforeUpload.bind(this)
    this.onCropComplete = this.onCropComplete.bind(this)
    this.onCropChange = this.onCropChange.bind(this)
    this.onZoomChange = this.onZoomChange.bind(this)
    this.createImage = this.createImage.bind(this)
    this.submitImage = this.submitImage.bind(this)

    this.originalFile = null
    this.blob = null
  }

  beforeUpload(file, fileList) {
    // console.log('file', file)
    this.originalFile = file
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      this.setState({ 
        imgSrc: reader.result,
        imgId: file.uid,
        imgType: file.type === 'image/png' ? 'png' : 'jpg' 
      })
    );
    reader.readAsDataURL(file);
    return false
  }

  onCropChange(crop) {
    this.setState({ crop });
  };

  onZoomChange(zoom) {
    this.setState({ zoom })
  }

  createImage(url) {
    return new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })
  }

  onCropComplete (croppedArea, crop) {

    // console.log(croppedArea, crop)

    this.createImage(this.state.imgSrc).then(image => {

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      /* setting canvas width & height allows us to 
      resize from the original image resolution */
      canvas.width = 156
      canvas.height = 156

      ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
      )

      canvas.toBlob((blob) => {
        this.blob = blob
      }, 'image/'+this.state.imgType)
    })
  }

  submitImage() {
    // console.log('submit', this.blob);
    if ((!this.blob || !this.originalFile) || this.state.loading) {
      return
    }

    this.setState({loading: true})

    // upload blob to firebase 'images' folder with filename 'image'
    const storageRef = Firebase.storage().ref('uploads')

    const fileName = this.state.imgId+'.'+this.state.imgType

        // Upload file and metadata to the object 'images/mountains.jpg'
    const uploadTask = storageRef.child(this.props.organizationId+'/'+fileName).put(this.state.mode === 'resize' ? this.blob : this.originalFile, { contentType: this.blob.type });

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(Firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
        // // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        // switch (snapshot.state) {
        //   case Firebase.storage.TaskState.PAUSED: // or 'paused'
        //     console.log('Upload is paused');
        //     break;
        //   case Firebase.storage.TaskState.RUNNING: // or 'running'
        //     console.log('Upload is running');
        //     break;
        // }
      }, (error) => {
        this.setState({loading: false})
        this.props.addMessage('error', error.code)
    }, () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.setState({loading: false, imgSrc: null, imgId: null, imgType: null, mode: 'resize'})
          this.props.onChange(downloadURL)
          // console.log('File available at', downloadURL);
        })
    })
  }

  render() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }

    return <Row gutter={0}>
      <Col span={7}>
        <Upload accept="image/*" showUploadList={false} beforeUpload={this.beforeUpload}>
          <Button icon={<UploadOutlined />}>Upload image</Button>
        </Upload>
        {this.state.imgSrc && <Modal
          title={undefined}
          visible={true}
          closable={false}
          cancelProps={{loading: this.state.loading}}
          onOkProps={{loading: this.state.loading}}
          onCancel={() => this.setState({imgSrc: null})}
          onOk={() => this.submitImage()}
          >
          <Radio.Group onChange={e => this.setState({mode: e.target.value})} value={this.state.mode}>
            <Radio style={radioStyle} value="original">Keep original</Radio>
            <Radio style={radioStyle} value="resize">Resize</Radio>
          </Radio.Group>

          {this.state.mode === 'resize' && <div>
            <div style={{height: '500px', position: 'relative'}}>
              <div className="crop-container">
                <Cropper
                  image={this.state.imgSrc}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={this.state.aspect}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                />
              </div>
            </div>

            <Slider 
              value={this.state.zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={zoom => this.onZoomChange(zoom)}
            />
          </div>}
        </Modal>}
      </Col>
      <Col span={17}>
        <Input placeholder="Image URL https://..." value={this.props.value} onChange={this.props.onChange} />
      </Col>
    </Row>
  }
}

export default ImageInput