import React from 'react'

import t from '../../utils/translate'
import { Button, Row, Col, Badge } from 'antd';
import capitalize from 'lodash/capitalize'
import ConnectPostmark from '../channels/_connectPostmark'
import ConnectSendgrid from '../channels/_connectSendgrid'
import ConnectTwilio from '../channels/_connectTwilio'
import ConnectMailjet from '../channels/_connectMailjet'
// import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const SetupChannels = ({app, projectLayout, organizationLayout}) => {

  const blocks = []

  let withEmail = false
  let withSMS = false

  projectLayout.notifications.forEach(x => {
    if (x.withEmail) withEmail = true
    if (x.withSMS) withSMS = true
  })

  // add widget block anyway
  blocks.push(<div className="block-channel">
    <div className="block-channel-title">Widget</div>
    <div className="padding-a-m">
      <p>Please forward the following documentation to your frontend developers in order to integrate the notification center into your SaaS.</p>
      <a href="https://docs.notifuse.com?utm_source=notifuse.com&utm_medium=console" target="_blank" rel="noreferrer noopener"><Button block type="primary" ghost className="margin-t-m">Go to documentation</Button></a>
    </div>
  </div>)

  if (withEmail) blocks.push(<div className="block-channel">
    <div className="block-channel-title">
      <span className="actions">
        {projectLayout.project.emailProvider && <Badge className="margin-l-m" status="success" text={t('connected_to', "Connected to")+' '+capitalize(projectLayout.project.emailProvider)} />}
        {!projectLayout.project.emailProvider && <Badge status="error" text={t('not_connected', "Not connected")} />}
      </span>
      Email
    </div>

    <div className="padding-a-m">
      <p>In order to guarantee an optimal deliverability, your email notifications should be sent by an email provider. Please connect your provider below.</p>

      <Row className="margin-t-l">
        <Col flex="150px"><div className="block-channel-logo"><img src="/images/channels/postmark.png" alt="Postmark" /></div></Col>
        <Col flex="auto">
          <ConnectPostmark app={app} organizationLayout={organizationLayout} projectLayout={projectLayout} btnType="primary" btnSize="normal" />
        </Col>
      </Row>

      <Row className="margin-t-l">
        <Col flex="150px"><div className="block-channel-logo"><img src="/images/channels/sendgrid.png" alt="Sendgrid" /></div></Col>
        <Col flex="auto">
          <ConnectSendgrid app={app} organizationLayout={organizationLayout} projectLayout={projectLayout} btnType="primary" btnSize="normal" />
          {projectLayout.project.emailProvider === 'sendgrid' && <Badge className="margin-l-m" status="success" text={t('connected', "Connected")} />}
        </Col>
      </Row>

      <Row className="margin-t-l">
        <Col flex="150px"><div className="block-channel-logo"><img src="/images/channels/mailjet.png" alt="Mailjet" /></div></Col>
        <Col flex="auto">
          <ConnectMailjet app={app} organizationLayout={organizationLayout} projectLayout={projectLayout} btnType="primary" btnSize="normal" />
          {projectLayout.project.emailProvider === 'mailjet' && <Badge className="margin-l-m" status="success" text={t('connected', "Connected")} />}
        </Col>
      </Row>
    </div>
  </div>)

  if (withSMS) blocks.push(<div className="block-channel">
    <div className="block-channel-title">
      <span className="actions">
        {projectLayout.project.smsProvider && <Badge className="margin-l-m" status="success" text={t('connected_to', "Connected to")+' '+capitalize(projectLayout.project.smsProvider)} />}
        {!projectLayout.project.smsProvider && <Badge status="error" text={t('not_connected', "Not connected")} />}
      </span>
      Email
    </div>

    <div className="padding-a-m">
      <p>In order to guarantee an optimal deliverability, your SMS notifications should be sent by an SMS provider. Please connect your provider below.</p>

      <Row className="margin-t-l">
        <Col flex="150px"><div className="block-channel-logo"><img src="/images/channels/twilio.png" alt="Twilio" /></div></Col>
        <Col flex="auto">
          <ConnectTwilio app={app} organizationLayout={organizationLayout} projectLayout={projectLayout} btnType="primary" btnSize="normal" />
          {projectLayout.project.smsProvider === 'twilio' && <Badge className="margin-l-m" status="success" text={t('connected', "Connected")} />}
        </Col>
      </Row>
    </div>
  </div>)

  // if (projectLayout.notifications.find(x => x.channel === 'sms'))) blocks.push('sms')

  const span = Math.floor(24/blocks.length)

  return <Row gutter={24}>
    {blocks.map((x, i) => {
      return <Col key={i} span={span}>{x}</Col>
    })}
  </Row>

}

export default SetupChannels