import React, { Component } from 'react'

import t from '../../utils/translate'
// import { ReloadOutlined } from '@ant-design/icons';
import { 
  Alert,
  // Row, 
  // Col, 
} from 'antd'
// import _ from 'lodash'
import NotificationItem from './_item'
import NotificationForm from './_form'

class ProjectTemplates extends Component {

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Templates (project: '+this.props.projectLayout.project.id+')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }
  }

  render() {

    return <div>
      <h1 className="margin-b-l">
      <div className="actions"><NotificationForm {...this.props} inDrawer={true} /></div>
        {t('templates', "Templates")}
      </h1>
      <div className="margin-h-m">

        <Alert
          description={t('templates_info', "A notification defines which channels it can be sent to, and holds the templates for each channel/translation.")}
          type="info"
          showIcon
          className="margin-b-l"
        />

        {this.props.projectLayout.notifications.map(notification => <NotificationItem 
          key={notification.id} 
          app={this.props.app}
          projectLayout={this.props.projectLayout}
          notification={notification} />
        )}
      </div>
    </div>
  }
}

export default ProjectTemplates