import React, { useState } from "react"
import t from '../../utils/translate'
import Countries from '../../utils/countries'
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
} from 'antd';

const DetailsForm = ({onComplete, onLoading, app, organization}) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)

  const submit = (values) => {
    setLoading(true)

    if (onLoading) {
      onLoading(true)
    }

    app.ajaxRequest({
      method: 'post',
      url: '/accounts.setBilling',
      data: values
    }, (errorMessage, response) => {

      if (errorMessage) {
        app.addMessage('error', errorMessage)
        setLoading(false)
        if (onLoading) {
          onLoading(false)
        }
        return
      }

      app.setState({account: response.data.account})
      setLoading(false)
      
      if (onComplete) {
        onComplete()
      }
      if (onLoading) {
        onLoading(false)
      }
    })
  }
  
  return <Form labelCol={{span: 8}} wrapperCol={{span: 16}}
    form={form}
    name="basic"
    initialValues={{
      firstName: organization.firstName,
      lastName: organization.lastName,
      name: organization.name,
      addressLine1: organization.addressLine1,
      addressLine2: organization.addressLine2,
      city: organization.city,
      postalCode: organization.postalCode,
      state: organization.state,
      country: organization.country,
    }}
    onFinish={submit}
    onFinishFailed={() => {}}
  >
    <Row gutter={24}>
      <Col span={10}>
        <Form.Item label={t('first_name', "First name")} name="firstName" rules={[{required: true, message: t('field_required', "This field is required")}]}><Input /></Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label={t('last_name', "Last name")} name="lastName" rules={[{required: true, message: t('field_required', "This field is required")}]}><Input /></Form.Item>
      </Col>
    </Row>

    <Row gutter={24}>
      <Col span={10}>
        <Form.Item label={t('business_name', "Business name")} name="name" rules={[{required: false}]}><Input /></Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label={t('vat_id', "VAT ID")} name="vatId" rules={[{required: false}]}><Input placeholder={t('only_for_eu_businesses', "Only for EU businesses")} /></Form.Item>
      </Col>
    </Row>

    <Row gutter={24}>
      <Col span={10}>
        <Form.Item label={t('address_line', "Address line")+' 1'} name="addressLine1" rules={[{required: true, message: t('field_required', "This field is required")}]}><Input /></Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label={t('address_line', "Address line")+' 2'} name="addressLine2" rules={[{required: false}]}><Input /></Form.Item>
      </Col>
    </Row>

    <Row gutter={24}>
      <Col span={10}>
        <Form.Item label={t('city', "City")} name="city" rules={[{required: true, message: t('field_required', "This field is required")}]}><Input /></Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label={t('postal_code', "Postal code")} name="postalCode" rules={[{required: true, message: t('field_required', "This field is required")}]}><Input /></Form.Item>
      </Col>
    </Row>

    <Row gutter={24}>
      <Col span={10}>
        <Form.Item label={t('state', "State")} name="state" rules={[{required: true, message: t('field_required', "This field is required")}]}><Input /></Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item label={t('country', "Country")} name="country" rules={[{required: true, message: t('field_required', "This field is required")}]}>
          <Select
            showSearch
            placeholder={t('select_a_country', "Select a country")}
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {Countries.map((c) => <Select.Option key={c.code} value={c.code}>{c.code+' - '+c.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <div className="text-right">
        <Button type="primary" htmlType="submit" loading={loading}>{t('update', "Update")}</Button>
        </div>
      </Col>
    </Row>
  </Form>
}

export default DetailsForm