import React, { Component } from 'react'

// import { LockOutlined, MailOutlined } from '@ant-design/icons';

import { Input, Button, Checkbox, Row, Col , Form} from 'antd';
import t from '../../utils/translate'
import Firebase from 'firebase/app'
import 'firebase/auth'
import Config from '../../config'
import Axios from 'axios'
import Moment from 'moment-timezone'

class SignUp extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.signInWithGoogle = this.signInWithGoogle.bind(this)
    this.onFinish = this.onFinish.bind(this)
    this.onFinishFailed = this.onFinishFailed.bind(this)
  }

  signInWithGoogle() {
    const provider = new Firebase.auth.GoogleAuthProvider()

    Firebase.auth().signInWithPopup(provider).catch((error) => {
      console.error(error)
    })
  }

  onFinishFailed(error) {
    console.error('reset password form error', error)
    return
  }

  onFinish(values) {

    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    Axios({
      method: 'post',
      url: Config().api_endpoint+'/admins.create',
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        timezone: Moment.tz.guess(),
        language: ((navigator.language || navigator.userLanguage).indexOf('fr') !== -1) ? 'fr' : 'en'
      }
    })
    .then(response => {
      
      // console.log('res', response.data);
      Firebase.auth().signInWithCustomToken(response.data.firebaseToken).then(() => {
        
        Firebase.auth().currentUser.sendEmailVerification().catch((error) => {
          console.error('send email verification', error)
        })
      }).catch((error) => {
        console.error(error)
        this.setState({loading: false})
      })

    })
    .catch(error => {
      console.error(error)
      let message = error.message

      if (error.response && error.response.status <= 500) {
        message = error.response.data.message
      }

      this.props.addMessage('error', message)
      this.setState({loading: false})
    })
  }

  render() {
    return <div>
        <Button type="primary" ghost size="large" onClick={this.signInWithGoogle}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="google-svg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
          {t('sign_in_with_google', "Sign in with Google")}
        </Button>

        <div className="text-center margin-v-l">{t('or_sign_up_with_credentials', "Or sign up with credentials")}:</div>

        <Row>
          <Col span={16} offset={4}>
          <Form
            layout="vertical"
            name="login"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              label={t('first_name', "First name")}
              name="firstName"
              rules={[{ required: true, type: 'string' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('last_name', "Last name")}
              name="lastName"
              rules={[{ required: true, type: 'string' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('email_address', "Email address")}
              name="email"
              rules={[{ required: true, type: 'email' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('password', "Password")}
              name="password"
              rules={[{ required: true, type: 'string' }]}
            >
              <Input type="password" />
            </Form.Item>
          
            <Form.Item name="terms" valuePropName="checked" 
            rules={[
              { validator:(_, value) => value ? Promise.resolve() : Promise.reject('You should accept the terms.') },
            ]}>
              <Checkbox><span dangerouslySetInnerHTML={{__html: t('create_account_terms_checkbox', 'By creating an account I agree to the <a href="https://notifuse.com/terms" target="blank">Terms and Conditions &nearr;</a> and <a href="https://notifuse.com/privacy" target="blank">Privacy policy &nearr;</a>.')}}/></Checkbox>
            </Form.Item>

            <Button className="margin-t-m" type="primary" loading={this.state.loading} size="large" htmlType="submit" block>{t('confirm', "Confirm")}</Button>
          </Form>
        </Col>
      </Row>
    </div>
  }
}

export default SignUp