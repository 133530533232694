import React, { useState, useEffect } from 'react'
import t from '../../utils/translate'
import DetailsForm from './_detailsForm'
import {
  Spin,
  Badge,
  // Button,
} from 'antd'
import Moment from 'moment-timezone'
import Formatters from '../../utils/formatters'

const Billing = ({app, organizationLayout}) => {


  // const [intentSecretLoaded, setIntentSecretLoaded] = useState(false)
  const [stripeCustomer, setStripeCustomer] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!stripeCustomer) {
      setLoading(true)

      app.ajaxRequest({
        method: 'get',
        url: '/organizations.stripeDetails',
        params: {
          organizationId: organizationLayout.organization.id
        }
      }, (errorMessage, response) => {

        // setIntentSecretLoaded(true)

        if (errorMessage) {
          app.addMessage('error', errorMessage)
          setLoading(false)
          return
        }

        console.log('stripe', response.data.stripeCustomer);
        setStripeCustomer(response.data.stripeCustomer)
        setLoading(false)
      })
    }
  }, [app, stripeCustomer, organizationLayout.organization.id]);

  
    const planIdToName = (id) => {
      if (id === 'price_1Hq1JDHL4s2OhEvqqFJ0HDYr' || id === 'price_1Hq1QqHL4s2OhEvqIlWO6UMx') return 'Free'
      if (id === 'price_1Hq1NMHL4s2OhEvqYDlbJJoG' || id === 'price_1Hq1QmHL4s2OhEvqty07Cgdj') return 'Pro'
      if (id === 'price_1Hq1OqHL4s2OhEvqFeKe8UvS' || id === 'price_1Hq1QfHL4s2OhEvqkm3P48yC') return 'Premium'
      // if (id === 'price_1GspolHL4s2OhEvqqKvUN1mt' || id === 'price_1GspolHL4s2OhEvqw3S0Ctqa') return 'Enterprise'
      return 'Unknown'
    }

    // console.log('props', this.props);
    return <React.Fragment>
      <h1>{t('account_billing', "Account & billing")}</h1>

      <h2 className="margin-b-m">{t('subscription', "Subscription")}</h2>

      {loading && <Spin className="margin-v-m" />}

      {stripeCustomer && <div>
        {stripeCustomer.subscriptions.data.map(sub => <div key={sub.id} className="margin-h-m">

        <table className="key-value-table margin-t-l">
          <tbody>
            <tr>
              <th style={{width: '180px'}}>Plan</th>
              <td>{planIdToName(sub.plan.id)}</td>
            </tr>
            <tr>
              <th>{t('status', 'Status')}</th>
              <td><Badge status={sub.plan.active ? 'success' : 'warning'} text={sub.status} /></td>
            </tr>
            <tr>
              <th>{t('trial_end', "Trial end")}</th>
              <td>{Moment.unix(sub.trial_end).fromNow()}</td>
            </tr>
            <tr>
              <th>{t('billing_period', "Billing period")}</th>
              <td>{Moment.unix(sub.current_period_start).format('LL')} &rarr; {Moment.unix(sub.current_period_end).format('LL')}</td>
            </tr>
            <tr>
              <th>{t('base_amount', "Base amount")}</th>
              <td>{sub.plan.tiers && Formatters.currency(sub.plan.currency, sub.plan.tiers[0].flat_amount, '0,0[.]00')} {sub.plan.interval === 'month' && t('per_month', "/m")} {sub.plan.interval === 'year' && t('per_year', "/y")} </td>
            </tr>
          </tbody>
        </table>

        {/*<Button type="link">{t('change', "Change")}</Button>*/}
        {/*<Button type="link">{t('cancel', "Cancel")}</Button>*/}
        </div>)}
      </div>}
      
      <h2 className="margin-v-l">{t('billing_details', "Billing details")}</h2>
      
      <DetailsForm organization={organizationLayout.organization} onComplete={() => {
        app.addMessage('success', t('billing_updated', "Your billing details have been updated!"))
      }} />
    </React.Fragment>
}

export default Billing