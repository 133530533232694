import React, { Component } from 'react'

import QS from 'qs'
import { Input, Button, Alert, Spin, Row, Col, Form } from 'antd';
import t from '../../utils/translate'
import Firebase from 'firebase/app'
import 'firebase/auth'
import _ from 'lodash'
import Config from '../../config'
import Axios from 'axios'
import Splash from './_splash'

class FirebaseAuthAction extends Component {

  constructor(props) {
    super(props)

    const params = QS.parse(this.props.location.search, { ignoreQueryPrefix: true })
    // console.log('params', params);

    this.state = {
      mode: params.mode,
      oobCode: params.oobCode,
      apiKey: params.apiKey,
      init: true,
      false: true,
      error: undefined,
      codeInfo: undefined,
    }

    this.onFinish = this.onFinish.bind(this)
    this.onFinishFailed = this.onFinishFailed.bind(this)
  }

  componentDidMount() {

    // check code
    Firebase.auth().checkActionCode(this.state.oobCode).then((codeInfo) => {
      this.setState({
        codeInfo: codeInfo,
        init: (codeInfo.operation === 'VERIFY_EMAIL') ? true : false,
        error: undefined
      })

      if (codeInfo.operation === 'VERIFY_EMAIL') {

        Axios({
          method: 'post',
          url: Config().api_endpoint+'/admins.verifyEmail',
          data: {
            oobCode: this.state.oobCode
          },
          // withCredentials: true
        })
        .then(response => {
          
          this.props.addMessage('success', t('email_verified', "Your email address has successfully been verified!"))

          if (Firebase.auth().currentUser) {
            // force refresh token in case the email verification linked the admin to an organization (invitation signup)
            this.props.signIn(Firebase.auth().currentUser, '/', true)
          } else {
            this.props.history.push('/')
          }
        })
        .catch(error => {
          console.error(error)
          let message = error.message

          if (error.response && error.response.status <= 500) {
            message = error.response.data.message
          }

          this.setState({init: false, error: message})
        })

        // Firebase.auth().applyActionCode(this.state.oobCode).then(() => {
        //   this.props.addMessage('success', t('email_verified', "Your email address has successfully been verified!"))
        //   this.props.history.push('/')
        // }).catch((error) => {
        //   console.error(error)
        //   this.setState({error: error, init: false})
        // })
      }

      // if (codeInfo.operation === 'RECOVER_EMAIL') {
      //   Firebase.auth().applyActionCode(this.state.oobCode).then(() => {
      //     this.props.addMessage('success', t('email_recovered', "Your email address has successfully been recovered!"))
      //     this.props.history.push('/')
      //   }).catch((error) => {
      //     console.error(error)
      //     this.setState({error: error, init: false})
      //   })
      // }
    }).catch((error) => {
      console.error(error)
      this.setState({error: error, init: false})
    })
  }

  onFinish(values) {

    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    Firebase.auth().confirmPasswordReset(this.state.oobCode, values.password).then((firebaseUser) => {
      this.props.addMessage('success', t('password_reset_success', "Your password has successfully been updated!"))
      this.props.history.push('/')
    }).catch((error) => {
      // var errorCode = error.code;
      // var errorMessage = error.message;
      console.error('reset password error', error)

      this.setState({loading: false, error: error.message})
    })
  }

  onFinishFailed(error) {
    console.error('reset password form error', error)
    return
  }

  render() {
    let h1 = this.state.mode || "Invalid request"

    switch(this.state.mode) {
      case 'verifyEmail':
        h1 = t('verify_your_email_address', "Verify your email address")
      break
      case 'resetPassword':
        h1 = t('reset_your_password', "Reset your password")
      break
      default:
    }

    if (this.state.init) {
      return <Splash h1={h1}><Spin size="large" /></Splash>
    }
    
    return <Splash h1={h1}>
      <div>
        {this.state.error && <Alert type="error" message={this.state.error.message} />}

        {this.state.mode === 'resetPassword' && _.get(this.state.codeInfo, 'data.email', null) && <div>
          <p>{t('for_x_email', "For {email}", {email: _.get(this.state.codeInfo, 'data.email')})}</p>

          <Row>
            <Col span="16" offset="4">
              <Form
                layout="vertical"
                name="login"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  label={t('new_password', "New password")}
                  name="password"
                  rules={[{ required: true, type: 'string' }]}
                >
                  <Input type="password" />
                </Form.Item>

                <Button className="margin-t-m" type="primary" loading={this.state.loading} size="large" htmlType="submit" block>{t('confirm', "Confirm")}</Button>
              </Form>
            </Col>
          </Row>
        </div>}
      </div>
    </Splash>
  }
}

export default FirebaseAuthAction