import React, { Component } from 'react'

class SplashScreen extends Component {

  render() {
    return <div className="splash">
      <img src={process.env.PUBLIC_URL+'/images/logo-white.png'} alt="Notifuse" className="splash-logo" />

      <div className="splash-content text-center">
        <h1 className="padding-b-l">{this.props.h1}</h1>
        {this.props.children}
      </div>
    </div>
  }
}

export default SplashScreen