import React, { Component } from 'react'
import { Button, Form, Input, Divider, Switch, Drawer, Select } from 'antd';
// import { ArrowRightOutlined } from '@ant-design/icons';
// import _ from 'lodash'
import t from '../../utils/translate'
import AceInput from './_aceInput'
import Languages from '../../utils/languages'
import PhoneInput from './_phoneNumberInput'
import parsePhoneNumber from 'libphonenumber-js'

const TestNotificationForm = ({adminEmail, project, loading, notification, drawerVisible, setDrawerVisible, onFinish, onFinishFailed}) => {
  const [form] = Form.useForm();
  // console.log('project', project);

  const initialValues = {
    userId: 'test', 
    userLanguage: project.defaultNotificationLanguage,
    testData: JSON.stringify(notification.testData ? notification.testData : {}, null, 2),
  }

  if (notification.withWidget) {
    initialValues.testWidget = true
  }
  if (notification.withEmail) {
    initialValues.testEmail = true
    initialValues.userEmail = adminEmail
  }
  if (notification.withSMS) {
    initialValues.testSMS = true
  }
  
  return <Drawer
    title={t('test_notification', "Test notification")+' - '+notification.name}
    visible={drawerVisible}
    closable={true}
    maskClosable={false}
    width={640}
    onClose={() => { setDrawerVisible(false) }}
    bodyStyle={{ paddingBottom: 80 }}
    footer={
      <div style={{textAlign: 'right'}}>
        <Button loading={loading} onClick={() => setDrawerVisible(false)} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
        <Button loading={loading} onClick={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }} type="primary">{t('confirm', "Confirm")}</Button>
      </div>
    }
  >
  <Form
    form={form}
    layout="horizontal"
    name="test_notif_form"
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    labelCol={{sm: { span: 8 }}}
    wrapperCol={{sm: { span: 14 }}}
    initialValues={initialValues}
  >
    <Form.Item 
      name="userId"
      label={t('user_id', "User ID")}
      rules={[{ required: true, type: 'string'}]}
    >
      <Input />
    </Form.Item>

    <Form.Item 
      name="userLanguage"
      label={t('user_language', "User language")} 
      rules={[{ required: true, type: 'string'}]}
    >
      <Select 
        placeholder={t('select_a_value', "Select a value")}
        allowClear={false}
        optionFilterProp="label"
        options={Languages.map((language) => { return {label: language.alpha2+' - '+language.name, value: language.alpha2}})}
      />
    </Form.Item>

    {notification.withWidget && <div>
      <Form.Item 
        name="testWidget"
        label={t('test_widget', "Test widget")}
        rules={[{ required: false, type: 'boolean' }]}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </div>}

    {notification.withEmail && <div>
      <Form.Item 
        name="testEmail"
        label={t('test_email', "Test email")}
        rules={[{ required: false, type: 'boolean' }]}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.testEmail !== curValues.testEmail}>
        {({ getFieldValue }) => {

          if (getFieldValue('testEmail') === true) return <Form.Item 
            name="userEmail"
            label={t('user_email', "User email address")}
            rules={[{ required: true, type: 'email'}]}
          >
            <Input />
          </Form.Item>

          return
        }}
      </Form.Item>
    </div>}

    {notification.withSMS && <div>
      <Form.Item 
        name="testSMS"
        label={t('test_sms', "Test SMS")}
        rules={[{ required: false, type: 'boolean' }]}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.testSMS !== curValues.testSMS}>
        {({ getFieldValue }) => {

          if (getFieldValue('testSMS') === true) return <Form.Item 
            name="userTelephone"
            label={t('user_mobile_phone', "User mobile phone")}
            rules={[
              { required: true, type: 'string'},
              { validator: (xxx, value) => {
                return new Promise((resolve, reject) => {
                  try {
                    const phone = parsePhoneNumber(value)

                    if (phone && phone.isValid() === true) {
                      resolve()
                    }
                    reject('Please enter a valid international phone number (+xx...)')
                  }catch(e) {
                    reject(e)
                  }
                })
              }}
            ]}
          >
            <PhoneInput />
          </Form.Item>

          return
        }}
      </Form.Item>
    </div>}


    <Divider plain>{t('test_data', "Test data")}</Divider>

    <p>{t('test_data_info', "The \"test data\" is a JSON object that is used to customize your templates when you want to preview or test them.")}</p>
    <Form.Item 
      name="testData"
      validateFirst={true}
      labelCol={{sm: { span: 0 }}}
      wrapperCol={{sm: { span: 24 }}}
      rules={[
        { validator:(xxx, value) => {
          return new Promise((resolve, reject) => {
            // check if data is valid json
            try { 
              if (JSON.parse(value)) {}
              return resolve()
            }
            catch(e) {
              return reject(t('test_data_is_not_valid_json', "Your test variables is not a valid JSON object!")) 
            }
          })

        }},
        { required: false, type: 'object', transform: (value) => {
          try { 
            const parsed = JSON.parse(value)
            return parsed
          }
          catch(e) {
            return value
          }
        } },
      ]}
    >
      <AceInput id="testData" width="100%" height="200px" mode="json" />
    </Form.Item>

    {/*<Divider plain>{t('channels', "Channels")}</Divider>

    <Form.Item 
      name="withWidget"
      label={t('in_app_widget', "In-app widget")} 
      rules={[{ required: false, type: 'boolean' }]}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>

    <Form.Item 
      name="withEmail"
      label={t('email', "Email")} 
      rules={[{ required: false, type: 'boolean' }]}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>

    <Form.Item 
      name="withSMS"
      label={t('sms', "SMS")} 
      help="Coming soon..."
      rules={[{ required: false, type: 'boolean' }]}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>

    <Form.Item 
      name="withSlack"
      label="Slack"
      help="Coming soon..."
      rules={[{ required: false, type: 'boolean' }]}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>

    <Form.Item 
      name="withWebPush"
      label="Web Push"
      help="Coming soon..."
      rules={[{ required: false, type: 'boolean' }]}
      valuePropName="checked"
    >
      <Switch disabled />
    </Form.Item>*/}
  </Form>
  </Drawer>
}


class TestNotificationDrawer extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      drawerVisible: false
    }

    this.onFinish = this.onFinish.bind(this)
    this.onFinishFailed = this.onFinishFailed.bind(this)
    this.setDrawerVisible = this.setDrawerVisible.bind(this)
  }

  setDrawerVisible() {
    this.setState({drawerVisible: !this.state.drawerVisible})
  }

  onFinishFailed(error) {
    console.error('form error', error)
    return
  }

  onFinish(values) {

    if (this.state.loading === true) {
      return
    }
    else {
      this.setState({loading: true})
    }

    values.projectId = this.props.projectLayout.project.id
    values.notificationId = this.props.notification.id

    if (values.testData) {
      try { 
        const parsed = JSON.parse(values.testData)
        values.testData = parsed
      }
      catch(e) {
        values.testData = {}
      }
    }
    
    // console.log('values', values);

    this.props.app.ajaxRequest({
      method: 'post',
      url: '/messages.test',
      data: values
    }, (errorMessage, response) => {

      if (errorMessage) {
        this.props.app.addMessage('error', errorMessage)
        this.setState({loading: false})
        return
      }

      this.props.app.addMessage('success', t('notification_sent', "The notification has successfully been sent!"))

      if (window.cmAgent) {
        window.cmAgent.event({
          label: 'testNotification',
          // props: {
            // orgId: response.data.organization.id,
            // projectId: 
          // }
        })
        window.cmAgent.dispatch()
      }

      this.setState({loading: false, drawerVisible: false})

      if (this.props.onComplete) this.props.onComplete()
    })
  }

  render() {

    // console.log('qsd', this.state);
    return <span>
      <TestNotificationForm
        notification={this.props.notification}
        adminEmail={this.props.app.state.admin.primaryEmail}
        project={this.props.projectLayout.project}
        drawerVisible={this.state.drawerVisible}
        setDrawerVisible={this.setDrawerVisible}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        loading={this.state.loading}
      />
      <Button loading={this.state.loading} type={this.props.notification ? 'default': 'primary'} size={this.props.btnSize || 'normal'} onClick={() => this.setDrawerVisible(true)}>{t('test', "Test")}</Button>
    </span>
  }
}

export default TestNotificationDrawer
