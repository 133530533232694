import React, { Component } from 'react'

import t from '../../utils/translate'
import Firebase from 'firebase/app'
import 'firebase/auth'
import { Link } from 'react-router-dom'
import SignUpForm from './_signUpForm'
import Splash from './_splash'

class SignUp extends Component {

  constructor(props) {
    super(props)

    this.state = {
      checkbox: false,
      loading: false,
    }

    this.signInWithGoogle = this.signInWithGoogle.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Sign up',
        page: '/sign-up'
      })
      window.cmAgent.dispatch()
    }

    if (this.props.app && this.props.app.state.firebaseUser) {
      this.props.history.push('/')
    }
  }

  signInWithGoogle() {
    const provider = new Firebase.auth.GoogleAuthProvider()

    Firebase.auth().signInWithPopup(provider).catch((error) => {
      console.error(error)
    })
  }

  handleSubmit(e) {
    e.preventDefault()

    if (this.state.checkbox !== true) {
        this.props.addMessage('error', t('checkbox_terms_required', "Accepting the Terms and Conditions is mandatory to create an account."))
        return
    }

    this.props.form.validateFields((error, values) => {
      if (error) {
        console.error('sign up values error', error)
        return
      }

      if (this.state.loading === true) {
        return
      }
      else {
        this.setState({loading: true})
      }

      Firebase.auth().createUserWithEmailAndPassword(values.email, values.password).then((firebaseUser) => {
        if (!firebaseUser.emailVerified) {

          firebaseUser.user.updateProfile({displayName: values.firstName+' '+values.lastName}).catch((error) => {
            console.error('update firebase profile', error)
          })

          firebaseUser.user.sendEmailVerification().catch((error) => {
            console.error('send email verification', error)
          })
        }
      }).catch((error) => {
        // var errorCode = error.code;
        // var errorMessage = error.message;
        console.error('sign up form error', error)

        if (error.code === "auth/user-not-found") {
          error.message = t('user_not_found', "User not found.")
        }
        if (error.code === "auth/email-already-in-use") {
          error.message = t('email_already_in_use', "The email address is already in use by another account.")
        }

        this.props.addMessage('error', error.message)
        this.setState({loading: false})
      })
    })
  }

  render() {
    return <Splash h1={t('sign_up_for_free', "Sign up for free")}>
      <SignUpForm addMessage={this.props.addMessage} signIn={this.props.signIn} />
      {t('already_have_an_account', "Already have an account?")} <Link to="/">{t('sign_in', "Sign in")}</Link>
    </Splash>
  }
}

export default SignUp