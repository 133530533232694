import { Component } from 'react'

class SignOut extends Component {

  componentDidMount() {

    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Logout',
        page: '/logout' // this.props.location.pathname
      })
      window.cmAgent.dispatch()
    }

    this.props.app.signOut()
  }

  render() {
    return null
  }
}

export default SignOut