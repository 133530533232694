import React, { Component } from 'react'

import t from '../../utils/translate'
import _ from 'lodash'

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { matchPath } from 'react-router-dom'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class OrganizationSidebar extends Component {

  constructor(props, context) {
    super(props, context)

    let openedMenu = {}

    if (props.location.pathname.match(/tasks|\/settings/)) {
      openedMenu['system'] = true
    }

    this.state = {
      openedMenu: openedMenu
    }

    this.toggleSubmenu = this.toggleSubmenu.bind(this)
    this.goTo = this.goTo.bind(this)
  }

  toggleSubmenu(id) {
    let openedMenu = Object.assign({}, this.state.openedMenu)
    // reopen the menu
    if (this.props.organizationLayout.menuVisible === false) {
      this.props.organizationLayout.toggleMenu()
      openedMenu[id] = true
    } else {
      openedMenu[id] = openedMenu[id] === true ? false : true 
    }

    this.setState({openedMenu: openedMenu})
  }

  goTo(params) {
    // console.log(params)
    const currentOrg = this.props.organizationLayout.organization

    const matchProject = matchPath(this.props.location.pathname, {path: '/organizations/:organizationId/projects/:projectId', exact: false,strict: false})

    let currentProject = undefined

    if (matchProject && matchProject.params.projectId) {
      currentProject = _.find(this.props.organizationLayout.projects, (p) => p.id === matchProject.params.projectId)
    }

    switch(params.key) {
      // organization
      case '/organizations/:organizationId': this.props.history.push(params.key.replace(':organizationId', currentOrg.id)); break
      case '/organizations/:organizationId/admins': this.props.history.push(params.key.replace(':organizationId', currentOrg.id)); break
      case '/organizations/:organizationId/billing': this.props.history.push(params.key.replace(':organizationId', currentOrg.id)); break

      // project
      case '/organizations/:organizationId/projects/:projectId': this.props.history.push(params.key.replace(':organizationId', currentOrg.id).replace(':projectId', currentProject.id)); break
      case '/organizations/:organizationId/projects/:projectId/setup': this.props.history.push(params.key.replace(':organizationId', currentOrg.id).replace(':projectId', currentProject.id)); break
      case '/organizations/:organizationId/projects/:projectId/settings': this.props.history.push(params.key.replace(':organizationId', currentOrg.id).replace(':projectId', currentProject.id)); break
      case '/organizations/:organizationId/projects/:projectId/templates': this.props.history.push(params.key.replace(':organizationId', currentOrg.id).replace(':projectId', currentProject.id)); break
      case '/organizations/:organizationId/projects/:projectId/macros': this.props.history.push(params.key.replace(':organizationId', currentOrg.id).replace(':projectId', currentProject.id)); break
      case '/organizations/:organizationId/projects/:projectId/users': this.props.history.push(params.key.replace(':organizationId', currentOrg.id).replace(':projectId', currentProject.id)); break
      case '/organizations/:organizationId/projects/:projectId/playground': this.props.history.push(params.key.replace(':organizationId', currentOrg.id).replace(':projectId', currentProject.id)); break
      default: alert('Unkown route :('); break
    }
  }

  render() {
    // console.log('props', this.props)
    
    const matchProject = matchPath(this.props.location.pathname, {path: '/organizations/:organizationId/projects/:projectId', exact: false, strict: false})

    let currentProject = undefined

    if (matchProject && matchProject.params.projectId) {
      currentProject = _.find(this.props.organizationLayout.projects, (p) => p.id === matchProject.params.projectId)
    }

    const path = this.props.location.pathname
    let selectedKey

    // organization paths
    
    if (path.includes('/organizations/')) {
      selectedKey = '/organizations/:organizationId'
    }
    
    if (path.includes('/projects')) {
      selectedKey = '/organizations/:organizationId/projects'
    }

    if (path.includes('/admins')) {
      selectedKey = '/organizations/:organizationId/admins'
    }

    if (path.includes('/billing')) {
      selectedKey = '/organizations/:organizationId/billing'
    }

    // project paths
    
    if (path.includes('/projects/')) {
      selectedKey = '/organizations/:organizationId/projects/:projectId'
    }
    
    if (/\/projects\/(.*)[^/]\/setup/.test(path) === true) {
      selectedKey = '/organizations/:organizationId/projects/:projectId/setup'
    }
    
    if (/\/projects\/(.*)[^/]\/settings/.test(path) === true) {
      selectedKey = '/organizations/:organizationId/projects/:projectId/settings'
    }
        
    if (/\/projects\/(.*)[^/]\/templates/.test(path) === true) {
      selectedKey = '/organizations/:organizationId/projects/:projectId/templates'
    }
        
    if (/\/projects\/(.*)[^/]\/macros/.test(path) === true) {
      selectedKey = '/organizations/:organizationId/projects/:projectId/macros'
    }
        
    if (/\/projects\/(.*)[^/]\/users/.test(path) === true) {
      selectedKey = '/organizations/:organizationId/projects/:projectId/users'
    }
        
    if (/\/projects\/(.*)[^/]\/playground/.test(path) === true) {
      selectedKey = '/organizations/:organizationId/projects/:projectId/playground'
    }
    
    const currentOrg = this.props.organizationLayout.organization
    const menuVisible = this.props.organizationLayout.menuVisible

    return (
      <aside className={cn("layout-sider", {collapse: menuVisible === false})}>

        <div className="layout-menu-title">
          {(menuVisible === true) ? <Select style={{width: '100%', background: 'none'}} defaultValue={currentOrg.id} onChange={(organizationId) => {
            this.props.history.push('/organizations/'+organizationId)
          }}>
            {(this.props.app.state.organizations.map((org) => <Select.Option key={org.id} value={org.id}>{org.name}</Select.Option>))}
          </Select>
          : <div onClick={this.props.organizationLayout.toggleMenu}> __</div>}
        </div>

        <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId'})}><FontAwesomeIcon icon="folder-open" /> {(menuVisible === true) && (t('projects', "Projects"))}</div>
        <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId/admins'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId/admins'})}><FontAwesomeIcon icon="user" /> {(menuVisible === true) && (t('admins', "Admins"))}</div>
        <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId/billing'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId/billing'})}><FontAwesomeIcon icon="credit-card" /> {(menuVisible === true) && (t('billing', "Billing"))}</div>

        {(this.props.organizationLayout.projects.length > 0) && <div className="layout-menu-title margin-t-m">
          {(menuVisible === true) ? <Select placeholder={t('go_to_project', "Go to project...")} style={{width: '100%', background: 'none'}} value={(currentProject) ? currentProject.id : undefined} onChange={(projectId) => {
              this.props.history.push('/organizations/'+currentOrg.id+'/projects/'+projectId)
            }}>
              {(this.props.organizationLayout.projects.filter(p => !p.deletedAt).map((pg) => <Select.Option key={pg.id} value={pg.id}>{pg.name}</Select.Option>))}
            </Select>
          : <div onClick={this.props.organizationLayout.toggleMenu}>__</div>}
          </div>
        }

        {(currentProject && currentProject.status === "setup") &&
          <div>
            <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId/projects/:projectId/setup'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId/projects/:projectId/setup'})}><FontAwesomeIcon icon="magic" /> {(menuVisible === true) && (t('setup', "Setup"))}</div>
          </div>
        }

        {(currentProject && currentProject.status === "active") &&
          <div>
            <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId/projects/:projectId'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId/projects/:projectId'})}><FontAwesomeIcon icon="th-large" /> {(menuVisible === true) && (t('dashboard', "Dashboard"))}</div>
            <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId/projects/:projectId/templates'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId/projects/:projectId/templates'})}><FontAwesomeIcon icon="copy" /> {(menuVisible === true) && (t('templates', "Templates"))}</div>
            <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId/projects/:projectId/macros'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId/projects/:projectId/macros'})}><FontAwesomeIcon icon="code" /> {(menuVisible === true) && (t('macros', "Macros"))}</div>
            <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId/projects/:projectId/users'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId/projects/:projectId/users'})}><FontAwesomeIcon icon="at" /> {(menuVisible === true) && (t('users', "Users"))}</div>
            <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId/projects/:projectId/playground'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId/projects/:projectId/playground'})}><FontAwesomeIcon icon="eye" /> {(menuVisible === true) && 'Playground'}</div>
            <div className={cn('layout-menu-item', {'active': selectedKey === '/organizations/:organizationId/projects/:projectId/settings'})} onClick={this.goTo.bind(null, {key: '/organizations/:organizationId/projects/:projectId/settings'})}><FontAwesomeIcon icon="cog" /> {(menuVisible === true) && (t('settings', "Settings"))}</div>
          </div>
        }

        <div className="layout-menu-hider" onClick={this.props.organizationLayout.toggleMenu}>
          {(menuVisible === true) ? 
            <LeftOutlined />
          :
            <RightOutlined />
          }
        </div>
      </aside>
    );
  }
}

export default OrganizationSidebar