import React, { useState } from 'react'
import { 
  Button,
  Form,
  Input,
  Row,
  Col,
  Drawer,
  Select,
  Tooltip,
  Tabs,
  Switch,
} from 'antd'
// import _ from 'lodash'
import t from '../../utils/translate'
// import { BellFilled } from '@ant-design/icons';
import Nunjucks from 'nunjucks'
import IframeSandbox from './_iframeSandbox'
import AceInput from './_aceInput'

const EmailForm = ({loading, channelTitle, macros, language, template, notification, visible, fromTemplate, setVisible, submitForm}) => {
  const [form] = Form.useForm()
  const [contentDecorated, setContentDecorated] = useState('')

  const [contentField, setContentField] = useState('content')

  // console.log('form', form);

  let content = 'Welcome <b>{{ user.firstName }}</b>! 😀'
  let macroId = undefined

  if (macros.find(x => x.id === 'default-email-html-layout')) {
    content = `{{ email_html_header('DEMO', 'https://notifuse.com') }}
<h1>Welcome <b>{{ user.firstName }}</b>! 😀</h1>
{{ email_html_footer('ACME') }}
`
    macroId = 'default-email-html-layout'
  }

  const initialValues = template ? Object.assign({}, template) : {
    content: content,
    macroId: macroId,
    testData: JSON.stringify(notification.testData, null, 2),
    cssInlining: true,
  }

  if (template) {
    initialValues.testData = JSON.stringify(notification.testData, null, 2)
  }

  // clone from template
  if (fromTemplate) {
    initialValues.content = fromTemplate.content
    initialValues.altContent = fromTemplate.altContent
    initialValues.testData = JSON.stringify(notification.testData, null, 2)
    initialValues.fromName = fromTemplate.fromName
    initialValues.fromAddress = fromTemplate.fromAddress
    initialValues.subject = fromTemplate.subject
    initialValues.replyTo = fromTemplate.replyTo
    initialValues.cc = fromTemplate.cc
    initialValues.bcc = fromTemplate.bcc
    initialValues.openTracking = fromTemplate.openTracking
    initialValues.cssInlining = fromTemplate.cssInlining
  }

  if (initialValues.cc && initialValues.cc.length) {
    initialValues.cc = initialValues.cc.split(',')
  }
  if (initialValues.bcc && initialValues.bcc.length) {
    initialValues.bcc = initialValues.bcc.split(',')
  }

  const decorateContent = (content, data, macroId) => {
    if (!content) {
      return
    }

    let parsedData = {}
    try { 
      parsedData = JSON.parse(data)
    }
    catch(e) {}

    let macroContent = ''

    if (macroId) {
      macros.forEach(x => {
        if (x.id === macroId) {
          macroContent = x.content
        }
      })
    }

    // console.log('macroId', macroId);
    // console.log('macroContent', macroContent);
    // console.log('parsedData', parsedData);

    Nunjucks.renderString(macroContent+' '+content, parsedData, (error, result) => {
      if(error) {
        setContentDecorated(t('templating_syntax_invalid', "Templating syntax is not valid..."))
        return
      }
      // trim result to remove linebreaks introduced by macros sets
      setContentDecorated(result.trim())
    })
  }

  const title = ': '+notification.name+' - '+channelTitle+' - '+language

  return <Drawer
    title={(template) ? t('edit_template', "Edit template")+title : t('create_a_template', "Create a template")+title}
    visible={visible}
    closable={true}
    maskClosable={false}
    width={1272}
    onClose={() => { setVisible(false) }}
    footer={
      <div style={{textAlign: 'right'}}>
        <Button loading={loading} onClick={() => setVisible(false)} style={{ marginRight: 8 }}>{t('cancel', "Cancel")}</Button>
        <Button loading={loading} onClick={() => {
          form
            .validateFields()
            .then((values) => {
              submitForm(values, () => {
                setVisible(false)
              })
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }} type="primary">{t('confirm', "Confirm")}</Button>
      </div>
    }
  >
    <Form form={form} layout="vertical" name="widget_form" initialValues={initialValues}>
      <Row gutter={24}>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item 
                name="fromAddress" 
                label={t('sender_email_address', "Sender email address")}
                rules={[{required: true, type: 'email'}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item 
                name="fromName" 
                label={t('sender_name', "Sender name")}
                rules={[{required: true, type: 'string'}]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item 
            name="subject" 
            label={t('subject', "Subject")}
            rules={[{required: true, type: 'string'}]}
          >
            <Input placeholder="Templating markup allowed" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Row gutter={24}>
            <Col span={12}>

              <Form.Item 
                name="cc" 
                label="Cc"
                validateFirst={true}
                rules={[
                  {required: false, type: 'array'},
                  {validator: (rule, value) => {
                    return new Promise((resolve, reject) => {
                      if (value && value.length) {
                        const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                        value.forEach((email) => {
                          if (reg.test(email) === false) {
                            return reject(email+' is not a valid email address')
                          }
                        })
                      }
                      return resolve()
                    })
                  }}
                ]}
              >
                <Select mode="tags" placeholder="Add an email and press enter" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                name="bcc" 
                label="Bcc"
                rules={[
                  {required: false, type: 'array'},
                  {validator: (rule, value) => {
                    return new Promise((resolve, reject) => {
                      if (value && value.length) {
                        const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
                        value.forEach((email) => {
                          if (reg.test(email) === false) {
                            return reject(email+' is not a valid email address')
                          }
                        })
                      }
                      return resolve()
                    })
                  }}
                ]}
              >
                <Select mode="tags" placeholder="Add an email and press enter" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item 
                name="replyTo" 
                label={t('reply_to', "Reply to")}
                rules={[{required: false, type: 'email'}]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                name="openTracking" 
                label={t('open_tracking', "Open tracking")}
                rules={[{required: false, type: 'boolean'}]}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item 
                name="cssInlining" 
                label={t('css_inlining', "CSS inlining")}
                rules={[{required: false, type: 'boolean'}]}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>

          <Tabs tabBarExtraContent={<div style={{width: '250px'}}>
            <Tooltip title={t('macros_page', "Macros page")}>
              <div>
                <Form.Item noStyle name="macroId">
                  <Select style={{width: '100%'}} dropdownMatchSelectWidth={false} allowClear={true} size="small" options={macros.map(x => { return {label: x.name, value: x.id}})} />
                </Form.Item>
              </div>
            </Tooltip></div>} 
            defaultActiveKey={contentField} 
            onChange={value => {
            setContentField(value)
            decorateContent(form.getFieldValue(value), form.getFieldValue('testData'), form.getFieldValue('macroId'))
          }}>
            <Tabs.TabPane tab="HTML" key="content">
              <Form.Item 
                name="content"
                rules={[{ required: false, type: 'string' }]}
              >
                <AceInput id="widgetContent" width="600px" height="300px" mode="nunjucks" />
              </Form.Item>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Text" key="altContent">
              <Form.Item 
                name="altContent"
                rules={[{ required: false, type: 'string' }]}
              >
                <AceInput id="textContent" width="600px" height="300px" mode="nunjucks" />
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>

          <Form.Item 
            label={t('notification_test_data', "Notification test data")}
            name="testData"
            validateFirst={true}
            rules={[
              { validator:(xxx, value) => {
                // check if data is valid json
                try { 
                  if (JSON.parse(value)) {}
                  return Promise.resolve()
                }
                catch(e) {
                  return Promise.reject(t('test_data_is_not_valid_json', "Your test variables is not a valid JSON object!")) 
                }
              }},
              { required: false, type: 'object', transform: (value) => {
                try { 
                  const parsed = JSON.parse(value)
                  return parsed
                }
                catch(e) {
                  return value
                }
              } },
            ]}
          >
            <AceInput id="testData" width="600px" height="150px" mode="json" />
          </Form.Item>
        </Col>

        <Col span={12} id="previewContent">
          <p className="padding-t-s">{t('preview', "Preview")}</p>

          <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.content !== curValues.content || prevValues.altContent !== curValues.altContent || prevValues.testData !== curValues.testData || prevValues.macroId !== curValues.macroId}>
          {({ getFieldValue }) => {
            decorateContent(getFieldValue(contentField), getFieldValue('testData'), getFieldValue('macroId'))
            return <IframeSandbox content={contentDecorated}
               className="Iframe-normal"
               sizeId="previewContent"
               id="templateCompiled" />
          }}
          </Form.Item>
        </Col>
      </Row>

    </Form>
  </Drawer>
}

export default EmailForm
