import React, { Component } from 'react'

import t from '../../utils/translate'
import {
  Alert,
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import IframeSandbox from '../notifications/_iframeSandbox'
import Config from '../../config'

class Playground extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      loading: false,
    }

    this.signUserId = this.signUserId.bind(this)
    this.onFinish = this.onFinish.bind(this)
  }

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Playground (project: '+this.props.projectLayout.project.id+')',
        page: this.props.location.pathname,
        props: {
          orgId: this.props.projectLayout.project.organizationId,
          projectId: this.props.projectLayout.project.id
        }
      })
      window.cmAgent.dispatch()
    }

    this.signUserId('test', (err, hmac) => {
      if (!err) {
        this.formRef.current.setFieldsValue({'userHmac': hmac})
      }
    })
  }

  signUserId(userId, callback) {
    const key = this.props.projectLayout.project.secretKeys.find(x => !x.deletedAt)

    if (!key) {
      this.props.app.addMessage('error', 'There is no secret key in your project to sign the userId')
      return
    }

    const enc = new TextEncoder()

    window.crypto.subtle.importKey('raw', enc.encode(key.key), { name: 'HMAC', hash: 'SHA-256' }, true, ['sign']).then(cryptoKey => {

      window.crypto.subtle.sign('HMAC', cryptoKey, enc.encode(userId)).then((sig) => {        
        const hmac = Array.prototype.map.call(new Uint8Array(sig), x => ('00' + x.toString(16)).slice(-2)).join('');
        return callback(null, hmac)
      }).catch(e => {
        console.error(e)
        return callback(e)
      })
    }).catch(e => {
      console.error(e)
      return callback(e)
    })
  }

  onFinish(values) {

  }

  formRef = React.createRef()

  render() {

    const project = this.props.projectLayout.project
    // console.log('project', project);

    return <div className="layout-content">
      <h1 className="margin-b-l">Playground</h1>

      <div className="margin-h-m">

        <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish} initialValues={{projectId: project.id, userId: 'test'}}>
        <Row gutter={24}>
          <Col span={8}>
              <Alert
                description={t('playground_info', "The playground allows you to view the notifications received by your users on the widget. We recommend you to use it with a 'test' user.")}
                type="info"
                showIcon
                className="margin-b-l"
              />

              <Form.Item name="userId" label="User ID">
                <Input onChange={(e) => {
                  this.signUserId(e.target.value, (err, hmac) => {
                    if (err) {
                      return console.error(e)
                    }
                    console.log('this.formRef', this.formRef);
                    this.formRef.current.setFieldsValue({'userHmac': hmac})   
                  })
                }} />
              </Form.Item>

              <Form.Item name="projectId" label="Project ID">
                <Input disabled />
              </Form.Item>

              <Form.Item name="userHmac" label="User HMAC">
                <Input disabled />
              </Form.Item>
          </Col>

          <Col span={16} id="previewContent" style={{minHeight: '680px'}}>
            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.userHmac !== curValues.userHmac}>
              {({ getFieldValue }) => {
                return <IframeSandbox noExpand={true} content={`
<!DOCTYPE html>
<html>
<head>
    <title>Notifuse Widget playground</title>
    <script src="https://notifuse.com/widget/widget2.min.js"></script>
    <style type="text/css">
      /* http://meyerweb.com/eric/tools/css/reset/ 
         v2.0 | 20110126
         License: none (public domain)
      */

      html, body, div, span, applet, object, iframe,
      h1, h2, h3, h4, h5, h6, p, blockquote, pre,
      a, abbr, acronym, address, big, cite, code,
      del, dfn, em, img, ins, kbd, q, s, samp,
      small, strike, strong, sub, sup, tt, var,
      b, u, i, center,
      dl, dt, dd, ol, ul, li,
      fieldset, form, label, legend,
      table, caption, tbody, tfoot, thead, tr, th, td,
      article, aside, canvas, details, embed, 
      figure, figcaption, footer, header, hgroup, 
      menu, nav, output, ruby, section, summary,
      time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
      }
      /* HTML5 display-role reset for older browsers */
      article, aside, details, figcaption, figure, 
      footer, header, hgroup, menu, nav, section {
        display: block;
      }
      body {
        line-height: 1;
      }
      ol, ul {
        list-style: none;
      }
      blockquote, q {
        quotes: none;
      }
      blockquote:before, blockquote:after,
      q:before, q:after {
        content: '';
        content: none;
      }
      table {
        border-collapse: collapse;
        border-spacing: 0;
      }

      html, body {
        font-family: 'Open Sans',Helvetica,sans-serif;
        background: linear-gradient(to right, #292E49, #536976);
      }
    </style>
</head>

<body>
  <p style="text-align: right; margin: 40px;">
      <span id="notifuse-bell">
          <svg aria-hidden="true" style="cursor: pointer; width: 26px; height: 30px; color: #FFFFFF" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-bell fa-w-14 fa-fw fa-lg"><path fill="currentColor" d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"></path></svg>
      </span>
  </p>

  <script type="text/javascript">
      // customize the projectId, userId and userHmac with
      Notifuse.init({
          host: '${Config().api_endpoint}',
          projectId: '${project.id}',
          userId: '${getFieldValue('userId')}',
          userHmac: '${getFieldValue('userHmac')}',
          onClickMessage: (message) => {
              console.log('got click on message', message);
          },
          minimized: false,
          bellSelector: '#notifuse-bell'
      });
  </script>
</body>
</html>

                `}
                className="Iframe-normal"
                sizeId="previewContent"
                id="templateCompiled" />
              }}
            </Form.Item>
          </Col>
        </Row>
        </Form>
      </div>
    </div>
  }
}

export default Playground