import React, { Component } from 'react'
import _ from 'lodash'
import { 
  Button, 
} from 'antd'

import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons'

class IframeSandbox extends Component {

  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }

    this.ref = _.uniqueId()
    this._resize = this._resize.bind(this)
  }

  _resize() {
    const parentHeight = window.getComputedStyle(document.querySelector('#'+this.props.sizeId)).height

    if (this.refs[this.ref+'-container']) {
      this.refs[this.ref+'-container'].style.height = (parseInt(parentHeight)-30)+'px'
    }
    if (this.refs[this.ref]) {
      if (this.state.expanded) this.refs[this.ref].style.height = '100%'
      else this.refs[this.ref].style.height = (parseInt(parentHeight)-30)+'px'
    }
  }

  componentDidMount() {
    // wait a bit to be sure parent element are well inserted in the dom
    // and height can be computed correctly
    window.setTimeout(() => {
      this._resize()
    }, 100)
  }

  componentDidUpdate(prevProps, prevState) {
    // reload editor when expand/compress
    if(prevState.expanded !== this.state.expanded) {
      console.log('resize after expand');
      this._resize()
      return
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.id !== this.props.id) return true;
    if(nextProps.ref !== this.ref) return true;
    if(nextProps.className !== this.props.className) return true;
    if(nextProps.content !== this.props.content) return true;
    if(nextState.expanded !== this.state.expanded) return true;
    if(nextProps.sizeId !== this.props.sizeId) return true;
    return false;
  }

  render() {
    // console.log('render', this.props.className);
    return (
      <div className={this.state.expanded ? 'Iframe expanded': 'Iframe'} ref={this.ref+'-container'}>
        {!this.props.noExpand && <Button type="primary" ghost className="Iframe-actions" onClick={() => this.setState({expanded: !this.state.expanded})} icon={this.state.expanded ? <ShrinkOutlined /> : <ExpandAltOutlined />} />}
        <iframe title={'iframe-'+this.props.id} srcDoc={this.props.content} frameBorder="0" id={this.props.id} ref={this.ref} className={this.props.className}></iframe>
      </div>
    );
  }
}

IframeSandbox.defaultProps = {
  id: 'iframe-id',
  className: 'Iframe-normal',
  content: ''
}

export default IframeSandbox
